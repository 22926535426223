import { rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Autocomplete from '@mui/material/Autocomplete';
import Hidden from '@mui/material/Hidden';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import { DownArrow } from '@styled-icons/boxicons-solid/DownArrow';
import { camelCase } from 'change-case';
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { GenericApi, makeApi } from '../../api/generic-api';
import { useCrudContext } from '../../components/crud/crud-context';
import { scopeToPath } from '../../helpers/scope-to-path';
import { getFirstError } from '../../utils/get-first-error';
import { Coin, Option, Props } from './protocols/currency-render.type';
import './renderer.css';

const currencyTester = rankWith(5, uiTypeIs('Currency'));

export const currencyRender = {
  tester: currencyTester,
  renderer: withJsonFormsControlProps((anyProps) => {
    // cast props to expected type
    const props = anyProps as Props;

    const ctx = useCrudContext();
    const { validationMode, crudStates, disabledFields, isRequiredField } = ctx;

    const [api, setApi] = useState<GenericApi<any> | null>(null);
    const [apiListData, setApiListData] = useState<any>([]);

    const [options, setOptions] = useState<any>([]);
    const [selected, setSelected] = useState<Option>({ label: '...' } as Option);

    const formatReal = (input: string) => {
      const numericValue = Number(input);
      const realValue = numericValue / 100;
      return realValue.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    };

    const [moneyValue, setMoneyValue] = useState(formatReal('0'));

    const selectUiSchema =
      props.uischema.elements.find((e) => e.type === 'Select') ?? props.uischema.elements[1];
    const moneyUiSchema =
      props.uischema.elements.find((e) => e.type === 'MonetaryInput') ?? props.uischema.elements[0];

    const selectRequired = (selectUiSchema as any).options?.required ?? false;
    const moneyRequired = (selectUiSchema as any).options?.required ?? false;

    useEffect(() => {
      const coinUrl = props.schema.properties?.moedaId.foreignRoute;
      if (coinUrl && !api) {
        const apiInstance = makeApi(coinUrl);
        setApi(apiInstance as any);
        getAllCoins(apiInstance);
      } else if (api) {
        getAllCoins(api);
      }
    }, [api]);

    const getAllCoins = (apiInstance: any) => {
      return apiInstance?.getAll?.().then((apiData: any[] | undefined) => {
        if (apiData) {
          const filterList = selectUiSchema.options?.filterList;

          if (filterList) {
            filterList(apiData).then?.((filteredData: any[]) => {
              setApiListData(filteredData);
            });
          } else {
            setApiListData(apiData);
          }
        }
      });
    };

    useEffect(() => {
      getOptions();

      if ((props.data && !selected) || selected?.value !== Number(props.data)) {
        const item = apiListData.find((item: any) => item.id === Number(props.data.moedaId));

        if (item) {
          const option = {
            label: item.simbolo,
            value: item.id,
          };

          setSelected(option);
        }
      }
    }, [props.data, apiListData]);

    const isSelectDisabled: boolean =
      crudStates.view ||
      selectUiSchema.options?.disabled ||
      ((crudStates.edit && selectUiSchema.options?.onlyCreate) ?? false) ||
      disabledFields.includes(scopeToPath(selectUiSchema.scope));

    const getOptions = (): void => {
      const options = apiListData.map((item: Coin) => ({
        label: item.simbolo,
        value: item.id,
      }));

      setOptions(options);
    };

    const handleSelectChange = (_: SyntheticEvent, option: Option) => {
      const coinRoute = props.schema.properties?.moedaId.foreignRoute!;
      const path = scopeToPath(selectUiSchema.scope);
      setSelected(option);
      props.handleChange(path, option.value);
      props.handleChange(camelCase(coinRoute), { id: option.value });
    };

    const select = (
      <Autocomplete
        className={`currency-select ${
          (isSelectDisabled && 'disabled-field') ||
          (validationMode === 'ValidateAndShow' && props.errors && 'has-error')
        }`}
        options={options}
        value={selected}
        getOptionLabel={(option: any) => option?.label}
        onChange={handleSelectChange}
        isOptionEqualToValue={(option: any, value: any) => option?.value === value?.value}
        disabled={isSelectDisabled}
        renderInput={(params) => (
          <TextField
            {...params}
            required={isRequiredField(selectRequired, ctx)}
            InputProps={{
              ...params.InputProps,
              endAdornment: <DownArrow size={5} />,
            }}
            InputLabelProps={{ shrink: false }}
          />
        )}
      />
    );

    useEffect(() => {
      if (props.data.valorDiaria) {
        setMoneyValue(formatReal(props.data.valorDiaria));
      }
    }, []);

    const isMoneyDisabled: boolean =
      crudStates.view ||
      moneyUiSchema.options?.disabled ||
      ((crudStates.edit && moneyUiSchema.options?.onlyCreate) ?? false) ||
      disabledFields.includes(moneyUiSchema.scope);

    const handleMoneyChange = (event: ChangeEvent<HTMLInputElement>) => {
      const originalCursorPosition = event.target.selectionStart ?? 0;
      const originalLength = event.target.value?.length ?? 0;

      const inputValue = event.target.value?.replace(/[^0-9]/g, '');
      const formattedValue = formatReal(inputValue);
      setMoneyValue(formattedValue);

      const path = scopeToPath(moneyUiSchema.scope);
      props.handleChange(path, Number(inputValue));

      const lengthDifference = formattedValue.length - originalLength;
      let newCursorPosition = originalCursorPosition + lengthDifference;
      newCursorPosition = Math.max(0, Math.min(newCursorPosition, formattedValue.length));

      event.target.setSelectionRange(newCursorPosition, newCursorPosition);
    };

    const money = (
      <TextField
        variant='filled'
        className={`currency-input ${
          (isMoneyDisabled && 'disabled-field') ||
          (validationMode === 'ValidateAndShow' && props.errors && 'has-error')
        }`}
        required={isRequiredField(moneyRequired, ctx)}
        disabled={isMoneyDisabled}
        value={moneyValue}
        placeholder={String(moneyUiSchema.label)}
        onChange={handleMoneyChange}
        InputProps={
          {
            disableUnderline: true,
          } as Partial<OutlinedInputProps>
        }
      />
    );

    return (
      <Hidden xsUp={!props.visible}>
        <div className='custom-input-container'>
          <div className='row'>
            {select}
            {money}
          </div>
          {validationMode === 'ValidateAndShow' && props.errors && (
            <span className='error-message'>{getFirstError(props.errors)}</span>
          )}
          {validationMode === 'ValidateAndShow' &&
            !props.errors &&
            moneyRequired &&
            !Number(moneyValue.replace(/[^0-9]/g, '')) && (
              <span className='error-message'>Campo obrigatório</span>
            )}
        </div>
      </Hidden>
    );
  }),
};
