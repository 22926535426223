import { secureObject } from '../../../helpers/secure-object';
import {
  DisableIcon,
  RestoreIcon,
  StyledTableCell,
  UpdateIcon,
  ViewIcon,
} from '../style/actions-table-cell.styled';
import ActionButton from './action-button';
import ActionsMenu from './actions-menu';

export interface Action {
  hasPermission?: ((moduleName: string, permissions: string[]) => boolean) | string[] | string;
  icon: JSX.Element;
  title: string;
  moduleName: string;
  handler: (row: any) => Promise<void> | void;
}

interface ActionsTableCellProps {
  row: any;
  hideView?: boolean;
  hideUpdate?: boolean;
  hideDelete?: boolean;
  restoreHandler?: Function;
  viewHandler?: Function;
  editHandler?: Function;
  deleteHandler?: Function;
  customActions?: ((row: any) => Action | undefined)[];
  moduleName: string;
  ignorePermissions?: boolean;
}

export const MAX_ACTIONS = 3;

export function ActionsTableCell<T>(props: ActionsTableCellProps) {
  const {
    row,
    hideView,
    hideUpdate,
    hideDelete,
    restoreHandler,
    viewHandler,
    editHandler,
    deleteHandler,
    customActions,
    moduleName,
    ignorePermissions = false,
  } = props;

  const getCustomAction = (processor: (row: T) => Action | undefined, row: T) => {
    const processorResult = processor?.(row);
    if (processorResult) {
      const { hasPermission, icon, title, moduleName, handler } = processorResult;
      return secureObject(
        {
          icon,
          title,
          handler,
        },
        {
          moduleName,
          hasPermission,
          ignorePermission: ignorePermissions,
        },
      );
    }
  };

  const wrapHandlerWithRowId = (handler?: Function) => {
    return handler ? (row: any) => handler(row.id) : undefined;
  };

  const baseActions: Action[] = [
    secureObject(
      !hideView &&
        !row.deletedAt && {
          icon: <ViewIcon />,
          title: 'Visualizar',
          handler: wrapHandlerWithRowId(viewHandler),
        },
      { moduleName, hasPermission: 'BUSCAR_POR_ID', ignorePermission: ignorePermissions },
    ),
    secureObject(
      !hideUpdate &&
        !row.deletedAt && {
          icon: <UpdateIcon />,
          title: 'Editar',
          handler: wrapHandlerWithRowId(editHandler),
        },
      { moduleName, hasPermission: 'ATUALIZAR', ignorePermission: ignorePermissions },
    ),
    secureObject(
      !hideDelete &&
        !row.deletedAt && {
          icon: <DisableIcon size={18} />,
          title: 'Desativar',
          handler: wrapHandlerWithRowId(deleteHandler),
        },
      { moduleName, hasPermission: 'DELETAR', ignorePermission: ignorePermissions },
    ),
    secureObject(
      row.deletedAt && {
        icon: <RestoreIcon size={24} />,
        title: 'Ativar',
        handler: wrapHandlerWithRowId(restoreHandler),
      },
      { moduleName, hasPermission: 'RESTAURAR', ignorePermission: ignorePermissions },
    ),
  ].filter(Boolean);

  const customActionsArray: Action[] = (customActions ?? [])
    .map((processor) => getCustomAction(processor, row))
    .filter(Boolean) as Action[];

  const actions = baseActions.concat(customActionsArray);

  return (
    <StyledTableCell>
      {actions.length <= MAX_ACTIONS &&
        actions.map((action, index) => <ActionButton key={index} action={action} row={row} />)}
      {actions.length > MAX_ACTIONS && (
        <>
          {actions.slice(0, MAX_ACTIONS - 1).map((action, index) => (
            <ActionButton key={index} action={action} row={row} />
          ))}

          <ActionsMenu actions={actions} row={row} />
        </>
      )}
    </StyledTableCell>
  );
}
