import styled from '@emotion/styled';
import Button from '@mui/material/Button';

export const BtnSave = styled(Button)`
  color: green !important;
`;

export const BtnConfirmDelete = styled(Button)`
  color: red !important;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

export const TextTitle = styled.h2`
  text-align: center;
  position: fixed;
  width: 85vw;
  background-color: #fafafa;
  z-index: 10;
  margin: 0;
  align-content: center;
  height: 75px;
  top: 55px;
`;
