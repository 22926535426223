import { useAuthContext } from '../views/login/auth/auth-context';
import { authStringsFor } from './auth-strings-for';

export interface ISecureOptions {
  hasPermission?: ((moduleName: string, permissions: string[]) => boolean) | string[] | string;
  moduleName: string;
  ignorePermission?: boolean;
}

export const secureObject = (
  obj: any,
  { hasPermission, moduleName, ignorePermission }: ISecureOptions,
) => {
  if (ignorePermission) return obj;

  const { permissions } = useAuthContext();
  let permissionGranted: boolean = true;

  if (typeof hasPermission === 'function')
    permissionGranted = hasPermission?.(moduleName, permissions);

  if (typeof hasPermission === 'string')
    permissionGranted = authStringsFor(`${moduleName}.${hasPermission}`).some((x) =>
      permissions.includes(x),
    );

  if (Array.isArray(hasPermission))
    permissionGranted = hasPermission.reduce(
      (acc, value) =>
        acc && authStringsFor(`${moduleName}.${value}`).some((x) => permissions.includes(x)),
      true,
    );

  return permissionGranted ? obj : null;
};
