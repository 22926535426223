import { Star } from '@styled-icons/evaicons-solid/Star';
import { useNavigate } from 'react-router-dom';
import { Crud } from '../../../components/crud/crud';
import { useCrudContext } from '../../../components/crud/crud-context';
import { moduleNameFor } from '../../../helpers/module-name-for';
import { LinhaDeAcao as LinhaDeAcaoModel } from '../../../models/linha-de-acao';
import { headCells } from './linha-de-acao.headcell';
import linhaDeAcaoUiSchema from './linha-de-acao.uischema';

function LinhaDeAcao() {
  const navigate = useNavigate();
  const { parameterFilters, setParameterFilters } = useCrudContext();

  const edicaoLinhaDeAcao = {
    hasPermission: 'EXIBIR_NO_MENU',
    moduleName: moduleNameFor('edicao-linha-de-acao'),
    icon: <Star size='24' color='#009900' />,
    title: 'Edição de Linha de Ação',
    handler: (row: LinhaDeAcaoModel) => {
      setParameterFilters({
        ...parameterFilters,
        linhaDeAcao: row,
      });
      navigate(`/linha-de-acao/${row?.id}/edicao-linha-de-acao`);
    },
  };

  return (
    <Crud<LinhaDeAcaoModel>
      headCells={headCells}
      titleConfig={{ value: 'Linhas de Ações', show: true }}
      uischema={linhaDeAcaoUiSchema}
      apiUrl={'/linha-de-acao'}
      queryFilters={{
        withDeleted: true,
        orderBy: 'dataInicio',
        orderDirection: 'ASC',
      }}
      customActions={[() => edicaoLinhaDeAcao]}
      width={650}
    ></Crud>
  );
}

export default LinhaDeAcao;
