import { rankWith, schemaMatches, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Checkbox from '@mui/material/Checkbox';
import Hidden from '@mui/material/Hidden';
import isEmpty from 'lodash/isEmpty';
import { useMemo, useState } from 'react';
import { useCrudContext } from '../../components/crud/crud-context';
import { getFirstError } from '../../utils/get-first-error';
import { IControlElement } from '../uischema';
import './renderer.css';

const isBooleanRender =
  schemaMatches((schema) => !isEmpty(schema) && (schema as any)?.type === 'boolean') ||
  uiTypeIs('Boolean');

const booleanRenderTester = rankWith(6, isBooleanRender);

export const booleanRender = {
  tester: booleanRenderTester,
  renderer: withJsonFormsControlProps(
    ({ visible = true, enabled = true, path, schema, label, ...props }) => {
      const uischema = props.uischema as IControlElement;

      const [value, setValue] = useState<boolean>(props.data ?? false);

      const ctx = useCrudContext();
      const { validationMode, crudStates, disabledFields, formData, isRequiredField } = ctx;

      const visibleHandler = uischema?.options?.visibleHandler;

      const isVisible = useMemo(() => {
        const visibility = visibleHandler ? visibleHandler?.(ctx, null, null) : true;
        return visible && visibility;
      }, [visible, crudStates, visibleHandler]);

      const isDisabled =
        !enabled ||
        crudStates.view ||
        (crudStates.edit && uischema?.options?.onlyCreate) ||
        uischema?.options?.disabled ||
        disabledFields.includes(path);

      const required = uischema.options?.required ?? false;

      const handleChange = (value: boolean) => {
        setValue(value);

        if (uischema.options?.handleChange) {
          uischema.options?.handleChange?.(value, props.handleChange, formData, ctx, path);
        }

        props.handleChange(path, value);
      };

      return (
        <Hidden xsUp={!isVisible} key={`${path}-boolean`}>
          <Checkbox
            id={`${path}-boolean`}
            key={`${path}-boolean-cbx`}
            checked={value}
            disabled={isDisabled}
            required={isRequiredField(required, ctx)}
            onChange={(_, checked) => handleChange(checked)}
          ></Checkbox>
          {label}
          {validationMode === 'ValidateAndShow' && props.errors && (
            <span className='error-message'>{getFirstError(props.errors)}</span>
          )}
        </Hidden>
      );
    },
  ),
};
