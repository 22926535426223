import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { IndicadorProducao } from '../../../models/indicador-producao';

export const displayProperties = ['nome'];

export const moduleLabel = 'Indicadores de Produção';

export const moduleName = 'EDITAL.INDICADOR_PRODUCAO.INDICADOR_PRODUCAO';

export const headCells: readonly HeadCell<IndicadorProducao>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
];
