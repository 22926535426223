import { ControlProps, rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import { kebabCase } from 'change-case';
import { useState } from 'react';
import {
  GridItem,
  PaperList,
  CustomList,
  WarningText,
  CustomButton,
  QuestionText,
  GridContainer,
  TransferListTitle,
  QuestionContainer,
  CustomListItemText,
  CustomListItemButton,
} from './styled/multipla-selecao-grande';

function filterByPresence<T extends { const: number }>(
  a: readonly T[],
  b: readonly T[],
  include: boolean,
): T[] {
  return a.filter(
    (itemA) => (b.findIndex((itemB) => itemB['const'] === itemA['const']) !== -1) === include,
  );
}

const MaterialMultiplaSelecaoGrandeComponent = (props: ControlProps) => {
  const { schema, errors } = props;

  const [checked, setChecked] = useState<readonly any[]>([]);
  const [left, setLeft] = useState<readonly any[]>(schema.oneOf ?? []);
  const [right, setRight] = useState<readonly any[]>([]);

  const leftChecked = filterByPresence(checked, left, true);
  const rightChecked = filterByPresence(checked, right, true);

  const handleToggle = (value: any) => () => {
    const currentIndex = checked.findIndex((item) => item.const === value.const);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const getColor = (color: string) => (errors ? '#C42525 !important' : `${color} !important`);

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(filterByPresence(left, leftChecked, false));
    setChecked(filterByPresence(checked, leftChecked, false));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(filterByPresence(right, rightChecked, false));
    setChecked(filterByPresence(checked, rightChecked, false));
  };

  const removeAll = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const lists = (items: readonly any[]) => (
    <PaperList componentColor={errors ? '1px solid #C42525' : '1px solid #757780'}>
      <CustomList dense role='list'>
        {items.length > 0 ? (
          items.map((value: any) => {
            const labelId = `transfer-list-item-${kebabCase(value.title)}-label`;

            return (
              <CustomListItemButton key={value.const} role='listitem' onClick={handleToggle(value)}>
                <CustomListItemText id={labelId} primary={value.title} />
                <ListItemIcon sx={{ minWidth: 'auto' }}>
                  <Checkbox
                    size='small'
                    sx={{ padding: '0', paddingLeft: '5px' }}
                    checked={checked.some((item) => item.const === value.const)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </ListItemIcon>
              </CustomListItemButton>
            );
          })
        ) : (
          <ListItem sx={{ backgroundColor: '#FFFFFF !important', paddingLeft: '4px' }}>
            <CustomListItemText primary='Nenhum item selecionado.' />
          </ListItem>
        )}
      </CustomList>
    </PaperList>
  );

  return (
    <GridContainer container spacing={1}>
      <GridItem item xs>
        <TransferListTitle componentColor={getColor('#1D1B20')}>Área de seleção:</TransferListTitle>
        {lists(left)}
      </GridItem>
      <GridItem item xs='auto' sx={{ mx: '16px !important' }}>
        <Grid
          container
          direction='column'
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <CustomButton
            size='small'
            sx={{
              backgroundColor: '#003D92',
              '&:hover': {
                backgroundColor: '#002d73',
              },
            }}
            color='primary'
            variant='contained'
            onClick={handleCheckedRight}
            disabled={left.length === 0}
            aria-label='Adicionar'
          >
            Adicionar
          </CustomButton>
          <CustomButton
            size='small'
            color='error'
            variant='outlined'
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label='Remover'
          >
            Remover
          </CustomButton>
          <CustomButton
            variant='outlined'
            size='small'
            sx={{
              color: '#003D92',
              borderColor: '#003D92',
              '&:hover': {
                backgroundColor: 'rgba(0, 61, 146, 0.1)',
                borderColor: '#003D92',
              },
            }}
            onClick={removeAll}
            disabled={right.length === 0}
            aria-label='Remover'
          >
            Limpar
          </CustomButton>
        </Grid>
      </GridItem>
      <GridItem item xs sx={{ marginLeft: 'auto' }}>
        <TransferListTitle componentColor={getColor('#1D1B20')}>
          Itens selecionados:
        </TransferListTitle>
        {lists(right)}
      </GridItem>
    </GridContainer>
  );
};

const MaterialMultiplaEscolhaGrandeLayoutRenderer = (props: ControlProps) => {
  const { visible, label, uischema, errors } = props;
  const required = uischema?.options?.required;

  const getColor = (color: string) => (errors ? '#C42525 !important' : `${color} !important`);

  return (
    <Hidden xsUp={!visible}>
      <QuestionContainer>
        <QuestionText color={getColor('#1D1B20')}>{label}</QuestionText>
        <WarningText color={getColor('#1351B4')}>(Se necessário, marque mais de uma)</WarningText>
        {required && <Typography color={getColor('#1D1B20')}>*</Typography>}
      </QuestionContainer>
      <MaterialMultiplaSelecaoGrandeComponent {...props} />
      {required && errors && (
        <Typography variant='caption' sx={{ color: '#C42525', marginTop: '4px', marginLeft: '0' }}>
          {'O campo é obrigatório!'}
        </Typography>
      )}
    </Hidden>
  );
};

const multiplaEscolhaGrandeRenderTester = rankWith(6, uiTypeIs('MultiplaSelecaoGrande'));

export const MultiplaSelecaoGrande = {
  tester: multiplaEscolhaGrandeRenderTester,
  renderer: withJsonFormsControlProps(MaterialMultiplaEscolhaGrandeLayoutRenderer),
};
