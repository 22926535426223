import { CrudContextData } from '../../../components/crud/crud-context';
import { IStepCallback } from '../../../jsonforms/uischema';

export const stepHandlerProposta = async (
  fieldsConfig: { paths: string[]; instancePaths: string[]; requiredScopes: string[] },
  ctx: CrudContextData,
  data: any,
  url: string,
  callback?: IStepCallback,
): Promise<boolean | void> => {
  const { errorsJsonForms, showError } = ctx;
  let valid = true;
  let message = '';
  if (errorsJsonForms.length > 0) {
    const error = errorsJsonForms[0];
    //Ajustar aqui as mensagens de erro retornados pelo backend.
  }

  if (!valid) {
    showError(message);
    return false;
  }

  if (callback) {
    return await callback?.(ctx);
  }

  return true;
};
