import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { HeadCell } from '../protocols/head-cell';

export interface EnhancedTableProps<T> {
  numSelected: number;
  headCells: readonly HeadCell<T>[];
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  checkboxes?: boolean;
  hideActions?: boolean;
}

export function EnhancedTableHead<T>(props: EnhancedTableProps<T>) {
  const { onSelectAllClick, headCells, numSelected, rowCount, checkboxes, hideActions } = props;

  return (
    <TableHead sx={{ backgroundColor: '#1351b4' }}>
      <TableRow>
        {checkboxes && (
          <TableCell padding='checkbox'>
            <Checkbox
              color='primary'
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all',
              }}
            />
          </TableCell>
        )}

        {headCells?.map((headCell) => (
          <TableCell
            sx={{
              color: '#fff',
              '&:hover': { color: '#fff' },
              padding: 0,
              height: '48px',
              paddingLeft: 2,
              textAlign: 'left',
            }}
            key={headCell.id as React.Key}
            align='center'
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}

        {!hideActions && (
          <TableCell sx={{ color: '#fff', padding: 0, height: '48px' }}>Ações</TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}
