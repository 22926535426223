import { rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Hidden from '@mui/material/Hidden';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { CloudUpload } from '@styled-icons/bootstrap/CloudUpload';
import { FiletypeDoc } from '@styled-icons/bootstrap/FiletypeDoc';
import { FiletypeJpg } from '@styled-icons/bootstrap/FiletypeJpg';
import { FiletypePdf } from '@styled-icons/bootstrap/FiletypePdf';
import { FiletypeXls } from '@styled-icons/bootstrap/FiletypeXls';
import { Trash3 } from '@styled-icons/bootstrap/Trash3';
import { Close } from '@styled-icons/ionicons-outline/Close';
import { kebabCase } from 'change-case';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import removeAccents from 'remove-accents';
import { FileApi } from '../../api/file-api';
import { useCrudContext } from '../../components/crud/crud-context';
import { cropString } from '../../helpers/crop-string';
import { IControlElement } from '../uischema';
import './file-render.css';

const fileTester = rankWith(5, uiTypeIs('File'));

export interface ICategory {
  name: string;
  maxFileSize: number;
  mimeTypeGroups: {
    title: string;
    mimeTypes: { [key: string]: string };
  }[];
  additionalData?: { [key: string]: any };
}

export type FileData = File & {
  createdAt?: string;
};

export const FileRender = {
  tester: fileTester,
  renderer: withJsonFormsControlProps(
    ({ visible = true, schema, path, data, enabled, ...props }) => {
      const uischema = props.uischema as IControlElement;
      const { formData, crudStates, disabledFields, showError, currentApiUrl } = useCrudContext();
      const [files, setFiles] = useState<FileData[]>(data?.anexos || []);
      const [fileCategories, setFileCategories] = useState<any>(null);
      const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(null);
      const [fileProgress, setFileProgress] = useState<{ [key: string]: number }>({});

      const fileApi = new FileApi();
      const options = uischema?.options;
      const anexoInfo = (schema as any)?.anexoInfo;
      const filePath = !options?.route
        ? (schema as any)?.type === 'object'
          ? 'anexo'
          : kebabCase(path)
        : '';
      const showCategoryField = fileCategories && !selectedCategory;

      const mimeTypeGroups = fileCategories
        ? selectedCategory?.mimeTypeGroups
        : anexoInfo?.mimeTypeGroups;
      const route = options?.route ?? currentApiUrl;

      const isDisabled =
        !enabled ||
        crudStates.view ||
        (crudStates.edit && uischema?.options?.onlyCreate) ||
        uischema?.options?.disabled ||
        disabledFields.includes(path);

      const fetchFilesFromApi = async () => {
        try {
          const response = await fileApi.getFile(
            route,
            filePath,
            !options?.withoutFk ? formData?.id : undefined,
          );

          if (response.status === 200) {
            if (options?.filterByCategories) {
              response.data = response.data.filter(
                (file: any) =>
                  fileCategories?.some(
                    (category: any) => kebabCase(removeAccents(category.name)) === file.categoria,
                  ),
              );
            }

            const apiFiles = response.data.map((fileData: any) => ({
              name: fileData.nome,
              size: fileData.tamanho,
              type: fileData.mimeType,
              category: fileData.categoria,
              createdAt: fileData.createdAt,
              id: fileData.id,
            }));
            const fileProgress = apiFiles.reduce((progress: any, file: any) => {
              progress[file.name] = 100;
              return progress;
            }, {});

            setFiles(apiFiles);
            setFileProgress(fileProgress);
          } else {
            console.error('Erro ao obter arquivos da API:', response);
          }
        } catch (error) {
          console.error('Erro durante a solicitação à API para obter arquivos:', error);
        }
      };

      useEffect(() => {
        const categoriesOptions = options?.fileApiOptions?.(formData) ?? {};

        if (anexoInfo?.categories) {
          setFileCategories(
            anexoInfo.categories.reduce((acc: any, category: any) => {
              acc[kebabCase(removeAccents(category.name))] = category;
              return acc;
            }, {}),
          );
        } else if (anexoInfo?.categoriesRoute) {
          const urlParams = [route, anexoInfo.categoriesRoute];
          const url = urlParams.filter((x) => x).join('/');

          fileApi
            .getCategories(url, categoriesOptions)
            .then((data) => setFileCategories(data))
            .catch((error) => console.error('Erro ao obter categorias de arquivos:', error));
        } else if (options?.categories) {
          setFileCategories(options.categories);
        } else if (options?.categoriesRoute) {
          fileApi
            .getCategories(options.categoriesRoute, categoriesOptions)
            .then((data) => setFileCategories(data))
            .catch((error) => console.error('Erro ao obter categorias de arquivos:', error));
        }
      }, [anexoInfo]);

      useEffect(() => {
        fetchFilesFromApi();
      }, [formData?.id, path, crudStates, fileCategories]);

      const uploadFile = async (file: File) => {
        const category = selectedCategory?.name.replace('*', '');
        const categoriesOptions = options?.fileApiOptions?.(formData) ?? {};

        try {
          await fileApi
            .addFile(
              file,
              setFileProgress,
              route,
              filePath,
              !options?.withoutFk ? formData.id : undefined,
              category,
              categoriesOptions,
              selectedCategory?.additionalData ?? {},
            )
            .then(() => fetchFilesFromApi())
            .catch((error) => console.error('Erro durante o upload:', error));
        } catch (error) {
          console.error('Erro durante o upload:', error);
        }
      };

      const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const uploadedFile = event.target.files;
        if (uploadedFile) {
          const updatedFiles = Array.from(uploadedFile);
          const anexoSize = fileCategories ? selectedCategory?.maxFileSize : anexoInfo?.maxFileSize;
          const allowedMimeTypes = mimeTypeGroups.flatMap((x: any) => Object.values(x.mimeTypes));

          updatedFiles.forEach((file) => {
            if (file.size <= toBytes(anexoSize) && allowedMimeTypes.includes(file.type)) {
              if (!fileProgress[file.name] || fileProgress[file.name] < 100) {
                setFiles((prevFiles) => [...prevFiles, file]);
                uploadFile(file);
              }
            } else {
              if (file.size > toBytes(anexoSize)) {
                showError(`Tamanho máximo de anexo é ${anexoSize} MB`);
              } else {
                showError(
                  `O anexo deve ser ${mimeTypeGroups?.map((x: any) => x.title).join(', ')}`,
                );
              }
            }
          });
        }
        event.target.value = '';
      };

      const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const droppedFile = event.dataTransfer.files;
        if (droppedFile) {
          const updatedFiles = Array.from(droppedFile);
          const anexoSize = fileCategories ? selectedCategory?.maxFileSize : anexoInfo?.maxFileSize;

          updatedFiles.forEach((file) => {
            if (
              file.size <= toBytes(anexoSize) &&
              mimeTypeGroups?.flatMap((x: any) => x.mimeTypes).includes(file.type)
            ) {
              setFiles((prevFiles) => [...prevFiles, file]);
              uploadFile(file);
            } else {
              if (file.size > toBytes(anexoSize)) {
                showError(`Tamanho máximo de anexo é  + ${anexoSize} MB`);
              } else {
                showError(
                  `O anexo deve ser ${mimeTypeGroups()
                    ?.map((x: any) => x.title)
                    .join(', ')}`,
                );
              }
            }
          });
        }
      };

      const handleDownloadFile = async (index: number) => {
        const fileToDownload = files?.[index] as any;

        try {
          const response = await fileApi.downloadFile(
            route,
            filePath,
            fileToDownload?.category ? fileToDownload?.category : fileToDownload?.id,
            !options?.withoutFk ? formData?.id : undefined,
          );

          const fileContentBlob = new Blob([response.data], { type: fileToDownload.type });
          const tempUrl = window.URL.createObjectURL(fileContentBlob);

          const tempDownloadLink = document.createElement('a');
          tempDownloadLink.href = tempUrl;
          tempDownloadLink.setAttribute('download', fileToDownload.name);

          tempDownloadLink.click();

          window.URL.revokeObjectURL(tempUrl);
        } catch (error) {
          console.log('Erro ao realizar o download do arquivo', error);
        }
      };

      const handleRemoveFile = async (event: any, index: number) => {
        event.stopPropagation();
        const fileToRemove = files[index] as any;

        if (isDisabled) {
          return;
        }

        if (fileToRemove?.id) {
          try {
            await fileApi.removeFile(
              route,
              filePath,
              fileToRemove?.category ? fileToRemove?.category : fileToRemove?.id,
              !options?.withoutFk ? formData?.id : undefined,
            );
          } catch (error) {
            console.error('Erro ao excluir arquivo:', error);
            return;
          }
        }
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));

        setFileProgress((prevProgress) => {
          const updatedProgress = { ...prevProgress };
          delete updatedProgress[fileToRemove.name];
          return updatedProgress;
        });
      };

      const getFileTypeClass = (file: File) => {
        switch (file.type) {
          case 'application/pdf':
            return 'pdf-file';
          case 'application/msword':
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return 'doc-file';
          case 'application/vnd.ms-excel':
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return 'xls-file';
          default:
            return 'jpeg-file';
        }
      };

      const fileStyles = {
        'pdf-file': { color: '#C41414', icon: <FiletypePdf size={20} color={'#C41414'} /> },
        'doc-file': { color: '#064379', icon: <FiletypeDoc size={20} color={'#C41414'} /> },
        'xls-file': { color: '#29AB45', icon: <FiletypeXls size={20} color={'#29AB45'} /> },
        'jpeg-file': { color: 'EA74C9', icon: <FiletypeJpg size={20} color={'#EA74C9'} /> },
      };

      const toBytes = (size: number) => size * 1024 * 1024;

      function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant='determinate' {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography style={{ color: '#1E90FF' }}>{`${Math.round(props.value)}%`}</Typography>
            </Box>
          </Box>
        );
      }

      return (
        <Hidden xsUp={!visible}>
          <Box className={`file-render ${isDisabled && 'disabled-field'}`}>
            <div className='document-fields'>
              {fileCategories ? (
                <FormControl fullWidth sx={{ marginBottom: '2rem' }} disabled={isDisabled}>
                  <InputLabel id='select-categories-label'>
                    {uischema.label ?? 'Categoria'}
                  </InputLabel>
                  <Select
                    labelId='select-categories-label'
                    id='select-categories'
                    label={uischema.label ?? 'Categoria'}
                    onChange={(event) =>
                      setSelectedCategory(fileCategories[event.target.value as string])
                    }
                  >
                    {Object.entries(fileCategories).map(([index, category]: [string, any]) => {
                      return (
                        <MenuItem key={index} value={index}>
                          {category.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              ) : null}

              <div
                className={'document-uploader'}
                onDrop={handleDrop}
                onDragOver={(event) => event.preventDefault()}
              >
                <div className='upload-info'>
                  <div className='upload-phrase'>
                    <CloudUpload className='cloud-icon' />
                    <div>
                      <p>
                        Arraste e solte seu arquivo ou{' '}
                        <label
                          className={`choose-file ${
                            (isDisabled || showCategoryField) && 'disabled-field'
                          }`}
                          htmlFor='browse'
                        >
                          Procure
                        </label>
                      </p>
                      <p className='accepted-file'>
                        Formato(s) aceito(s): {mimeTypeGroups?.map((x: any) => x.title).join(', ')}
                      </p>
                    </div>
                  </div>
                </div>
                <input
                  type='file'
                  hidden
                  id='browse'
                  onChange={handleFileChange}
                  accept={mimeTypeGroups?.flatMap((x: any) => Object.values(x.mimeTypes)).join(',')}
                  multiple={!fileCategories}
                  disabled={isDisabled || showCategoryField}
                />
              </div>
            </div>

            <div className='imported-document'>
              <p>
                <label className='file-label-title'>Arquivos Importados</label>
              </p>

              {files.length === 0 ? (
                <span className='no-file-message'>Nenhum arquivo adicionado</span>
              ) : (
                files.map((file, index) => (
                  <div
                    className={`file-item ${getFileTypeClass(file)}`}
                    key={index}
                    onClick={() => handleDownloadFile(index)}
                  >
                    <div className={`border-icon ${getFileTypeClass(file)}`}>
                      <Box>{fileStyles[getFileTypeClass(file)].icon}</Box>
                    </div>
                    {fileProgress[file.name] && fileProgress[file.name] === 100 ? (
                      <div className='file-group'>
                        <div className='file-info'>
                          <div>
                            <p
                              className={
                                'file-name file-name--' + getFileTypeClass(file).split('-')[0]
                              }
                            >
                              {cropString(file.name, 32)}
                            </p>
                            <p className='file-upload-date'>
                              Data de upload: {dayjs(file.createdAt).format('DD/MM/YYYY')}
                            </p>
                          </div>
                          <p className='file-upload-size'>
                            {(file.size / 1024 / 1024).toPrecision(1)} MB
                          </p>
                        </div>
                        {!isDisabled && (
                          <Trash3
                            className='trash-icon'
                            onClick={(event) => handleRemoveFile(event, index)}
                          />
                        )}
                      </div>
                    ) : (
                      <div className='file-upload-group'>
                        <div className='file-info'>
                          <p>{cropString(file.name, 32)}</p>
                          <Close
                            className='close-icon'
                            onClick={(event) => handleRemoveFile(event, index)}
                          />
                        </div>
                        <LinearProgressWithLabel value={fileProgress[file.name] || 0} />
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
          </Box>
        </Hidden>
      );
    },
  ),
};
