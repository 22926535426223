import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { Calendar2RangeFill } from '@styled-icons/bootstrap/Calendar2RangeFill';

import {
  changeTimeFromDate,
  compareDates,
  normalize,
  ptBrformatDate,
} from '../pt-br-format-date/pt-br-format-date';
import dayjs from '../dayjs/sig-dayjs';
import { Dayjs } from 'dayjs';
import {
  CallendarRangeIcon,
  ComponentContent,
  Vigencia,
  VigenciaLabel,
} from '../layout/style/edital/validity-checker';

const isNearToClose = (date: Dayjs) => {
  const nearDateToClose = changeTimeFromDate(date, -72);
  const currentDate = dayjs();
  return compareDates(currentDate, nearDateToClose, '>=') ? '#BA1A1A' : '#225ABD';
};

const ValidityChecker = (item: any) => {
  const edital = item.item;
  if (Object.keys(edital).length === 0) return 'Loading...';
  const chamadaVigente = edital.chamadaVigente ?? edital.chamadaEdital[0];

  const inicio = normalize(chamadaVigente?.inicio);
  const fim = normalize(chamadaVigente?.termino);
  const color = isNearToClose(fim);
  return (
    <ListItemText
      primary={
        <>
          <Typography variant='body1'>{item.nome}</Typography>
          <Typography variant='body2'>
            <ComponentContent>
              <CallendarRangeIcon size='16' color='#003D92' />
              <VigenciaLabel>Vigência:</VigenciaLabel>
              <Vigencia colorValue={color}>
                {ptBrformatDate(inicio)} - {ptBrformatDate(fim)}
              </Vigencia>
            </ComponentContent>
          </Typography>
        </>
      }
    />
  );
};

export default ValidityChecker;
