import { circleFlagBuilder } from '../../components/circle-flag';
import { CrudContextData } from '../../components/crud/crud-context';
import { hideIfBrasil } from '../../jsonforms/uischema/hide-if-brasil';
import { showIfBrasil } from '../../jsonforms/uischema/show-if-brasil';
import { IUiSchema } from '../../jsonforms/uischema/uischema.type';

export const requiredIfBrasil = (ctx: CrudContextData) => {
  const { formData } = ctx;
  return formData['paisId-codigo'] === 'brasil';
};

export const requiredIfNotBrasil = (ctx: CrudContextData) => {
  const { formData } = ctx;
  return formData['paisId-codigo'] !== 'brasil';
};

const instituicaoUiSchema: IUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'Control',
          scope: '#/properties/nome',
          label: 'Nome',
          options: {
            required: true,
          },
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/sigla',
              label: 'Sigla',
              options: {
                required: true,
              },
            },
            {
              type: 'Text',
              scope: '#/properties/endereco/properties/cep',
              label: 'Zipcode',
              rule: hideIfBrasil,
              options: {
                maxLength: 9,
                required: requiredIfNotBrasil,
              },
            },
            {
              type: 'Control',
              scope: '#/properties/cnpj',
              label: 'CNPJ',
              rule: showIfBrasil,
              options: {
                mask: '99.999.999/9999-99',
                required: requiredIfBrasil,
              },
            },
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/estado',
              rule: showIfBrasil,
              options: {
                required: requiredIfBrasil,
              },
            },
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/logradouro',
              label: 'Logradouro',
              rule: showIfBrasil,
              options: {
                required: requiredIfBrasil,
              },
            },
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/numero',
              label: 'Número',
              rule: showIfBrasil,
              options: {
                required: requiredIfBrasil,
                isPositiveNumber: true,
              },
            },
          ],
        },
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Select',
              scope: '#/properties/paisId',
              label: 'País',
              options: {
                required: true,
                iconBuilder: circleFlagBuilder,
              },
            },
            {
              type: 'Cep',
              scope: '#/properties/endereco/properties/cep',
              label: 'CEP',
              rule: showIfBrasil,
              options: {
                mask: '99999-999',
                required: requiredIfBrasil,
              },
            },
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/municipio',
              label: 'Município',
              rule: showIfBrasil,
              options: {
                required: requiredIfBrasil,
              },
            },
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/bairro',
              label: 'Bairro',
              rule: showIfBrasil,
              options: {
                required: requiredIfBrasil,
              },
            },
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/complemento',
              label: 'Complemento',
              rule: showIfBrasil,
            },
          ],
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/estado',
              label: 'Estado/Região',
              rule: hideIfBrasil,
              options: {
                required: requiredIfNotBrasil,
              },
            },
          ],
        },
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/municipio',
              label: 'Município',
              rule: hideIfBrasil,
              options: {
                required: requiredIfNotBrasil,
              },
            },
          ],
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/logradouro',
              label: 'Logradouro',
              rule: hideIfBrasil,
              options: {
                required: requiredIfNotBrasil,
              },
            },
          ],
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/bairro',
              label: 'Bairro',
              rule: hideIfBrasil,
              options: {
                required: requiredIfNotBrasil,
              },
            },
          ],
        },
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/endereco/properties/complemento',
              label: 'Complemento',
              rule: hideIfBrasil,
            },
          ],
        },
      ],
    },
  ],
};

export default instituicaoUiSchema;
