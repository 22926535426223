import React, { createContext, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeLoginApi } from '../../../api/login-api';
import client from '../../../config/axios-config';
import { Anexo } from '../../../models/anexo';
import { Instituicao } from '../../../models/instituicao';

export interface IConfiguracoesFundacao {
  fundacaoInstituicaoId: string;
  instituicao: Instituicao;
  logoCabecalho?: Anexo;
  [key: string]: any;
}

export interface UserPayload {
  id: number;
  nome: string;
  email: string;
  fotoPerfil?: string;
}

interface AuthData {
  token: string;
  user: UserPayload;
  permissions: string[];
  expiresIn: number;
}

export interface AuthContextData {
  login: (authData: AuthData) => void;
  logout: () => void;
  dadosFundacao: (configuracoesFundacao: IConfiguracoesFundacao) => void;
  isLoggedIn: boolean;
  token: string;
  permissions: string[];
  configuracoesFundacao: IConfiguracoesFundacao;
  loggedInUser?: UserPayload;
  updateUserProfileImage: (profileImage: string) => void;
  updateUserName: (nome: string) => void;
}

const AuthContext = createContext<AuthContextData | undefined>(undefined);

export const AuthProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token') || '';
  const permissions = JSON.parse(localStorage.getItem('permissions') || '[]') as string[];
  const configuracoesFundacao = JSON.parse(
    localStorage.getItem('configuracoesFundacao') || '{}',
  ) as IConfiguracoesFundacao;
  const isLoggedIn = !!token;
  const [loggedInUser, setLoggedInUser] = React.useState<UserPayload>();

  useEffect(() => {
    const loginApi = makeLoginApi();
    loginApi
      .obterConfiguracoesFundacao()
      .then((config) => {
        if (config?.status === 200 && config.data.fundacaoInstituicaoId) {
          dadosFundacao(config.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    getLoggedInUser();
  }, []);

  const getLoggedInUser = async () => {
    client.get('/usuario/autenticado').then((response) => {
      if (response.status === 200) {
        setLoggedInUser(response.data);
      }
    });
  };

  const updateUserProfileImage = (profileImage: string) => {
    setLoggedInUser({ ...loggedInUser!, fotoPerfil: profileImage });
  };

  const updateUserName = (nome: string) => {
    setLoggedInUser({ ...loggedInUser!, nome: nome });
  };

  const login = (authData: AuthData) => {
    localStorage.setItem('token', authData.token);
    localStorage.setItem('permissions', JSON.stringify(authData.permissions));
    getLoggedInUser();
    navigate('/home');
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    navigate('/login');
  };

  const dadosFundacao = (configuracoesFundacao: IConfiguracoesFundacao) => {
    localStorage.setItem('configuracoesFundacao', JSON.stringify(configuracoesFundacao));
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        dadosFundacao,
        token,
        permissions,
        isLoggedIn,
        configuracoesFundacao,
        loggedInUser,
        updateUserProfileImage,
        updateUserName,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext deve ser usado dentro de um AuthProvider');
  }
  return context;
};
