import { Trash3 } from '@styled-icons/bootstrap/Trash3';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeApi } from '../../../api/generic-api';
import { Severity } from '../../../components/crud/alert-custom';
import { useCrudContext } from '../../../components/crud/crud-context';
import CustomMultipleUserSelect from '../../../components/custom-multiple-user-select/custom-multiple-user-select';
import { moduleNameFor } from '../../../helpers/module-name-for';
import { Usuario } from '../../../models/usuario';
import { headCells, moduleLabel, moduleName } from './convite-participantes.headcell';
import { EditalConviteParticipante } from '../../../models/edital-convite-participante';

function ConviteParticipantes() {
  const { id, setId, setCurrentTitle } = useCrudContext();
  const { editalId } = useParams();
  const editalIdNumber = Number(editalId);
  const apiSearchInstance = makeApi<Usuario>('/usuario');
  const apiCreateInstance = makeApi<EditalConviteParticipante>('/edital-convite-participante');

  const [openDialog, setOpenDialog] = useState(false);
  const [apiListData, setApiListData] = useState([]);
  const [usuarios, setUsuarios] = useState<Usuario[]>([]);
  const [selectedUsuarios, setSelectedUsuarios] = useState<any[]>([]);
  const [filters, setFilters] = useState<any>({ editalId: editalIdNumber });
  const [openAlert, setOpenAlert] = useState(false);
  const [severityAlert, setSeverityAlert] = useState<Severity>(Severity.SUCCESS);
  const [messageAlert, setMessageAlert] = useState<string>('Permissões salvas com sucesso!');

  const onTextChange = (searchText: string) => {
    setFilters({ editalId: editalIdNumber, nomeUsuario: searchText });
  };

  const onCreateParticipants = () => {
    setFilters({ editalId: editalIdNumber });
  };

  const handleOpenDelete = (id: number) => {
    setId(id);
    setOpenDialog(true);
  };

  const handleCloseDelete = () => {
    setOpenDialog(false);
    setTimeout(() => setId(-1), 100);
  };

  const showSuccess = (message: string) => {
    setMessageAlert(message);
    setSeverityAlert(Severity.SUCCESS);
    setOpenAlert(true);
    setTimeout(() => setOpenAlert(false), 3000);
  };

  const showError = (message: string) => {
    setMessageAlert(message);
    setSeverityAlert(Severity.ERROR);
    setOpenAlert(true);
    setTimeout(() => setOpenAlert(false), 3000);
  };

  const destroy = async () => {
    const response = await apiCreateInstance?.delete?.(id).catch((e) => {
      showError(e?.cause?.response?.data?.message?.[0] || 'Ocorreu um erro.');
    });
    if (response.status === 200) {
      apiCreateInstance
        ?.getAll?.(filters)
        .then((data: any) => {
          setApiListData(data);
          handleCloseDelete();
          showSuccess('Excluído com sucesso.');
        })
        .catch((e) => {
          showError(e?.cause?.response?.data?.message?.[0] || 'Ocorreu um erro.');
        });
    }
  };

  const handleAdd = (e: any) => {
    const fetchData = async () => {
      const createData = selectedUsuarios.map((usuario) => {
        return { editalId: editalId, usuarioId: usuario.id };
      });
      apiCreateInstance
        .post({ editalConviteParticipantes: createData })
        .then(() => {
          showSuccess('Participante(s) adicionado(s) com sucesso!');
          onCreateParticipants();
          setSelectedUsuarios([]);
          setUsuarios([]);
        })
        .catch((e) => {
          showError(e?.cause?.response?.data?.message?.[0] || 'Ocorreu um erro.');
        });
    };
    fetchData();
  };

  useEffect(() => {
    apiCreateInstance
      ?.getAll?.(filters)
      .then((data: any) => {
        setApiListData(data);
      })
      .catch((e) => {
        showError(e?.cause?.response?.data?.message?.[0] || 'Ocorreu um erro.');
      });
  }, [filters]);

  useEffect(() => {
    setCurrentTitle(moduleLabel);
  }, []);

  const deletarParticipante = {
    hasPermission: ['DELETAR'],
    moduleName: moduleNameFor('convite-participantes'),
    icon: <Trash3 size={20} style={{ color: 'red' }} />,
    title: 'Deletar Participante',
    handler: (row: any) => {
      handleOpenDelete(row.id);
    },
  };

  return (
    <CustomMultipleUserSelect
      headCells={headCells}
      moduleName={moduleName}
      moduleLabel={moduleLabel}
      apiSearchInstance={apiSearchInstance}
      apiListData={apiListData}
      customActions={[() => deletarParticipante]}
      onTextChange={onTextChange}
      onCreateParticipants={onCreateParticipants}
      handleAdd={handleAdd}
      usuariosState={{ usuarios, setUsuarios }}
      selectedUsuariosState={{ selectedUsuarios, setSelectedUsuarios }}
      dialogProps={{ openDialog, handleCloseDelete, destroy }}
      alertCustom={{ openAlert, severityAlert, messageAlert }}
      hasSecure={true}
    ></CustomMultipleUserSelect>
  );
}

export default ConviteParticipantes;

export const rootViews = ['edital'];
