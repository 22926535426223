import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import isEmpty from 'lodash/isEmpty';
import { Dispatch, Fragment } from 'react';
import { PropostaConviteMembroApi } from '../../api/proposta-convite-membro-api';
import Loading from '../crud/loading';
import { PropostaConviteMembro } from '../../models/proposta-convite-membro';
import {
  AcaoLabel,
  AcceptListItemButton,
  AceitarLabel,
  BackgroundBoxActionButtons,
  BackgroundBoxButtons,
  BackgroundItem,
  BackgroundList,
  BackgroundListItem,
  ComponentBox,
  ConviteList,
  CoordenadorBox,
  CoordenadorListItem,
  InformacoesLabel,
  RecusarLabel,
  RefuseListItemButton,
  StyledDocumentFolder,
  StyledPersonPin,
  TituloInformacoesLabel,
  TituloLabel,
} from './style/proposta-convite-membro';

type propostaConviteMembroProps<T> = {
  apiResultData: PropostaConviteMembro[];
  propostaConviteMembroApi: PropostaConviteMembroApi;
  loadState: {
    load: boolean;
    setLoad: Dispatch<React.SetStateAction<boolean>>;
  };
  loadAvisos(): any;
};

export default function PropostaConviteMembros<T extends Record<string, any>>({
  apiResultData,
  propostaConviteMembroApi,
  loadState,
  loadAvisos,
}: propostaConviteMembroProps<T>) {
  const { load, setLoad } = loadState;
  const [confirmacao, setConfirmacao] = useState<{
    id: number | null;
    acao: 'aceitar' | 'recusar' | null;
  }>({ id: null, acao: null });

  const handleAceitar = (conviteId: any) => {
    propostaConviteMembroApi
      .aceitar(conviteId, { dataAceite: Date.now() })
      .then(() => {
        setLoad(true);
        loadAvisos();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleRecusar = (conviteId: any) => {
    propostaConviteMembroApi
      .recusar(conviteId)
      .then(() => {
        setLoad(true);
        loadAvisos();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleConfirmacao = (conviteId: any, acao: 'aceitar' | 'recusar') => {
    setConfirmacao({ id: conviteId, acao });
  };

  const handleCancelar = () => {
    setConfirmacao({ id: null, acao: null });
  };

  const handleConfirmarAcao = (conviteId: any) => {
    if (confirmacao.acao === 'aceitar') {
      handleAceitar(conviteId);
    } else if (confirmacao.acao === 'recusar') {
      handleRecusar(conviteId);
    }
    handleCancelar();
  };

  return isEmpty(apiResultData) ? (
    <></>
  ) : (
    <ComponentBox>
      <BackgroundList>
        {load ? (
          <Loading isLoading={load} />
        ) : (
          apiResultData.map((item, index) => (
            <Fragment key={index}>
              <BackgroundItem>
                {confirmacao.id === item.id ? (
                  // Exibir a mensagem de confirmação e os botões
                  <BackgroundBoxActionButtons>
                    <BackgroundBoxButtons>
                      {confirmacao.acao === 'aceitar' ? (
                        <ConviteList>
                          <AcaoLabel>Deseja realmente</AcaoLabel>{' '}
                          <AceitarLabel>aceitar</AceitarLabel>{' '}
                          <AcaoLabel>o convite? A ação não poderá ser desfeita.</AcaoLabel>
                        </ConviteList>
                      ) : (
                        <ConviteList>
                          <AcaoLabel>Deseja realmente</AcaoLabel>{' '}
                          <RecusarLabel>recusar</RecusarLabel>{' '}
                          <AcaoLabel>o convite? A ação não poderá ser desfeita.</AcaoLabel>
                        </ConviteList>
                      )}
                    </BackgroundBoxButtons>
                    <BackgroundBoxButtons>
                      <AcceptListItemButton onClick={() => handleConfirmarAcao(item.id)}>
                        <Box>Confirmar</Box>
                      </AcceptListItemButton>
                      <RefuseListItemButton onClick={handleCancelar}>
                        <Box>Cancelar</Box>
                      </RefuseListItemButton>
                    </BackgroundBoxButtons>
                  </BackgroundBoxActionButtons>
                ) : (
                  <BackgroundList>
                    <BackgroundListItem>
                      <TituloLabel>{item.proposta.tituloDoProjeto}</TituloLabel>
                    </BackgroundListItem>
                    <BackgroundListItem>
                      <StyledDocumentFolder />
                      <TituloInformacoesLabel>Edital:</TituloInformacoesLabel>
                      <InformacoesLabel>{item.proposta.edital.nome}</InformacoesLabel>
                    </BackgroundListItem>
                    <CoordenadorListItem>
                      <CoordenadorBox>
                        <StyledPersonPin />
                        <TituloInformacoesLabel>Coordenador:</TituloInformacoesLabel>
                        <InformacoesLabel>{item.proposta.criadoPor.nome}</InformacoesLabel>
                      </CoordenadorBox>
                      <BackgroundBoxButtons>
                        <AcceptListItemButton onClick={() => handleConfirmacao(item.id, 'aceitar')}>
                          <Box>Aceitar</Box>
                        </AcceptListItemButton>
                        <RefuseListItemButton onClick={() => handleConfirmacao(item.id, 'recusar')}>
                          <Box>Recusar</Box>
                        </RefuseListItemButton>
                      </BackgroundBoxButtons>
                    </CoordenadorListItem>
                  </BackgroundList>
                )}
              </BackgroundItem>
              {index < apiResultData.length - 1 && <Divider />}
            </Fragment>
          ))
        )}
      </BackgroundList>
    </ComponentBox>
  );
}
