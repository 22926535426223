import { HeadCell } from '../../components/crud/protocols/head-cell';

export interface Data extends Record<string, any> {
  id: number;
  nomePt: string;
}

export const displayProperties = ['nomePt'];

export const moduleLabel = 'Sexo';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'nomePt',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
];
