import { instituicaoInstrumentoJuridicoIdRequired } from './../cronograma-desembolso/cronograma-desembolso.errors';
import { FormApi } from '../../../api/generic-api';
import { IUiSchema } from '../../../jsonforms/uischema';
import { InstituicaoInstrumentoJuridico } from '../../../models/instituicao-instrumento-juridico';

const instituicaoInstrumentoJuridicoUiSchema: IUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'Select',
          scope: '#/properties/instituicaoId',
          options: {
            required: true,
            filter: {
              formFields: ['instrumentoJuridicoId'],
              handler: (
                formData: InstituicaoInstrumentoJuridico,
                listData: InstituicaoInstrumentoJuridico[],
                api: FormApi<InstituicaoInstrumentoJuridico>,
              ): Promise<InstituicaoInstrumentoJuridico[]> => {
                if (!formData.instrumentoJuridicoId) return Promise.resolve(listData);
                let filters: { [key: string]: any } = { withDeleted: false };
                if (!formData.id) {
                  filters.instrumentoJuridicoId = formData.instrumentoJuridicoId;
                }
                return api?.getAll?.(filters);
              },
            },
          },
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'LongText',
          scope: '#/properties/objetivoInstrumentoJuridico',
          label: 'Objetivo do Instrumento Jurídico',
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Select',
              scope: '#/properties/tipoParceriaId',
              options: {
                required: true,
              },
            },
            {
              type: 'Control',
              scope: '#/properties/porcentagemOutrosFins',
              label: '% para outros fins',
            },
          ],
        },
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'MonetaryInput',
              scope: '#/properties/valorFirmado',
              label: 'Valor Firmado',
            },
          ],
        },
      ],
    },
  ],
};

export default instituicaoInstrumentoJuridicoUiSchema;
