import { pascalCase } from 'change-case';
import { makeApi } from '../../../api/generic-api';
import { CrudContextData } from '../../../components/crud/crud-context';
import { IStepCallback } from '../../../jsonforms/uischema/uischema.type';

export const stepHandlerDocumentoPropostaEdital = async (
  fieldsConfig: { paths: string[]; instancePaths: string[]; requiredScopes: string[] },
  ctx: CrudContextData,
  data: any,
  url: string,
  callback?: IStepCallback,
): Promise<boolean> => {
  const { formData, setFormData, crudStates, parameterFilters, setParameterFilters, showError } =
    ctx;
  const api = makeApi(url);
  const urlVar = `step${pascalCase(url?.replace?.('/', ''))}Id`;
  const isCreated = !!(
    formData.id &&
    parameterFilters?.[urlVar] &&
    Number(parameterFilters?.[urlVar]) === formData?.id
  );
  if (crudStates.add && !isCreated) {
    const postResponse = await api?.post?.(formData);
    if (postResponse.status === 201) {
      setParameterFilters({ ...parameterFilters, [urlVar]: postResponse?.data?.id });
      setFormData({ ...formData, ...postResponse.data });
      return true;
    } else {
      console.error(postResponse);
      return false;
    }
  }
  if (crudStates.edit || (crudStates.add && isCreated)) {
    let putResponse;
    try {
      putResponse = await api?.put?.(formData?.id, formData);
      if (putResponse.status === 200) {
        return true;
      }
    } catch (error: any) {
      console.error(putResponse);
      let errorMessage = error?.cause?.response?.data?.message || error?.response?.data?.message;
      if (Array.isArray(errorMessage)) errorMessage = errorMessage?.[0];
      errorMessage = errorMessage
        ?.replace?.('documentoPropostaEdital.', 'Documento da Proposta ')
        .replace?.(/(\d+)\.(\s*)/, (_: any, p1: any, p2: any) => {
          let incrementedNumber = parseInt(p1, 10) + 1;
          return incrementedNumber + '. ' + p2;
        });
      showError(errorMessage || 'Ocorreu um erro.');
      return false;
    }
  }
  return true;
};
