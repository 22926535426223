import styled from '@emotion/styled';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { Blocked } from '@styled-icons/icomoon/Blocked';
import { CheckSquare } from 'styled-icons/boxicons-solid';

export const ViewIcon = styled(VisibilityIcon)`
  color: var(--color-icon);
`;

export const UpdateIcon = styled(EditIcon)({
  color: '#5DC1F2',
});

export const RestoreIcon = styled(CheckSquare)({
  color: '#16C60C',
});

export const DisableIcon = styled(Blocked)({
  color: 'red',
});

export const StyledTableCell = styled(TableCell)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  gap: '1rem',
  height: '48px',
  padding: 0,
});

export const StyledMenu = styled(Menu)({
  '& .MuiMenu-list': {
    padding: 0,
  },
});

export const ActionMenuItem = styled(MenuItem)({
  padding: '0.5rem 1rem 0.5rem 0.5rem',
});

export const ActionTitle = styled(Typography)({
  fontFamily: 'Raleway',
  fontSize: '0.875rem',
});
