import { IUiSchema } from '../../../jsonforms/uischema';

const diariasUiSchema: IUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      scope: '#/properties/descricao',
      label: 'Descrição',
      options: {
        required: true,
      },
    },
    {
      type: 'Control',
      scope: '#/properties/localidade',
    },
    {
      type: 'Select',
      scope: '#/properties/nivelAcademicoId',
      options: {
        required: true,
      },
    },
    {
      type: 'Currency',
      elements: [
        {
          type: 'MonetaryInput',
          scope: '#/properties/valorDiaria',
          label: 'Valor Diária',
          options: {
            required: true,
            isPositiveNumber: true,
          },
        },
        {
          type: 'Select',
          scope: '#/properties/moedaId',
          options: {
            required: true,
          },
        },
      ],
    },
  ],
};

export default diariasUiSchema;
