import { authStringsFor } from '../../helpers/auth-strings-for';
import { ISecureOptions } from '../../helpers/secure-object';
import { useAuthContext } from '../../views/login/auth/auth-context';

export interface ISecureProps extends ISecureOptions, React.PropsWithChildren<{}> {
  ignorePermission?: boolean;
}

export const Secure = ({ hasPermission, moduleName, ignorePermission, children }: ISecureProps) => {
  if (ignorePermission) return <>{children}</>;

  const { permissions } = useAuthContext();
  let permissionGranted: boolean = true;

  if (typeof hasPermission === 'function')
    permissionGranted = hasPermission?.(moduleName, permissions);

  if (typeof hasPermission === 'string')
    permissionGranted = authStringsFor(`${moduleName}.${hasPermission}`).some((x) =>
      permissions.includes(x),
    );

  if (Array.isArray(hasPermission))
    permissionGranted = hasPermission.reduce(
      (acc, value) =>
        acc && authStringsFor(`${moduleName}.${value}`).some((x) => permissions.includes(x)),
      true,
    );

  return permissionGranted ? <>{children}</> : null;
};
