import {
  ArrayLayoutProps,
  JsonFormsUISchemaRegistryEntry,
  RankedTester,
  createDefaultValue,
  findUISchema,
  rankWith,
  uiTypeIs,
  update,
} from '@jsonforms/core';
import { JsonFormsDispatch, useJsonForms, withJsonFormsArrayLayoutProps } from '@jsonforms/react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import Hidden from '@mui/material/Hidden';
import merge from 'lodash/merge';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useCrudContext } from '../../components/crud/crud-context';
import { generateRandomToken } from '../../helpers/generate-random-token';
import { IOptions, TableLayoutHeadCell } from '../uischema';
import './array-layout-render.css';
import {
  ActionButton,
  ActionsContainer,
  AddButton,
  StyledAddIcon,
  StyledCheckIcon,
  StyledPaper,
  StyledTable,
  TableLayoutActionCell,
  TableLayoutCell,
  TableLayoutHeader,
  TableLayoutHeaderCell,
  TableLayoutRow,
} from './styled/array-table-layout-render';

const ArrayTableLayoutComponent = (props: ArrayLayoutProps) => {
  const {
    enabled,
    data,
    path,
    schema,
    uischema,
    addItem,
    errors,
    removeItems,
    renderers,
    cells,
    rootSchema,
    config,
    uischemas,
  } = props;
  const { showError, setValidationMode } = useCrudContext();
  const { dispatch, core } = useJsonForms();

  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [listData, setListData] = useState<any[]>([]);

  const appliedUiSchemaOptions: IOptions = merge({}, config, props.uischema.options);
  const headCells = uischema.options?.headCells as TableLayoutHeadCell[];

  const isEditing = (): boolean => editIndex !== null && editIndex !== undefined;

  useEffect(() => {
    setListData(core.data[path]);
  }, [core.data, path]);

  const getUnsavedItem = useCallback(() => {
    return core.data[`${path}Unsaved`];
  }, [core.data, path]);

  const saveItem = useCallback(() => {
    const { beforeCreate } = appliedUiSchemaOptions;
    const shouldCreate = beforeCreate?.(core.data, path) ?? true;

    setValidationMode('ValidateAndShow');

    if (errors.length > 0) return;

    if (isEditing()) {
      setEditIndex(null);
      return;
    }

    if (!shouldCreate) {
      showError('Não foi possível adicionar um novo item');
      return;
    }

    addItem?.(path, innerCreateDefaultValue())?.();
    dispatch(update(`${path}Unsaved`, () => {}));
    setValidationMode('ValidateAndHide');
  }, [appliedUiSchemaOptions, core.data[path], path, editIndex, addItem, dispatch]);

  const innerCreateDefaultValue = useCallback(() => {
    const { defaultValue } = appliedUiSchemaOptions;

    let result: any;
    if (defaultValue) {
      result =
        typeof defaultValue === 'function'
          ? defaultValue(core.data, appliedUiSchemaOptions)
          : defaultValue;
    } else {
      result = createDefaultValue(props.schema, props.rootSchema);
    }

    return { uniqueArrayKey: generateRandomToken(8), ...(result ?? {}), ...getUnsavedItem() };
  }, [appliedUiSchemaOptions, core.data, props.schema, props.rootSchema, getUnsavedItem]);

  const foundUISchema = useMemo(
    () =>
      findUISchema(
        uischemas as JsonFormsUISchemaRegistryEntry[],
        schema,
        uischema.scope,
        path,
        undefined,
        uischema,
        rootSchema,
      ),
    [uischemas, schema, uischema.scope, path, uischema, rootSchema],
  );

  const getCellContent = (row: unknown, headCell: TableLayoutHeadCell) => {
    const { field } = headCell;
    return typeof field === 'function' ? field(row) ?? '-' : row[field] ?? '-';
  };

  const editItem = (index: number) => () => {
    setEditIndex(index);
  };

  const deleteItem = (path: string, index: number) => () => {
    removeItems(path, [index])?.();
    setEditIndex(null);
  };

  return (
    <>
      <StyledPaper>
        <StyledTable size='small' aria-label='dense-data-table'>
          <TableLayoutHeader>
            <TableRow>
              {headCells?.map((headCell: TableLayoutHeadCell) => (
                <TableLayoutHeaderCell key={headCell?.label}>
                  {headCell.label}
                </TableLayoutHeaderCell>
              ))}
              {data !== 0 ? (
                <TableLayoutHeaderCell key={'actions'}>Ações</TableLayoutHeaderCell>
              ) : null}
            </TableRow>
          </TableLayoutHeader>
          <TableBody>
            {data === 0 ? (
              <TableRow>
                <TableCell align='center' colSpan={headCells?.length + 1}>
                  Sem dados
                </TableCell>
              </TableRow>
            ) : (
              listData?.map((row: any, index: number) => (
                <TableLayoutRow key={row?.uniqueArrayKey}>
                  {headCells?.map((headCell: TableLayoutHeadCell) => (
                    <TableLayoutCell key={headCell?.label}>
                      {getCellContent(row, headCell)}
                    </TableLayoutCell>
                  ))}
                  <TableLayoutActionCell>
                    <ActionButton disabled={!enabled} variant='text' onClick={editItem(index)}>
                      Editar
                    </ActionButton>
                    <ActionButton
                      disabled={!enabled}
                      variant='text'
                      onClick={deleteItem(path, index)}
                    >
                      Excluir
                    </ActionButton>
                  </TableLayoutActionCell>
                </TableLayoutRow>
              ))
            )}
          </TableBody>
        </StyledTable>
      </StyledPaper>
      <ActionsContainer>
        <AddButton
          disabled={!enabled}
          onClick={saveItem}
          variant='contained'
          startIcon={isEditing() ? <StyledCheckIcon /> : <StyledAddIcon />}
          className='add-button'
        >
          {isEditing() ? 'Atualizar' : 'Adicionar'}
        </AddButton>
      </ActionsContainer>
      <JsonFormsDispatch
        key={isEditing() ? `edit-${editIndex}` : `create-${data}`}
        enabled={enabled}
        schema={schema}
        uischema={foundUISchema}
        path={isEditing() ? `${path}.${editIndex}` : `${path}Unsaved`}
        renderers={renderers}
        cells={cells}
      />
    </>
  );
};

const ArrayTableLayout = memo(ArrayTableLayoutComponent);

const ArrayTableLayoutRenderer = ({ visible, addItem, ...props }: ArrayLayoutProps) => {
  const addItemCb = useCallback((p: string, value: unknown) => addItem(p, value), [addItem]);

  return (
    <Hidden xsUp={!visible}>
      <ArrayTableLayout visible={visible} addItem={addItemCb} {...props} />
    </Hidden>
  );
};

const arrayTableLayoutTester: RankedTester = rankWith(5, uiTypeIs('ArrayTableLayout'));

export const arrayTableLayoutRenderer = {
  tester: arrayTableLayoutTester,
  renderer: withJsonFormsArrayLayoutProps(ArrayTableLayoutRenderer),
};
