import { NaturezaDespesa } from './natureza-despesa';
import { Edital } from './edital';
import { Moeda } from './moeda';
import { EditalRubricaMoeda } from './edital-rubrica-moeda';
export enum TipoEditalRubrica {
  diarias = 'diarias',
  hospedagem = 'hospedagem',
  alimentacao = 'alimentacao',
  servicosDeTerceiros = 'servicosDeTerceiros',
  materialDeConsumo = 'materialDeConsumo',
  materialPermanenteEquipamentos = 'materialPermanenteEquipamentos',
  passagens = 'passagens',
  contrapartida = 'contrapartida',
  pessoal = 'pessoal',
  encargos = 'encargos',
  bolsa = 'bolsa',
}
export interface EditalRubrica {
  id: number;
  tipoEditalRubrica: TipoEditalRubrica;
  naturezaDespesaId?: number;
  naturezaDespesa: NaturezaDespesa;
  editalId?: number;
  edital: Edital;
  temJustificativaObrigatoria?: boolean;
  temMoedaEstrangeira?: boolean;
  moeda: Moeda[];
  editalRubricaMoeda: EditalRubricaMoeda[];
}
