import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { InstituicaoInstrumentoJuridico } from '../../../models/instituicao-instrumento-juridico';

export const moduleName =
  'ORCAMENTO.INSTURMENTO_JURIDICO.INSTITUICAO_INSTRUMENTO_JURIDICO.INSTITUICAO_INSTRUMENTO_JURIDICO';

export const displayProperties = ['instituicao.nome'];

export const moduleLabel = 'Instituição Instrumento Jurídico';

export const headCells: readonly HeadCell<InstituicaoInstrumentoJuridico>[] = [
  {
    id: 'instituicaoId',
    numeric: true,
    disablePadding: false,
    label: 'Nome da Instituição',
    resource: {
      name: 'instituicao',
      target: 'nome',
    },
  },
  {
    id: 'tipoParceriaId',
    numeric: true,
    disablePadding: false,
    label: 'Parceria',
    resource: {
      name: 'tipoParceria',
      target: 'nome',
    },
  },
  {
    id: 'valorFirmado',
    numeric: true,
    disablePadding: false,
    label: 'Valor Firmado',
    mask: 'money',
  },
  {
    id: 'valorFinal',
    numeric: true,
    disablePadding: false,
    label: 'Valor Final Estimado',
    mask: 'money',
  },
  {
    id: 'somaDesembolso',
    numeric: true,
    disablePadding: false,
    label: 'Soma do Desembolso',
    mask: 'money',
  },
  {
    id: 'desembolsoPago',
    numeric: true,
    disablePadding: false,
    label: 'Desembolso Pago',
    mask: 'money',
  },
];
