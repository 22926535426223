import styled from '@emotion/styled';
import Loading from '../loading';

export const StyledLoading = styled(Loading)`
  height: 100vh !important;
`;

export const Form = styled.div`
  margin: auto;
  padding: 0.4rem;
  display: flex;
  justify-content: center;

  @media (min-width: 1050px) {
    width: 950px;
    margin-top: 3rem;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 0;
`;

export const Loader = styled.div`
  height: 100vh !important;
`;
