import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { Search } from '@styled-icons/ionicons-sharp/Search';

export const BoxContainer = styled(Box)({
  fontFamily: 'Raleway',
  '& input, & button, & p': {
    fontFamily: 'inherit',
    fontSize: '1rem',
  },
});

export const SearchIcon = styled(Search)({
  margin: '0 1rem',
  color: '#434653',
});

export const DescriptionText = styled(Typography)({
  margin: '1rem 0 1.5rem 0',
  fontWeight: '500',
});

export const SearchBox = styled('div')({
  display: 'flex',
  backgroundColor: '#E7E7F0',
  marginBottom: '1rem',
  borderRadius: '30px',
  alignItems: 'center',
});

export const SearchInput = styled('input')({
  background: 'none !important',
  border: 'none',
  borderRadius: '30px',
  padding: '1rem 1.5rem',

  '&:focus': {
    outline: 'none',
  },
});

export const ItemGridButton = styled(Button)(({ theme }) => ({
  width: '100%',
  boxSizing: 'border-box',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  boxShadow: 'none',
  color: '#FFFFFF',
  fontWeight: '600',
  borderRadius: '20px',
  padding: '0.35rem',
  textTransform: 'none',
  transition: 'none',

  // Estilos específicos para variant="contained"
  '&.MuiButton-contained': {
    backgroundColor: '#FFFFFF',
    color: '#003D92',
    border: '1px solid #003D92',

    '&:hover': {
      boxShadow: 'none',
    },
  },

  // Estilos específicos para variant="outlined"
  '&.MuiButton-outlined': {
    backgroundColor: '#C3C6D5',
    border: '1px solid #C3C6D5',

    '&:hover': {},
  },
}));
