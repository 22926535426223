import {
  ArrayLayoutProps,
  RankedTester,
  composePaths,
  computeLabel,
  createDefaultValue,
  isObjectArrayWithNesting,
  rankWith,
} from '@jsonforms/core';
import { withJsonFormsArrayLayoutProps } from '@jsonforms/react';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';
import map from 'lodash/map';
import merge from 'lodash/merge';
import range from 'lodash/range';
import { memo, useCallback, useEffect, useState } from 'react';
import { ArrayToolbar } from '../../components/layout/array-toolbar';
import ExpandableItemLayout from '../../components/layout/expandable-item-layout';
import SimpleItemLayout from '../../components/layout/simple-item-layout';
import './array-layout-render.css';
import { IControlElement, IOptions } from '../uischema';
import { useCrudContext } from '../../components/crud/crud-context';
import { generateRandomToken } from '../../helpers/generate-random-token';

const MaterialArrayLayoutComponent = (props: ArrayLayoutProps) => {
  const [expanded, setExpanded] = useState<string | boolean>(false);
  const [disableAdd, setDisableAdd] = useState<boolean>(false);
  const { formData, showError } = useCrudContext();
  const {
    enabled,
    data,
    path,
    schema,
    uischema,
    errors,
    addItem,
    renderers,
    cells,
    label,
    required,
    rootSchema,
    config,
    uischemas,
    translations,
    description,
  } = props;

  const itemLayout = (uischema as IControlElement)?.options?.itemLayout;

  const appliedUiSchemaOptions: IOptions = merge({}, config, props.uischema.options);

  useEffect(() => {
    setDisableAdd(data === 0);
  }, [data]);

  const createNewItem = useCallback(() => {
    const { beforeCreate } = appliedUiSchemaOptions;
    const shouldCreate = beforeCreate?.(formData, path) ?? true;

    if (shouldCreate) {
      addItem(path, innerCreateDefaultValue())?.();
    } else {
      showError('Não foi possível adicionar um novo item');
    }
  }, [appliedUiSchemaOptions]);

  const innerCreateDefaultValue = useCallback(() => {
    const { defaultValue } = appliedUiSchemaOptions;

    let result: any;
    if (defaultValue) {
      result =
        typeof defaultValue === 'function'
          ? defaultValue(formData, appliedUiSchemaOptions)
          : defaultValue;
    } else {
      result = createDefaultValue(props.schema, props.rootSchema);
    }

    return { uniqueArrayKey: generateRandomToken(8), ...(result ?? {}) };
  }, [appliedUiSchemaOptions]);

  const handleChange = useCallback(
    (panel: string) => (_event: any, expandedPanel: boolean) => {
      setExpanded(expandedPanel ? panel : false);
    },
    [],
  );

  const isExpanded = (index: number) => expanded === composePaths(props.path, `${index}`);

  return (
    <Box className='array-layout'>
      <ArrayToolbar
        translations={translations}
        label={computeLabel(label, required!, appliedUiSchemaOptions.hideRequiredAsterisk)}
        description={description!}
        errors={errors}
        path={path}
        enabled={enabled}
        addItem={() => createNewItem}
        createDefault={innerCreateDefaultValue}
        disableAdd={disableAdd}
      />
      <Box className='array-layout-container'>
        {data > 0 ? (
          map(range(data), (index) => {
            if (formData?.[path]?.[index] && !('uniqueArrayKey' in formData[path][index])) {
              formData[path][index]['uniqueArrayKey'] = generateRandomToken(8);
            }
            const keyField = formData?.[path]?.[index]?.['uniqueArrayKey'] ?? '';

            return itemLayout === 'SimpleItemLayout' ? (
              <SimpleItemLayout
                enabled={enabled}
                index={index}
                schema={schema}
                path={path}
                uischema={uischema}
                renderers={renderers}
                cells={cells}
                key={`${keyField}`}
                rootSchema={rootSchema}
                config={config}
                childLabelProp={appliedUiSchemaOptions.elementLabelProp}
                uischemas={uischemas}
                translations={translations}
              />
            ) : (
              <ExpandableItemLayout
                enabled={enabled}
                index={index}
                expanded={isExpanded(index)}
                schema={schema}
                path={path}
                handleExpansion={handleChange}
                uischema={uischema}
                renderers={renderers}
                cells={cells}
                key={`${keyField}`}
                rootSchema={rootSchema}
                enableMoveUp={appliedUiSchemaOptions.showSortButtons}
                enableMoveDown={appliedUiSchemaOptions.showSortButtons}
                config={config}
                childLabelProp={appliedUiSchemaOptions.elementLabelProp}
                uischemas={uischemas}
                translations={translations}
              />
            );
          })
        ) : (
          <Box className='no-data-container'>
            <Typography className='no-data-label'>Nenhuma informação cadastrada</Typography>
            {enabled && (
              <Button
                onClick={createNewItem}
                variant='contained'
                startIcon={<AddIcon />}
                className='add-button'
              >
                Adicionar
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const MaterialArrayLayout = memo(MaterialArrayLayoutComponent);

const MaterialArrayLayoutRenderer = ({ visible, addItem, ...props }: ArrayLayoutProps) => {
  const addItemCb = useCallback((p: string, value: any) => addItem(p, value), [addItem]);

  return (
    <Hidden xsUp={!visible}>
      <MaterialArrayLayout visible={visible} addItem={addItemCb} {...props} />
    </Hidden>
  );
};

const materialArrayLayoutTester: RankedTester = rankWith(5, isObjectArrayWithNesting);

export const materialArrayLayoutRenderer = {
  tester: materialArrayLayoutTester,
  renderer: withJsonFormsArrayLayoutProps(MaterialArrayLayoutRenderer),
};
