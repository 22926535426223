import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { Search } from '@styled-icons/evil/Search';
import { ChangeEvent, FC } from 'react';

interface SearchInputArgs {
  placeholder?: string;
  value: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const SearchInput: FC<SearchInputArgs> = ({ placeholder, value, onChange }) => {
  return (
    <Paper
      component='form'
      elevation={0}
      sx={{
        p: '0px 10px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '4px',
        backgroundColor: '#FAFAFA',
        border: 'solid 1px lightgrey',
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        inputProps={{ 'aria-label': 'pesquisar mensagens' }}
      />
      <IconButton type='button' sx={{ p: '10px' }} aria-label='search'>
        <Search size={24} />
      </IconButton>
    </Paper>
  );
};
