import { rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Hidden from '@mui/material/Hidden';
import { useCrudContext } from '../../components/crud/crud-context';
import Editor from '../../components/layout/ckeditor';
import { getFirstError } from '../../utils/get-first-error';
import { IControlElement } from '../uischema';
import './renderer.css';

const CkEditorRenderTester = rankWith(5, uiTypeIs('CkEditor'));

export const CkEditorRender = {
  tester: CkEditorRenderTester,
  renderer: withJsonFormsControlProps(
    ({ visible = true, enabled = true, path, schema, ...props }) => {
      const uischema = props.uischema as IControlElement;

      const { crudStates, validationMode, disabledFields } = useCrudContext();

      const isDisabled: boolean =
        !enabled ||
        crudStates.view ||
        (crudStates.edit && uischema?.options?.onlyCreate) ||
        uischema?.options?.disabled ||
        disabledFields.includes(path);

      const handleBlur = (editorData: string) => {
        props.handleChange(path, editorData);
      };

      return (
        <Hidden xsUp={!visible} key={`${path}-ckeditor`}>
          <div>
            <Editor
              isDisabled={isDisabled}
              placeholder='Digite algum texto aqui para começar...'
              initialValue={props.data}
              onBlur={handleBlur}
              size={uischema.options?.ckeditorSize}
              toolbar={uischema.options?.toolbar}
            />

            {validationMode === 'ValidateAndShow' && props.errors && (
              <span className='error-message'>{getFirstError(props.errors)}</span>
            )}
          </div>
        </Hidden>
      );
    },
  ),
};
