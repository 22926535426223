import { or, rankWith, schemaMatches, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Hidden from '@mui/material/Hidden';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import isEmpty from 'lodash/isEmpty';
import React, { ChangeEvent, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { withMask } from 'use-mask-input';
import { useCrudContext } from '../../components/crud/crud-context';
import { getFirstError } from '../../utils/get-first-error';
import { IControlElement } from '../uischema';
import './renderer.css';

const isNumberRender = or(
  schemaMatches(
    (schema) => !isEmpty(schema) && (schema as any)?.type === 'integer' && !(schema as any)?.format,
  ),
  uiTypeIs('Number'),
);

const numberRenderTester = rankWith(5, isNumberRender);

export const numberRender = {
  tester: numberRenderTester,
  renderer: withJsonFormsControlProps(({ visible = true, path, schema, enabled, ...props }) => {
    const uischema = props.uischema as IControlElement;
    const fieldRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const position = useRef({
      beforeStart: 0,
      beforeEnd: 0,
    });

    const [value, setValue] = useState<string>(props.data ?? undefined);

    const ctx = useCrudContext();
    const { formData, validationMode, crudStates, disabledFields, isRequiredField } = ctx;

    const required = uischema.options?.required ?? false;

    // keep cursor position after setting value
    useLayoutEffect(() => {
      inputRef?.current?.setSelectionRange(
        position.current.beforeStart,
        position.current.beforeEnd,
      );
    }, [value]);

    useEffect(() => {
      fieldRef?.current?.childNodes?.forEach?.((childNode) => {
        const node = childNode as HTMLElement;
        const htmlInput = node.children?.[1]?.children[0];
        if (
          childNode.nodeType === 1 &&
          (node.tagName === 'INPUT' || htmlInput?.tagName === 'INPUT')
        ) {
          const inputElement = childNode as HTMLInputElement;
          if (inputElement && props.data !== undefined) {
            inputElement.value = props.data;
          } else if (inputElement) {
            inputElement.value = '';
          }
        }
      });
      setValue(props.data);
    }, [props.data]);

    const isDisabled: boolean =
      !enabled ||
      crudStates.view ||
      uischema?.options?.disabled ||
      (crudStates.edit && uischema?.options?.onlyCreate) ||
      disabledFields.includes(path);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const beforeStart = event.target.selectionStart ?? 0;
      const beforeEnd = event.target.selectionEnd ?? 0;
      position.current = {
        beforeStart,
        beforeEnd,
      };

      const { isPositiveNumber, handleChange } = uischema?.options;

      const val = event?.target?.value?.trim?.();
      const numValue = val ? Number(val) : undefined;

      if (!Number(numValue) || (!!isPositiveNumber && Number(numValue) <= 0)) {
        props.handleChange(path, 0);
        handleChange?.(0, props?.handleChange, formData, ctx, path);
        setValue('0');
      } else {
        props.handleChange(path, numValue);
        handleChange?.(numValue, props?.handleChange, formData, ctx, path);
        setValue(val);
      }
    };

    return (
      <Hidden xsUp={!visible}>
        <div className='custom-input-container' ref={fieldRef}>
          <TextField
            {...props}
            type='number'
            variant='filled'
            className={`input
              ${isDisabled && 'disabled-field'}
              ${props.errors && validationMode === 'ValidateAndShow' && 'has-error'}
            `}
            label={props.label}
            required={isRequiredField(required, ctx)}
            disabled={isDisabled}
            value={value}
            inputRef={uischema?.options?.mask ? withMask(uischema.options.mask) : undefined}
            onChange={handleChange}
            InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
            inputProps={{ step: uischema?.options?.numberStep || 1.0 }}
          />
          {validationMode === 'ValidateAndShow' && props.errors && (
            <span className='error-message'>{getFirstError(props.errors)}</span>
          )}
        </div>
      </Hidden>
    );
  }),
};
