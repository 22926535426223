import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button';
import { DialogActions } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import { SxProps } from '@mui/material/styles';

export const StyledDialogActions = styled(DialogActions)`
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
`;

export const BtnConfirmDelete = styled(Button)`
  color: red !important;
`;

export const BtnCancelDelete = styled(Button)`
  color: #1976d2 !important;
`;

export const DataTableContainer = styled(Box)`
  width: 100%;
`;

export const StyledPaper = styled(Paper)<{ sxProps?: SxProps<Theme> }>`
  display: flex;
  flex-direction: column;
  background-color: #faf8ff;
  background-color: var(--color-background-secondary);
  padding: 16px;
  color: var(--foreground-color);
  ${(props) => props.sxProps && { ...props.sxProps }};
`;

export const StyledToolbar = styled.div`
  width: 100% !important;
`;

export const StyledTableContainer = styled(TableContainer)`
  .table {
    min-width: 750px;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

export const StyledTableBody = styled(TableBody)`
  background-color: var(--color-background-secondary);
  color: var(--foreground-color);
`;

export const StyledTableRow = styled(TableRow)<{ isDeleted?: boolean }>`
  cursor: pointer;
  background-color: ${({ isDeleted }) =>
    isDeleted ? 'var(--color-deleted)' : 'var(--background-color)'};
`;

export const StyledTableCell = styled(TableCell)`
  color: var(--foreground-color);
  padding: 0;
  height: 48px;
  padding-left: 16px;
  text-align: left;
`;

export const StyledTablePagination = styled(TablePagination)`
  width: 100%;
  color: var(--color-foreground);
  background-color: var(--background-color);
  border-bottom: 0px solid var(--color-border);
`;

export const AddButton = styled(Button)`
  margin-right: 50px !important;
  color: green !important;
`;
