import styled from '@emotion/styled';
import {
  Categorization,
  Category,
  RankedTester,
  StatePropsOfLayout,
  and,
  categorizationHasCategory,
  deriveLabelForUISchemaElement,
  isVisible,
  optionIs,
  rankWith,
  uiTypeIs,
} from '@jsonforms/core';
import {
  AjvProps,
  MaterialLayoutRenderer,
  MaterialLayoutRendererProps,
  withAjvProps,
} from '@jsonforms/material-renderers';
import { TranslateProps, withJsonFormsLayoutProps, withTranslateProps } from '@jsonforms/react';
import Button from '@mui/material/Button';
import Hidden from '@mui/material/Hidden';
import Step, { StepProps } from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import merge from 'lodash/merge';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Footsteps } from 'styled-icons/ionicons-sharp';
import { useCrudContext } from '../../components/crud/crud-context';
import { getUischemaConfigs } from '../../helpers/get-uischema-configs';
import { IStepHandler, IUiSchema } from '../uischema/uischema.type';
import { ColorlibStepIconRoot, QontoConnector } from './stepper-layout/styles';
import { StyledCircleIcon } from './styled/circle-icon';
import { useAuthContext } from '../../views/login/auth/auth-context';

const materialCategorizationStepperTester: RankedTester = rankWith(
  3,
  and(uiTypeIs('Categorization'), categorizationHasCategory, optionIs('variant', 'stepper')),
);

interface MaterialCategorizationStepperLayoutRendererProps
  extends StatePropsOfLayout,
    AjvProps,
    TranslateProps {
  data: any;
}

const CategorizationStepperLayoutRenderer = (
  props: MaterialCategorizationStepperLayoutRendererProps,
) => {
  const { data, path, renderers, schema, visible, cells, config, ajv, t } = props;
  const [activeCategory, setActiveCategory] = useState<number>(0);
  const uischema = props?.uischema as IUiSchema;
  const ctx = useCrudContext();
  const { crudStates } = ctx;
  const navigate = useNavigate();
  const categorization = uischema as Categorization;
  const authContext = useAuthContext();

  const handleStep = (step: number) => {
    setActiveCategory(step);
  };

  const prevHandler = () => {
    handleStep(activeCategory - 1);
  };

  const categories = useMemo(
    () =>
      categorization?.elements?.filter?.((category: Category | Categorization) =>
        isVisible(category, data, '', ajv),
      ),
    [categorization, ajv],
  );

  const getRootSchema = () => {
    const category = categories?.[activeCategory];
    const rootSchema = uischema?.elements?.find?.(
      (element: any) => element.label === category.label,
    );
    return rootSchema;
  };

  const getStepHandler = (): IStepHandler => {
    const rootStepHandler = uischema?.options?.stepHandler;
    const rootSchema = getRootSchema();
    const categoryStepHandler = rootSchema?.options?.stepHandler;
    return (categoryStepHandler ?? rootStepHandler) as IStepHandler;
  };

  const getNextButtonTitle = (): string => {
    const rootSchema = getRootSchema();
    return rootSchema?.options?.nextButtonTitle || 'Finalizar';
  };

  const nextButtonDisabled = (): boolean => {
    const rootSchema = getRootSchema();
    const nextButtonDisabled = rootSchema?.options?.nextButtonDisabled;
    if (nextButtonDisabled && typeof nextButtonDisabled === 'function') {
      return nextButtonDisabled(data);
    }

    return false;
  };

  const isDisabled = crudStates.view || nextButtonDisabled();

  const nextHandler = async () => {
    const nextStep = activeCategory + 1;

    if (crudStates.view) {
      handleStep(nextStep);
      return;
    }

    const stepHandler: IStepHandler = getStepHandler();
    if (!stepHandler) return;
    const { handler, url, callback } = stepHandler;

    const rootSchema = getRootSchema();
    const uiSchemaConfigs = getUischemaConfigs(rootSchema, ctx);

    const stepHandlerResult = await handler?.(uiSchemaConfigs, ctx, data, url, callback);

    if (stepHandlerResult) {
      if (nextStep < categories.length) {
        handleStep(nextStep);
      } else {
        const rootSchema = getRootSchema();
        if (rootSchema?.options?.onFinish) {
          rootSchema?.options?.onFinish(navigate, ctx, authContext);
        } else {
          navigate(location.pathname.replace(/\/\d+$/, ''));
        }
      }
    }
  };

  const getCategoryIcon = (categoryLabel: string | undefined) => {
    const iconsConfig = uischema?.options?.icons;

    const icon = iconsConfig?.find?.((iconConfig: any) => iconConfig?.category === categoryLabel);
    return icon ? icon.icon : Footsteps; //Retorna icone padrão caso não haja nenhum definido no iconConfig
  };

  function ColorlibStepIcon(props: StepProps & { icon: React.ComponentType<any> }) {
    const { active, completed, className, icon: IconComponent } = props;

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        <IconComponent style={{ width: 28, height: 28, color: '#EBF0F4 ' }} />
      </ColorlibStepIconRoot>
    );
  }

  const appliedUiSchemaOptions = merge({}, config, uischema.options);
  const buttonWrapperStyle = {
    textAlign: 'right' as const,
    width: '100%',
    margin: '1em auto',
  };
  const buttonNextStyle = {
    float: 'right' as const,
  };
  const buttonStyle = {
    marginRight: '1em',
  };

  const childProps: MaterialLayoutRendererProps = {
    elements: categories?.[activeCategory]?.elements,
    schema,
    path,
    direction: 'column',
    visible,
    renderers,
    cells,
  };

  const tabLabels = useMemo(() => {
    return categories.map((e: Category | Categorization) => deriveLabelForUISchemaElement(e, t));
  }, [categories, t]);

  const StyledStepper = styled(Stepper)({
    position: 'fixed',
    backgroundColor: '#fafafa',
    zIndex: 10,
    paddingLeft: '10rem',
    paddingRight: '10rem',
  });

  const StyledStepLabel = styled(StepLabel)({
    '& .MuiStepLabel-label': {
      fontFamily: 'Raleway, sans-serif',
      fontWeight: 1000,
      fontSize: '16px',
      textTransform: 'none',
    },
  });

  return (
    <Hidden xsUp={!visible}>
      <StyledStepper activeStep={activeCategory} nonLinear connector={<QontoConnector />}>
        {categories.map((_: Category | Categorization, idx: number) => {
          if (idx !== activeCategory && categories?.length > 5)
            return <StyledCircleIcon key={idx}></StyledCircleIcon>;
          const categoryIconObj = getCategoryIcon(tabLabels[idx]);
          return (
            <Step key={tabLabels[idx]}>
              <Button>
                {/* onClick={() => handleStep(idx)} */}
                <StyledStepLabel
                  StepIconComponent={(props) => (
                    <ColorlibStepIcon {...props} icon={categoryIconObj} />
                  )}
                >
                  {tabLabels[idx]}
                </StyledStepLabel>
              </Button>
            </Step>
          );
        })}
      </StyledStepper>
      <div style={{ width: '100%', minHeight: '450px', marginTop: '6rem' }}>
        <MaterialLayoutRenderer key={getRootSchema().label} {...childProps} />
      </div>
      {appliedUiSchemaOptions.showNavButtons && (
        <div style={buttonWrapperStyle}>
          {activeCategory < categories.length - 1 ? (
            <Button
              style={buttonNextStyle}
              variant='contained'
              color='primary'
              disabled={activeCategory >= categories.length - 1}
              onClick={() => nextHandler()}
            >
              Próximo
            </Button>
          ) : (
            <Button
              style={buttonNextStyle}
              variant='contained'
              color='primary'
              disabled={isDisabled}
              onClick={() => nextHandler()}
            >
              {getNextButtonTitle()}
            </Button>
          )}
          <Button
            style={buttonStyle}
            color='secondary'
            variant='contained'
            disabled={activeCategory <= 0}
            onClick={() => prevHandler()}
          >
            Anterior
          </Button>
        </div>
      )}
    </Hidden>
  );
};

export const categorizationStepperLayoutRenderer = {
  tester: materialCategorizationStepperTester,
  renderer: withAjvProps(
    withTranslateProps(withJsonFormsLayoutProps(CategorizationStepperLayoutRenderer)),
  ),
};
