import AddIcon from '@mui/icons-material/Add';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { Dispatch, useCallback, useEffect, useState } from 'react';
import { GenericApi } from '../../api/generic-api';
import { moduleName } from '../../views/edital/convite-participantes/convite-participantes.headcell';
import { Secure } from '../auth/secure';
import { Usuario } from '../../models/usuario';

type CustomMultipleSelectUserChip = {
  apiSearchInstance: GenericApi<unknown>;
  apiListData: any[];
  onTextChange?: (searchText: string) => void;
  onCreateParticipants: () => void;
  handleAdd: (e: any) => void;
  usuariosState: {
    usuarios: Usuario[];
    setUsuarios: Dispatch<React.SetStateAction<Usuario[]>>;
  };
  selectedUsuariosState: {
    selectedUsuarios: Usuario[];
    setSelectedUsuarios: Dispatch<React.SetStateAction<Usuario[]>>;
  };
  enabled?: boolean;
  hasSecure?: boolean;
};

const CustomMultipleSelectUserChip = (props: CustomMultipleSelectUserChip) => {
  const { usuarios, setUsuarios } = props.usuariosState;
  const { selectedUsuarios, setSelectedUsuarios } = props.selectedUsuariosState;
  const [searchText, setSearchText] = useState('');
  const handleNameAvatar = (name: string | undefined) => {
    if (!name) return '';

    return name
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase())
      .slice(0, 2)
      .join('');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataFromApi = await props.apiSearchInstance.getAll({ nome: searchText, take: 10 });

        const dataFromApiFilteredByListData = dataFromApi.filter(
          (x: any) => !props.apiListData.some((usuario) => usuario.usuarioId === x.id),
        );
        setUsuarios(
          dataFromApiFilteredByListData.filter(
            (x: any) => !selectedUsuarios.some((usuario) => usuario.id === x.id),
          ),
        );
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [searchText, props.apiListData]);

  const handleChange = useCallback(
    debounce((event) => {
      setSearchText(event.target.value);
      props.onTextChange?.(event.target.value);
    }, 300),
    [],
  );

  return (
    <Stack direction='row' spacing={2} sx={{ flexGrow: 1, justifyContent: 'space-around' }}>
      <Autocomplete
        multiple
        id='tags-outlined'
        limitTags={1}
        defaultValue={[]}
        options={usuarios}
        disabled={!props.enabled}
        filterSelectedOptions
        getOptionLabel={(option) => option?.nome}
        value={selectedUsuarios}
        sx={{ maxWidth: 500, width: 500, marginLeft: 10 }}
        onChange={(e, value) => {
          setSelectedUsuarios(value);
        }}
        renderOption={(props, option) => {
          return (
            <Box
              {...props}
              sx={{
                borderRadius: '8px',
                margin: '5px',
                [`&.${autocompleteClasses.option}`]: {
                  padding: '8px',
                },
              }}
              component='li'
            >
              {option.fotoPerfil ? (
                <img
                  src={option.fotoPerfil}
                  style={{
                    borderRadius: '50%',
                    width: '45px',
                    height: '45px',
                    marginRight: '10pt',
                  }}
                />
              ) : (
                <Avatar
                  sx={{
                    width: '45px',
                    height: '45px',
                    fontSize: '13pt',
                    bgcolor: '#ffdddd',
                    color: '#5d0000',
                    marginRight: '10pt',
                  }}
                >
                  {handleNameAvatar(option.nome)}
                </Avatar>
              )}
              {option.nome}
            </Box>
          );
        }}
        renderTags={(value: any[], getTagProps) =>
          value.map((option: any, index: number) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Chip
                variant='outlined'
                label={option.nome}
                key={key}
                {...tagProps}
                avatar={
                  option.fotoPerfil ? (
                    <img
                      src={option.fotoPerfil}
                      style={{
                        borderRadius: '50%',
                        width: '25px',
                        height: '25px',
                        marginRight: '10pt',
                      }}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        width: '25px',
                        height: '25px',
                        fontSize: '13pt',
                        bgcolor: '#ffdddd',
                        color: '#5d0000',
                        marginRight: '10pt',
                      }}
                    >
                      {handleNameAvatar(option.nome)}
                    </Avatar>
                  )
                }
              />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label='Nome do Pesquisador'
            placeholder='Digite o nome do pesquisador'
            onChange={(e) => {
              handleChange(e);
            }}
          />
        )}
      />
      {props.hasSecure ? (
        <Secure hasPermission={'CRIAR'} moduleName={moduleName}>
          <Tooltip title='Adicionar' arrow>
            <Button
              className={isEmpty(selectedUsuarios) ? 'btn-disabled' : 'btn-add'}
              disabled={isEmpty(selectedUsuarios) || !props.enabled}
              onClick={(e) => {
                props.handleAdd(e);
              }}
            >
              <AddIcon></AddIcon>
              {'Adicionar'}
            </Button>
          </Tooltip>
        </Secure>
      ) : (
        <Tooltip title='Adicionar' arrow>
          <Button
            className={isEmpty(selectedUsuarios) ? 'btn-disabled' : 'btn-add'}
            disabled={isEmpty(selectedUsuarios) || !props.enabled}
            onClick={(e) => {
              props.handleAdd(e);
            }}
          >
            <AddIcon></AddIcon>
            {'Adicionar'}
          </Button>
        </Tooltip>
      )}
    </Stack>
  );
};

export default CustomMultipleSelectUserChip;
