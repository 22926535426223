import { Condition, Rule, RuleEffect } from '@jsonforms/core';
import Attachment from '@mui/icons-material/Attachment';
import { BagPlusFill } from '@styled-icons/bootstrap/BagPlusFill';
import { Calendar2Week } from '@styled-icons/bootstrap/Calendar2Week';
import { PeopleCommunityAdd } from '@styled-icons/fluentui-system-filled/PeopleCommunityAdd';
import { DocumentText } from '@styled-icons/ionicons-outline/DocumentText';
import { AddLocationAlt as LocationIcon } from '@styled-icons/material/AddLocationAlt';
import { NavigateFunction } from 'react-router-dom';
import { ReceiptMoney } from 'styled-icons/fluentui-system-regular';
import { Newspaper } from 'styled-icons/heroicons-outline';
import { FormApi, GenericApi } from '../../../api/generic-api';
import { circleFlagBuilder } from '../../../components/circle-flag';
import { CrudContextData } from '../../../components/crud/crud-context';
import { hideIfBrasil, IUiSchema, showIfBrasil } from '../../../jsonforms/uischema';
import { EditalRubrica } from '../../../models/edital-rubrica';
import { FaixaFinanciamentoEdital } from '../../../models/faixa-financiamento-edital';
import { Moeda } from '../../../models/moeda';
import { createResource } from '../../../utils/create-resource';
import { stepHandlerErrors } from '../../../utils/step-handler-errors';
import {
  AreaDeConhecimentoApi,
  makeAreaDeConhecimentoApi,
} from '../../fundacao/area-de-conhecimento/area-de-conhecimento-api';
import { stepHandlerProposta } from '../handlers/step-handler-proposta';
import { stepHandlerAbrangencia } from './handlers/step-handler-abrangencia';
import { IbgeApi, makeIbgeApi } from './ibge.api';
import { PlaneTakeOff } from '@styled-icons/boxicons-solid';
import { requiredIfBrasil, requiredIfNotBrasil } from '../../instituicao/instituicao.uischema';
import { TipoEditalRubrica } from '../../../models/edital-rubrica';
import { DocumentAttach } from '@styled-icons/ionicons-sharp/DocumentAttach';

const iconConfig = [
  {
    category: 'Dados da Proposta',
    icon: Newspaper,
  },
  {
    category: 'Membros',
    icon: PeopleCommunityAdd,
  },
  {
    category: 'Abrangência',
    icon: LocationIcon,
  },
  {
    category: 'Documentos Pessoais',
    icon: Attachment,
  },
  {
    category: 'Documentos da Proposta',
    icon: DocumentAttach,
  },
  {
    category: 'Orçamento',
    icon: ReceiptMoney,
  },
  {
    category: 'Diárias',
    icon: Calendar2Week,
  },
  {
    category: 'Material de Consumo',
    icon: BagPlusFill,
  },
  {
    category: 'Passagens',
    icon: PlaneTakeOff,
  },
  {
    category: 'Termo de Aceite',
    icon: DocumentText,
  },
];

const formatLabel = (item: any) => {
  const valorMinimoInReal = item.valorMinimo / 100;
  const valormaximoInReal = item.valorMaximo / 100;

  const formatCurrency = (value: number) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);

  const formattedValorMinimo = formatCurrency(valorMinimoInReal);
  const formattedValorMaximo = formatCurrency(valormaximoInReal);

  return `${item.nome} (${formattedValorMinimo} - ${formattedValorMaximo})`;
};

const disableTituloDoEdital = {
  effect: 'DISABLE',
  condition: {},
} as Rule;

const visibleIfHasFilter = (ctx: CrudContextData, apiListData: any[], queryFilterValues: any[]) => {
  const filterValue = queryFilterValues?.[0]?.value;
  if (filterValue !== null && filterValue !== undefined) return true;
  return false;
};

const disableIfNoOptions = (ctx: CrudContextData, apiListData: any[]) => {
  if (apiListData.length === 0) return true;
  return false;
};

const areaDeConhecimentoHandleChange = async (
  item: any,
  handler: (path: string, value: any) => void,
  formData: any,
  ctx?: CrudContextData,
  path?: string,
) => {
  const pathToSet = path?.split('.').slice(0, -1).join('.') || '';

  if (pathToSet && item?.id) handler(`${pathToSet}.id`, item?.id);
};

const showIfNationalTicket = {
  effect: 'SHOW',
  condition: {
    scope: '#/properties/trecho',
    schema: {
      const: 'nacional',
    },
  },
} as Rule;

const showIfInternationalTicket = {
  effect: 'SHOW',
  condition: {
    scope: '#/properties/trecho',
    schema: {
      const: 'internacional',
    },
  },
} as Rule;

const apiInstanceAreaDeConhecimento = makeAreaDeConhecimentoApi();

const apiInstanceIbge = makeIbgeApi();

const hideIfEntidadeAndTipoPessoa = {
  effect: 'SHOW',
  condition: {
    scope: '#/properties/contrapartida',
    schema: {
      const: true,
    },
  },
} as Rule;

const showIfForeignCurrency = {
  effect: 'SHOW',
  condition: {
    scope: '#/properties/moedaEstrangeira',
    schema: {
      const: true,
    },
  },
} as Rule;

const createShowRuleForEditalRubrica = (tipoEditalRubricaValue: TipoEditalRubrica): Rule => {
  return {
    effect: 'SHOW',
    condition: {
      scope: '#/properties/edital',
      schema: {
        properties: {
          editalRubrica: {
            type: 'array',
            contains: {
              properties: {
                tipoEditalRubrica: {
                  enum: [tipoEditalRubricaValue],
                },
              },
            },
          },
        },
      },
    },
  } as Rule;
};

const propostaUiSchema: IUiSchema = {
  type: 'Categorization',
  elements: [
    {
      type: 'Category',
      label: 'Dados da Proposta',
      options: {
        stepHandler: {
          handler: stepHandlerProposta,
          callback: (ctx: CrudContextData) => createResource(ctx, '/proposta'),
        },
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/tituloDoEdital',
          label: 'Título do Edital',
          rule: disableTituloDoEdital,
        },
        {
          type: 'Control',
          scope: '#/properties/tituloDoProjeto',
          label: 'Título do Projeto',
          options: {
            required: true,
          },
        },
        {
          type: 'Control',
          scope: '#/properties/duracao',
          label: 'Duração do projeto em meses',
          options: {
            isPositiveNumber: true,
          },
        },
        {
          type: 'Select',
          scope: '#/properties/instituicaoExecutoraId',
          label: 'Instituição',
        },
        {
          type: 'Select',
          scope: '#/properties/unidadeExecutoraId',
          label: 'Unidade',
          options: {
            filter: {
              formFields: ['instituicaoExecutoraId'],
              handler: (formData: any, listData: any[], api: FormApi) => {
                if (!formData.instituicaoExecutoraId) return Promise.resolve(listData);
                return api.getAll({ instituicaoId: formData?.instituicaoExecutoraId });
              },
            },
          },
        },
        {
          type: 'Control',
          scope: '#/properties/areaDeConhecimento',
          label: 'Suas Áreas de Conhecimento',
          options: {
            label: 'Área de Conhecimento',
            detail: {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Select',
                  scope: '#/properties/grandeAreaId',
                  label: 'Grande área',
                  options: {
                    apiInstance: apiInstanceAreaDeConhecimento,
                    handleChange: areaDeConhecimentoHandleChange,
                    displayProperties: ['grandeAreaNome'],
                    filter: {
                      handler: (formData: any, listData: any[], api: AreaDeConhecimentoApi) => {
                        return api.buscarGrandesAreas();
                      },
                    },
                    disableHandler: disableIfNoOptions,
                  },
                },
                {
                  type: 'Select',
                  scope: '#/properties/areaId',
                  label: 'Área',
                  options: {
                    apiInstance: apiInstanceAreaDeConhecimento,
                    handleChange: areaDeConhecimentoHandleChange,
                    displayProperties: ['areaNome'],
                    filter: {
                      formFields: ['grandeAreaId'],
                      handler: (
                        formData: any,
                        listData: any[],
                        api: AreaDeConhecimentoApi,
                        queryFilterValues: any,
                      ) => {
                        if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                        return api.buscarAreaPorGrandeAreaId(queryFilterValues?.[0].value);
                      },
                    },
                    disableHandler: disableIfNoOptions,
                    visibleHandler: visibleIfHasFilter,
                  },
                },
                {
                  type: 'Select',
                  scope: '#/properties/subAreaId',
                  label: 'Sub-área',
                  options: {
                    apiInstance: apiInstanceAreaDeConhecimento,
                    displayProperties: ['subAreaNome'],
                    handleChange: areaDeConhecimentoHandleChange,
                    filter: {
                      formFields: ['areaId'],
                      handler: (
                        formData: any,
                        listData: any[],
                        api: AreaDeConhecimentoApi,
                        queryFilterValues: any,
                      ) => {
                        if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                        return api.buscarSubAreaPorAreaId(queryFilterValues?.[0].value);
                      },
                    },
                    disableHandler: disableIfNoOptions,
                    visibleHandler: visibleIfHasFilter,
                  },
                },
                {
                  type: 'Select',
                  scope: '#/properties/especialidadeId',
                  label: 'Especialidade',
                  options: {
                    apiInstance: apiInstanceAreaDeConhecimento,
                    displayProperties: ['especialidadeNome'],
                    handleChange: areaDeConhecimentoHandleChange,
                    filter: {
                      formFields: ['subAreaId'],
                      handler: (
                        formData: any,
                        listData: any[],
                        api: AreaDeConhecimentoApi,
                        queryFilterValues: any,
                      ) => {
                        if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                        return api.buscarEspecialidadePorSubAreaId(queryFilterValues?.[0].value);
                      },
                    },
                    disableHandler: disableIfNoOptions,
                    visibleHandler: visibleIfHasFilter,
                  },
                },
              ],
            },
          },
        },
      ],
    },
    {
      type: 'Category',
      label: 'Membros',
      options: {
        stepHandler: {
          handler: stepHandlerErrors,
        },
      },
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'CustomMultipleUserSelect',
              scope: '#/properties/propostaConviteMembros',
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Abrangência',
      options: {
        stepHandler: {
          handler: stepHandlerAbrangencia,
        },
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/abrangencia',
          label: 'Abrangência',
          options: {
            itemLayout: 'SimpleItemLayout',
            detail: {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/estadoId',
                      label: 'Estado',
                      options: {
                        displayProperties: ['nome'],
                        filter: {
                          handler(formData, listData, api, queryFilterValues, path) {
                            const editalId = formData?.editalId;
                            if (!editalId) return Promise.resolve([]);

                            const index = Number(path?.split?.('.')?.at?.(-2));
                            if (Number.isNaN(index)) return Promise.resolve([]);

                            const { abrangencia } = formData;

                            return api.getAll({ editalId: editalId }).then((response: any) => {
                              return response?.filter((x: any) => {
                                if (!abrangencia) return true;
                                return abrangencia
                                  ?.filter((_: any, i: number) => i !== index)
                                  ?.every((y: any) => y.estadoId !== x.id);
                              });
                            });
                          },
                        },
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/abrangenciaMunicipio',
                      options: {
                        selectMultiple: true,
                        displayProperties: ['nome'],
                        apiInstance: apiInstanceIbge,
                        filter: {
                          formFields: ['estadoId'],
                          handler: (
                            formData: any,
                            listData: any[],
                            api: IbgeApi,
                            queryFilterValues: any,
                          ) => {
                            if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                            return api.buscarMunicipioPorEstadoId(queryFilterValues[0].value);
                          },
                        },
                        handleChange: async (values: any, handler, formData, ctx, path) => {
                          const mappedValues = values?.map((value: any) => {
                            return {
                              municipio: value?.data?.nome,
                            };
                          });
                          handler(`${path}`, mappedValues);
                        },
                        optionsComparator: (option: any, item: any) =>
                          option?.data?.nome === item?.municipio,
                        visibleHandler: visibleIfHasFilter,
                      },
                    },
                  ],
                },
              ],
            },
          },
        },
      ],
    },
    {
      type: 'Category',
      label: 'Documentos Pessoais',
      options: {
        stepHandler: {
          handler: stepHandlerErrors,
        },
      },
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              label: 'Documentos Pessoais',
              type: 'File',
              options: {
                categoriesRoute: 'proposta/documento-pessoal-proposta-anexo/categories',
                route: 'usuario/usuario-anexo',
                fileApiOptions: (formData: any) => {
                  return {
                    editalId: formData?.editalId,
                  };
                },
                withoutFk: true,
                filterByCategories: true,
              },
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Documentos da Proposta',
      options: {
        stepHandler: {
          handler: stepHandlerErrors,
        },
      },
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              label: 'Documentos da Proposta',
              type: 'File',
              scope: '#/properties/documentoPropostaAnexo',
              options: {
                fileApiOptions: (formData: any) => {
                  return {
                    editalId: formData?.editalId,
                  };
                },
              },
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Orçamento',
      options: {
        stepHandler: {
          handler: stepHandlerErrors,
        },
      },
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Select',
              label: 'Faixa de Financiamento',
              scope: '#/properties/faixaFinanciamentoEditalId',
              options: {
                required: true,
                displayProperties: ['nome', 'valorMinimo', 'valorMaximo'],
                filter: {
                  handler: (
                    formData: FaixaFinanciamentoEdital,
                    listData: FaixaFinanciamentoEdital[],
                    api: GenericApi<FaixaFinanciamentoEdital>,
                  ) => {
                    if (!formData.editalId) return Promise.resolve(listData);
                    return api.getAll({
                      editalId: formData?.editalId,
                    });
                  },
                },
                formatOptionLabel: formatLabel,
              },
            },
          ],
        },
      ],
      rule: {
        effect: RuleEffect.SHOW,
        condition: {
          scope: '#/properties/edital/properties/faixaFinanciamento',
          schema: {
            type: 'array',
            minItems: 1,
          },
        } as Condition,
      } as Rule,
    },
    {
      type: 'Category',
      label: 'Diárias',
      rule: createShowRuleForEditalRubrica(TipoEditalRubrica.diarias),
      options: {
        stepHandler: {
          handler: stepHandlerErrors,
        },
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/diariaProposta',
          label: 'Diária',
          options: {
            label: 'Diária',
            defaultValue: (FormData: any) => ({ paisId: 1 }),
            detail: {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/paisId',
                      label: 'País',
                      options: {
                        required: true,
                        iconBuilder: circleFlagBuilder,
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/estadoId',
                      label: 'Estado',
                      options: {
                        displayProperties: ['nome'],
                        required: requiredIfBrasil,
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/municipio',
                      label: 'Município',
                      options: {
                        required: requiredIfBrasil,
                        displayProperties: ['nome'],
                        apiInstance: apiInstanceIbge,
                        filter: {
                          formFields: ['estadoId'],
                          handler: (
                            formData: any,
                            listData: any[],
                            api: IbgeApi,
                            queryFilterValues: any,
                          ) => {
                            if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                            return api.buscarMunicipioPorEstadoId(queryFilterValues[0].value);
                          },
                        },
                      },
                    },
                  ],
                  rule: showIfBrasil,
                },
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/paisId',
                      label: 'País',
                      options: {
                        required: true,
                        iconBuilder: circleFlagBuilder,
                      },
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/estadoRegiao',
                      label: 'Estado/Região',
                      options: {
                        required: requiredIfNotBrasil,
                      },
                    },
                  ],
                  rule: hideIfBrasil,
                },
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Number',
                      scope: '#/properties/numeroDiaria',
                      label: 'Número de Diárias',
                      options: {
                        required: true,
                        numberStep: 0.5,
                        isPositiveNumber: true,
                        handleChange: (
                          numeroDiaria: number,
                          handler,
                          formData: any,
                          ctx,
                          path: String,
                        ) => {
                          let ticketIndex = Number(path?.split?.('.')?.at?.(-2));
                          let custoUnitario =
                            formData?.diariaProposta?.[ticketIndex]?.custoUnitario;

                          if (!numeroDiaria && !custoUnitario) return;

                          handler(
                            `${path}`.replace('numeroDiaria', 'valorTotal'),
                            custoUnitario * numeroDiaria,
                          );
                        },
                      },
                    },
                    {
                      type: 'MonetaryInput',
                      scope: '#/properties/custoUnitario',
                      label: 'Custo Unitário',
                      options: {
                        required: true,
                        isPositiveNumber: true,
                        handleChange: (
                          custoUnitario: number,
                          handler,
                          formData: any,
                          ctx,
                          path: String,
                        ) => {
                          let ticketIndex = Number(path?.split?.('.')?.at?.(-2));
                          let numeroDiaria = formData?.diariaProposta?.[ticketIndex]?.numeroDiaria;
                          if (!custoUnitario && !numeroDiaria) return;

                          handler(
                            `${path}`.replace('custoUnitario', 'valorTotal'),
                            custoUnitario * numeroDiaria,
                          );
                        },
                      },
                    },
                    {
                      type: 'MonetaryInput',
                      scope: '#/properties/valorTotal',
                      label: 'Valor Total',
                      options: {
                        disabled: true,
                      },
                    },
                  ],
                },
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/mesPrevisto',
                      label: 'Mês Previsto',
                      options: {
                        required: true,
                        oneOf: (formData: any) => {
                          return Array.from({ length: formData?.duracao }, (_, i) => ({
                            title: `${i + 1}°`,
                            const: i + 1,
                          }));
                        },
                      },
                    },
                    {
                      type: 'Boolean',
                      scope: '#/properties/moedaEstrangeira',
                      label: 'Moeda Estrangeira',
                      options: {
                        visibleHandler: (ctx: CrudContextData) => {
                          const { formData } = ctx;

                          return formData?.edital?.editalRubrica?.some(
                            (editalRubrica: EditalRubrica) =>
                              editalRubrica?.tipoEditalRubrica === TipoEditalRubrica.diarias &&
                              editalRubrica?.editalRubricaMoeda?.length > 0,
                          );
                        },
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/moedaId',
                      label: 'Moeda',
                      rule: showIfForeignCurrency,
                      options: {
                        displayProperties: ['nome'],
                        filter: {
                          handler: (
                            formData: any,
                            listData: any[],
                            api: GenericApi<Moeda>,
                            queryFilterValues: any,
                            path: string,
                          ): Promise<Moeda> => {
                            let editalRubrica = formData?.edital?.editalRubrica?.find(
                              (editalRubrica: EditalRubrica) =>
                                editalRubrica?.tipoEditalRubrica === TipoEditalRubrica?.diarias,
                            );
                            return api
                              .getAll()
                              .then((response) =>
                                response.filter(
                                  (moeda: Moeda) =>
                                    editalRubrica?.editalRubricaMoeda?.find(
                                      (editalRubricaMoeda) =>
                                        editalRubricaMoeda?.moedaId === moeda?.id,
                                    ),
                                ),
                              );
                          },
                        },
                      },
                    },
                  ],
                },
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Control',
                      scope: '#/properties/contrapartida',
                      options: {
                        defaultValue: false,
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/tipoPessoa',
                      label: 'Tipo',
                      rule: hideIfEntidadeAndTipoPessoa,
                    },
                  ],
                },
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Control',
                      scope: '#/properties/entidade',
                      label: 'Entidade',
                      rule: hideIfEntidadeAndTipoPessoa,
                      options: {
                        required: true,
                      },
                    },
                  ],
                },
              ],
            },
          },
        },
      ],
    },
    {
      type: 'Category',
      label: 'Material de Consumo',
      rule: createShowRuleForEditalRubrica(TipoEditalRubrica.materialDeConsumo),
      options: {
        stepHandler: {
          handler: stepHandlerErrors,
        },
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/materialConsumoProposta',
          label: 'Material de Consumo',
          options: {
            label: 'Material de Consumo',
            detail: {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Control',
                      scope: '#/properties/especificacao',
                      label: 'Especificação',
                      options: {
                        required: true,
                      },
                    },
                  ],
                },
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Number',
                      scope: '#/properties/quantidade',
                      label: 'Quantidade',
                      options: {
                        required: true,
                        isPositiveNumber: true,
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/unidadeMedida',
                      label: 'Unidade de Medida',
                      options: {
                        required: true,
                      },
                    },
                    {
                      type: 'MonetaryInput',
                      scope: '#/properties/custoUnitario',
                      label: 'Custo Unitário',
                      options: {
                        required: true,
                        isPositiveNumber: true,
                      },
                    },
                  ],
                },
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/mesPrevisto',
                      label: 'Mês Previsto',
                      options: {
                        required: true,
                        oneOf: (formData: any) => {
                          return Array.from({ length: formData?.duracao }, (_, i) => ({
                            title: `${i + 1}°`,
                            const: i + 1,
                          }));
                        },
                      },
                    },
                    {
                      type: 'Boolean',
                      scope: '#/properties/moedaEstrangeira',
                      label: 'Moeda Estrangeira',
                      options: {
                        visibleHandler: (ctx: CrudContextData) => {
                          const { formData } = ctx;

                          return formData?.edital?.editalRubrica?.some(
                            (editalRubrica: EditalRubrica) =>
                              editalRubrica?.tipoEditalRubrica ===
                                TipoEditalRubrica.materialDeConsumo &&
                              editalRubrica?.editalRubricaMoeda?.length > 0,
                          );
                        },
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/moedaId',
                      label: 'Moeda',
                      rule: showIfForeignCurrency,
                      options: {
                        displayProperties: ['nome'],
                        filter: {
                          handler: (
                            formData: any,
                            listData: any[],
                            api: GenericApi<Moeda>,
                            queryFilterValues: any,
                            path: string,
                          ): Promise<Moeda> => {
                            let editalRubrica = formData?.edital?.editalRubrica?.find(
                              (editalRubrica: EditalRubrica) =>
                                editalRubrica?.tipoEditalRubrica ===
                                TipoEditalRubrica?.materialDeConsumo,
                            );

                            return api
                              .getAll()
                              .then((response) =>
                                response.filter(
                                  (moeda: Moeda) =>
                                    editalRubrica?.editalRubricaMoeda?.find(
                                      (editalRubricaMoeda) =>
                                        editalRubricaMoeda?.moedaId === moeda?.id,
                                    ),
                                ),
                              );
                          },
                        },
                      },
                    },
                  ],
                },
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Control',
                      scope: '#/properties/contrapartida',
                      options: {
                        defaultValue: false,
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/tipoPessoa',
                      label: 'Tipo',
                      rule: hideIfEntidadeAndTipoPessoa,
                    },
                  ],
                },
                {
                  type: 'Control',
                  scope: '#/properties/entidade',
                  label: 'Entidade',
                  rule: hideIfEntidadeAndTipoPessoa,
                  options: {
                    required: true,
                  },
                },
              ],
            },
          },
        },
      ],
    },
    {
      type: 'Category',
      label: 'Passagens',
      rule: createShowRuleForEditalRubrica(TipoEditalRubrica.passagens),
      options: {
        stepHandler: {
          handler: stepHandlerErrors,
        },
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/passagemProposta',
          label: 'Passagens',
          options: {
            itemLayout: 'SimpleItemLayout',
            detail: {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Select',
                      label: 'Trecho',
                      scope: '#/properties/trecho',
                      options: {
                        required: true,
                      },
                    },
                    {
                      type: 'Control',
                      label: 'Ida e Volta',
                      scope: '#/properties/idaVolta',
                    },
                  ],
                },
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Select',
                      label: 'Estado de Origem',
                      scope: '#/properties/estadoOrigemId',
                      options: {
                        displayProperties: ['nome'],
                        required: true,
                      },
                    },
                    {
                      type: 'Select',
                      label: 'Município de Origem',
                      scope: '#/properties/municipioOrigem',
                      options: {
                        defaultValue: 'municipioOrigem',
                        required: true,
                        displayProperties: ['nome'],
                        apiInstance: apiInstanceIbge,
                        filter: {
                          formFields: ['estadoOrigemId'],
                          handler: (
                            formData: any,
                            listData: any[],
                            api: IbgeApi,
                            queryFilterValues: any,
                          ) => {
                            if (!queryFilterValues?.[0].value) return Promise.resolve([]);

                            return api.buscarMunicipioPorEstadoId(queryFilterValues[0].value);
                          },
                        },
                      },
                    },
                  ],
                },
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Select',
                      label: 'Estado de Destino',
                      scope: '#/properties/estadoDestinoId',
                      rule: showIfNationalTicket,
                      options: {
                        displayProperties: ['nome'],
                        required: true,
                      },
                    },
                    {
                      type: 'Select',
                      label: 'Município de Destino',
                      scope: '#/properties/municipioDestino',
                      rule: showIfNationalTicket,
                      options: {
                        defaultValue: 'municipioOrigem',
                        displayProperties: ['nome'],
                        required: true,
                        apiInstance: apiInstanceIbge,
                        filter: {
                          formFields: ['estadoDestinoId'],
                          handler: (
                            formData: any,
                            listData: any[],
                            api: IbgeApi,
                            queryFilterValues: any,
                          ) => {
                            if (!queryFilterValues?.[0].value) return Promise.resolve([]);

                            return api.buscarMunicipioPorEstadoId(queryFilterValues[0].value);
                          },
                        },
                      },
                    },
                  ],
                },
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Select',
                      label: 'País de Destino',
                      scope: '#/properties/paisDestinoId',
                      rule: showIfInternationalTicket,
                      options: {
                        displayProperties: ['nomePt'],
                        required: true,
                      },
                    },
                    {
                      type: 'Control',
                      label: 'Estado/Região de Destino',
                      rule: showIfInternationalTicket,
                      scope: '#/properties/estadoRegiaoDestino',
                      options: {
                        required: true,
                      },
                    },
                  ],
                },
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Control',
                      label: 'Tipo',
                      scope: '#/properties/tipo',
                      options: {
                        required: true,
                      },
                    },
                    {
                      type: 'MonetaryInput',
                      label: 'Custo Unitário',
                      scope: '#/properties/custoUnitario',
                      options: {
                        required: true,
                        handleChange: (
                          custoUnitario: number,
                          handler,
                          formData: any,
                          ctx,
                          path: string,
                        ) => {
                          let ticketIndex = Number(path?.split?.('.')?.at?.(-2));
                          let quantidade = formData?.passagemProposta?.[ticketIndex]?.quantidade;

                          if (!custoUnitario && !quantidade) return;

                          handler(
                            `${path}`.replace('custoUnitario', 'valorTotal'),
                            custoUnitario * quantidade,
                          );
                        },
                      },
                    },
                    {
                      type: 'Control',
                      label: 'Quantidade',
                      scope: '#/properties/quantidade',
                      options: {
                        required: true,
                        isPositiveNumber: true,
                        handleChange: (
                          quantidade: number,
                          handler,
                          formData: any,
                          ctx,
                          path: string,
                        ) => {
                          let ticketIndex = Number(path?.split?.('.')?.at?.(-2));
                          let custoUnitario =
                            formData?.passagemProposta?.[ticketIndex]?.custoUnitario;

                          if (!custoUnitario && !quantidade) return;

                          handler(
                            `${path}`.replace('quantidade', 'valorTotal'),
                            custoUnitario * quantidade,
                          );
                        },
                      },
                    },
                  ],
                },
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'MonetaryInput',
                      label: 'Valor Total',
                      scope: '#/properties/valorTotal',
                      options: {
                        disabled: true,
                      },
                    },
                    {
                      type: 'Select',
                      label: 'Mês Previsto',
                      scope: '#/properties/mesPrevisto',
                      options: {
                        required: true,
                        oneOf: (formData: any) => {
                          return Array.from({ length: formData?.duracao }, (_, i) => ({
                            title: `${i + 1}°`,
                            const: i + 1,
                          }));
                        },
                      },
                    },
                  ],
                },
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Boolean',
                      scope: '#/properties/moedaEstrangeira',
                      label: 'Moeda Estrangeira',
                      options: {
                        visibleHandler: (ctx: CrudContextData) => {
                          const { formData } = ctx;

                          return formData?.edital?.editalRubrica?.some(
                            (editalRubrica: EditalRubrica) =>
                              editalRubrica?.tipoEditalRubrica === TipoEditalRubrica.passagens &&
                              editalRubrica?.editalRubricaMoeda?.length > 0,
                          );
                        },
                      },
                    },
                    {
                      type: 'Select',
                      label: 'Moeda',
                      scope: '#/properties/moedaId',
                      options: {
                        displayProperties: ['nome'],
                        required: true,
                        filter: {
                          handler: (
                            formData: any,
                            listData: any[],
                            api: GenericApi<Moeda>,
                            queryFilterValues: any,
                            path: string,
                          ): Promise<Moeda> => {
                            let editalRubrica = formData?.edital?.editalRubrica?.find(
                              (editalRubrica: EditalRubrica) =>
                                editalRubrica.tipoEditalRubrica === TipoEditalRubrica.passagens,
                            );

                            return api
                              .getAll()
                              .then((response) =>
                                response.filter(
                                  (moeda: Moeda) =>
                                    editalRubrica?.editalRubricaMoeda?.find(
                                      (editalRubricaMoeda) =>
                                        editalRubricaMoeda?.moedaId === moeda?.id,
                                    ),
                                ),
                              );
                          },
                        },
                      },
                      rule: showIfForeignCurrency,
                    },
                  ],
                },
              ],
            },
          },
        },
      ],
    },
    {
      type: 'Category',
      label: 'Termo de Aceite',
      options: {
        stepHandler: {
          handler: stepHandlerAbrangencia,
        },
        nextButtonTitle: 'Submeter Proposta',
        nextButtonDisabled: (data: any) => {
          return !data?.termoDeAceiteAceito;
        },
        onFinish: (navigate: NavigateFunction, ctx: CrudContextData) => {
          navigate('/');
        },
      },
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'CkEditor',
              scope: '#/properties/edital/properties/termoDeAceite',
              options: {
                ckeditorSize: { height: 400 },
                toolbar: [],
                disabled: true,
              },
            },
          ],
        },
        {
          type: 'Control',
          scope: '#/properties/termoDeAceiteAceito',
          label: 'Li e estou de acordo com o Termo de Aceite',
        },
      ],
    },
  ],
  options: {
    variant: 'stepper',
    showNavButtons: true,
    icons: iconConfig,
  },
};

export default propostaUiSchema;
