import { Proposta } from './proposta';
import { Usuario } from './usuario';

export enum TipoSituacao {
  pendente = 'PENDENTE',
  aceito = 'ACEITO',
  recusado = 'RECUSADO',
  removido = 'REMOVIDO',
}

export type TipoSituacaoI18N = {
  [key in TipoSituacao]: string;
};

export const tipoSituacaoI18N: TipoSituacaoI18N = {
  PENDENTE: 'Pendente',
  ACEITO: 'Aceito',
  RECUSADO: 'Recusado',
  REMOVIDO: 'Removido',
};
export interface PropostaConviteMembro {
  id: number;
  propostaId: number;
  proposta: Proposta;
  usuarioId: number;
  usuario: Usuario;
  situacao: TipoSituacao;
  createdAt: Date;
  dataAceite: Date;
}
