import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { TipoInstrumentoJuridico } from '../../../models/tipo-instrumento-juridico';

export interface Data extends Record<string, any> {
  id: number;
  nome: string;
}

export const moduleName = 'CONFIGURACOES_INICIAIS.TIPO_INSTRUMENTO_JURIDICO';

export const displayProperties = ['nome'];

export const moduleLabel = 'Tipo do Instrumento Jurídico';

export const rowsLength = 7;

export const headCells: readonly HeadCell<TipoInstrumentoJuridico>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'descricao',
    numeric: false,
    disablePadding: false,
    label: 'Descrição',
  },
];
