import styled from '@emotion/styled';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { Diversity3 } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';

export const ComponentBox = styled(Box)({
  width: '100%',
  maxWidth: '1089px',
  fontFamily: 'Raleway',
  paddingLeft: '16px',
  paddingRight: '16px',
});

export const Header = styled('div')({
  marginLeft: '20px',
});

export const WidthFull = styled(List)({
  width: '100%',
});

export const ConvitesBox = styled(Box)({
  width: '100%',
  fontFamily: 'Raleway',
  borderRadius: '4px',
  border: 'solid 1px #C5C6D0',
  marginBottom: '40px',
});

export const StyledAccordion = styled(Accordion)({
  margin: '0px !important',
  backgroundColor: 'transparent',
});

export const StyledAccordionSummary = styled(AccordionSummary)({
  backgroundColor: 'transparent',
  fontSize: '12px',
  fontWeight: 600,
  marginLeft: '4px',
  marginRight: '16px',
  fontFamily: 'Raleway',
  color: '#333333 !important',
  padding: '0px !important',
  maxHeight: '41px !important',
  minHeight: '41px !important',
});

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)({
  color: '#1351B4 !important',
});

export const StyledDiversity3 = styled(Diversity3)({
  marginLeft: '16px',
  marginRight: '4px',
  width: '16px',
  height: '16px',
  color: '#1351B4',
});

export const QuantidadeConvitesLabel = styled(Typography)({
  fontSize: '10px',
  fontWeight: 700,
  marginLeft: '8px',
  fontFamily: 'Raleway',
  color: '#003D92 !important',
  lineHeight: '10px',
  letterSpacing: '0.5px',
  display: 'inline-flex',
  alignItems: 'center',
});

export const StyledAccordionDetails = styled(AccordionDetails)({
  padding: '0px !important',
  borderTop: 'solid 1px #C5C6D0',
});
