import { CrudContextData } from '../../../components/crud/crud-context';
import { IStepCallback } from '../../../jsonforms/uischema';

export const stepHandlerAbrangencia = async (
  fieldsConfig: { paths: string[]; instancePaths: string[]; requiredScopes: string[] },
  ctx: CrudContextData,
  data: any,
  url: string,
  callback?: IStepCallback,
): Promise<boolean | void> => {
  const { formData, showError } = ctx;

  const editalEstado = formData?.editalEstado;

  if (editalEstado?.length > 0) {
    if (callback) await callback(ctx);
    return true;
  }

  showError('Informe pelo menos um estado.');
  return false;
};
