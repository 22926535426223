import { PerguntaDescritiva } from './pergunta-descritiva';
import { PerguntaMultiplaEscolha } from './pergunta-multipla-escolha';

export enum TipoPergunta {
  informacoesComplementares = 'INFORMACOES_COMPLEMENTARES',
  descricaoProjeto = 'DESCRICAO_PROJETO',
}
export enum TipoResposta {
  descritiva = 'DESCRITIVA',
  data = 'DATA',
  multiplaEscolha = 'MULTIPLA_ESCOLHA',
  multiplaSelecao = 'MULTIPLA_SELECAO',
}
export interface Pergunta {
  id: number;
  pergunta: string;
  tipoPergunta: TipoPergunta;
  tipoResposta: TipoResposta;
  obrigatoria: boolean;
  descritiva: PerguntaDescritiva;
  multiplaEscolha: PerguntaMultiplaEscolha[];
  posicao: number;
  deletedAt: Date;
}
