import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import { ReactNode, useState } from 'react';
import { ChevronLeftCircle, ChevronRightCircle } from 'styled-icons/boxicons-regular';
import { CrudStatesOptions } from '../../../crud/crud-context';
import LogoFundacao from '../../logo-fundacao';
import { NavCategory } from '../nav-category';
import { navItems } from './items';
import {
  NavContainer,
  StyledDrawer,
  SidebarBox,
  SidebarBoxContent,
  SidebarToolbar,
  SidebarBoxContentNavIcon,
  NavIcon,
  SidebarBoxContentDiv,
} from './nav-drawer.styled';

const NavContainerWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box component='nav'>
      <NavContainer>{children}</NavContainer>
    </Box>
  );
};

export type Item = {
  route: string;
  icon: ReactNode;
  title: string;
  state?: CrudStatesOptions;
  hasPermission?: (moduleName: string, permissions: string[]) => boolean;
};

export interface NavDrawerProps {
  handleMainContent: (open: boolean) => void;
}

export function NavDrawer(props: NavDrawerProps) {
  const { handleMainContent } = props;
  const [open, setOpen] = useState(true);
  const [drawerWidth, setDrawerWidth] = useState(240);

  const toggleDrawer = () => {
    setDrawerWidth(!open ? 240 : 60);
    setOpen(!open);
    handleMainContent(!open);
  };

  return (
    <StyledDrawer
      variant='permanent'
      open={open}
      sx={{
        display: { xs: 'none', sm: 'block' },
        '& .MuiDrawer-paper': {
          width: drawerWidth,
        },
      }}
    >
      <SidebarBox>
        <SidebarToolbar>
          <div>
            {open ? (
              <LogoFundacao width={74.07} style={{ transition: 'width 0.4s ease-in-out' }} />
            ) : (
              <LogoFundacao width={50} style={{ transition: 'width 0.5s ease-in-out' }} />
            )}
          </div>
        </SidebarToolbar>

        <SidebarBoxContent onClick={toggleDrawer}>
          {open ? (
            <SidebarBoxContentDiv>
              <NavIcon>
                <ChevronLeftCircle size={20} />
              </NavIcon>
              <p>Recolher</p>
            </SidebarBoxContentDiv>
          ) : (
            <Tooltip arrow title='Expandir Menu' placement='right'>
              <SidebarBoxContentNavIcon
                sx={{
                  ':hover': { backgroundColor: '#d4e0e4' },
                }}
              >
                <ChevronRightCircle size={20} />
              </SidebarBoxContentNavIcon>
            </Tooltip>
          )}
        </SidebarBoxContent>
      </SidebarBox>

      <Box paddingRight={1} paddingLeft={1}>
        <Divider />
      </Box>

      <NavContainerWrapper>
        {Object.values(navItems).map((category, index) => (
          <Tooltip
            arrow
            key={index}
            title={category.title}
            placement='right'
            disableHoverListener={open}
          >
            <Box
              sx={{
                padding: '0px 5px',
                width: drawerWidth,
              }}
            >
              <NavCategory
                key={index}
                title={category.title}
                icon={category.icon}
                items={category.items}
                open={open}
                setOpen={setOpen}
                toggleDrawer={toggleDrawer}
              />
            </Box>
          </Tooltip>
        ))}
      </NavContainerWrapper>
      <Box sx={{ pb: '28px' }} />
    </StyledDrawer>
  );
}
