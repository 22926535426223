import styled from '@emotion/styled';
import Check from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Paper, Table, TableCell, TableRow } from '@mui/material';
import TableHead from '@mui/material/TableHead';

export const StyledPaper = styled(Paper)({
  marginBottom: '0.75rem',
  overflow: 'hidden',
});

export const StyledTable = styled(Table)({
  fontFamily: 'Raleway, sans-serif',
  '& button, & td, & th': {
    fontFamily: 'Raleway, sans-serif',
  },
});

export const TableLayoutHeader = styled(TableHead)({
  backgroundColor: '#1351B4',
});

export const TableLayoutHeaderCell = styled(TableCell)({
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  color: '#fff !important',
});

export const TableLayoutRow = styled(TableRow)({
  '&:last-child td, &:last-child th': {
    border: '1rem',
  },
});

export const TableLayoutCell = styled(TableCell)({
  whiteSpace: 'nowrap',
  wordBreak: 'break-word',
});

export const TableLayoutActionCell = styled(TableCell)({
  width: 0,
  whiteSpace: 'nowrap',
});

export const ActionButton = styled(Button)({
  textTransform: 'none',
  padding: 0,
});

export const AddButton = styled(Button)({
  color: '#fff',
  borderRadius: '4px',
  fontSize: '0.875rem',
  backgroundColor: '#003d92',
  padding: '0.1rem 0.5rem',

  '&:hover': {
    backgroundColor: '#002b66',
  },
});

export const ActionsContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
});

export const TableLayoutBody = styled(Box)({
  overflow: 'auto',
  maxHeight: '20rem',
});

export const StyledAddIcon = styled(AddIcon)({
  width: '1rem',
  height: '1rem',
});

export const StyledCheckIcon = styled(Check)({
  width: '1rem',
  height: '1rem',
});
