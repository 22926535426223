import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { getNestedValues } from '../../../helpers/get-nested-values';
import dayjs from '../../dayjs/sig-dayjs';
import { HeadCell } from '../protocols/head-cell';
import { Action, ActionsTableCell } from './actions-table-cell';
import { EnhancedTableHead } from './table-head';
import { EnhancedTableToolbar } from './table-toolbar';
import { useState } from 'react';
import {
  DataTableContainer,
  StyledPaper,
  StyledTableContainer,
  StyledTableBody,
  StyledTableRow,
  StyledTableCell,
  StyledTablePagination,
} from '../style/data-table.styled';
import { Theme } from '@mui/material/styles/createTheme';
import { SxProps } from '@mui/material/styles';

type DataTableProps<T> = {
  rows: T[];
  headCells: readonly HeadCell<T>[];
  checkboxes?: boolean;
  title?: string;
  viewHandler?: Function;
  editHandler?: Function;
  addHandler?: Function;
  deleteHandler?: Function;
  hideActions?: boolean;
  hideDelete?: boolean;
  hideUpdate?: boolean;
  hideCreate?: boolean;
  hideView?: boolean;
  onItemSelect?: Function;
  customActions?: ((row: T) => Action | undefined)[];
  restoreHandler?: Function;
  customTableHeader?: React.ReactNode;
  customTableFooter?: React.ReactNode;
  moduleName: string;
  ignorePermissions?: boolean;
  page: number;
  setPage: (page: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (rowsPerPage: number) => void;
  totalCount: number;
  rowsPerPageOptions?: number[];
  styledPaperSx?: SxProps<Theme>;
};

export default function DataTable<T extends Record<string, any>>(props: DataTableProps<T>) {
  const {
    rows,
    headCells,
    checkboxes,
    title,
    viewHandler,
    editHandler,
    addHandler,
    deleteHandler,
    hideActions,
    hideDelete,
    hideUpdate,
    hideCreate,
    hideView,
    onItemSelect,
    customActions,
    restoreHandler,
    customTableHeader,
    customTableFooter,
    moduleName,
    ignorePermissions = false,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalCount,
    rowsPerPageOptions,
    styledPaperSx,
  } = props;
  const [selected, setSelected] = useState<readonly string[]>([]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows?.map((n: any) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (
    event: React.MouseEvent<unknown>,
    name: string,
    id: number,
    row: any,
    checkboxes?: boolean,
    onItemSelect?: Function,
  ) => {
    if (checkboxes) {
      const selectedIndex = selected?.indexOf?.(name);
      let newSelected: readonly string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected?.concat?.(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected?.concat?.(selected?.slice?.(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected?.concat?.(selected?.slice?.(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected?.concat?.(
          selected?.slice?.(0, selectedIndex),
          selected?.slice?.(selectedIndex + 1),
        );
      }

      setSelected(newSelected);
    }
    if (onItemSelect) {
      onItemSelect(row);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  //Evita um salto de layout ao atingir o numero máximo de itens.
  const totalPages = Math.ceil(totalCount / rowsPerPage);
  const isLastPage = page === totalPages - 1;
  const emptyRows = isLastPage ? 0 : Math.max(0, rowsPerPage - rows.length);

  const getResourceValue = (row: any, cell: any) => {
    const resource = cell?.resource;
    let value = '';
    if (typeof resource?.target === 'function') {
      value = resource?.target?.(row);
    } else {
      value = row?.[resource?.name]?.[resource?.target];
    }
    if (resource?.name === 'pais') {
      const flag = row[resource?.name]?.sigla;
      if (flag) {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <img
              src={`https://hatscripts.github.io/circle-flags/flags/${flag.toLowerCase()}.svg`}
              width='25'
            />
            {value}
          </Box>
        );
      }
    }

    return value || '-';
  };

  const getCellContentValue = (row: any, cell: any) => {
    if (cell.resource) return getResourceValue(row, cell);
    if (cell.computedRowLabel) return cell.computedRowLabel(row);

    let crrValue = getNestedValues(row, cell.id);

    if (cell.dateFormat && crrValue) {
      if (cell.isDateTime) {
        crrValue = dayjs(crrValue, 'YYYY-MM-DD HH:mm:ss', 'UTC').format(cell.dateFormat);
      } else {
        crrValue = dayjs(crrValue, 'YYYY-MM-DD', 'UTC').format(cell.dateFormat);
      }
    }

    if (cell.mask) {
      if (cell.mask === 'money') {
        crrValue = parseInt(crrValue) / 100;
        if (Number.isNaN(crrValue)) crrValue = 0;
        crrValue =
          'R$ ' +
          crrValue.toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
      }
    }

    return crrValue || '-';
  };

  return (
    <DataTableContainer>
      <StyledPaper sxProps={styledPaperSx}>
        <EnhancedTableToolbar
          className='toolbar'
          numSelected={selected.length}
          title={title}
          moduleName={moduleName}
          addHandler={addHandler}
          customTableHeader={customTableHeader}
          hideCreate={hideCreate || hideActions}
          ignorePermissions={ignorePermissions}
        />
        <StyledTableContainer>
          <Table className='table' aria-labelledby='tableTitle' size='medium'>
            <EnhancedTableHead<T>
              numSelected={selected.length}
              headCells={headCells}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows?.length}
              checkboxes={checkboxes}
              hideActions={hideActions}
            />
            <StyledTableBody>
              {rows?.map((row: any, index: number) => {
                const isItemSelected = isSelected(String(row?.name));
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <StyledTableRow
                    isDeleted={Boolean(row.deletedAt)}
                    hover
                    onClick={(event) =>
                      handleClick(
                        event,
                        String(row?.name),
                        Number(row?.id),
                        row,
                        checkboxes,
                        onItemSelect,
                      )
                    }
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={`${row.name}-${row.id}`}
                    selected={isItemSelected}
                  >
                    {checkboxes && (
                      <TableCell padding='checkbox'>
                        <Checkbox
                          color='primary'
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                    )}

                    {headCells?.map((cell: any) => (
                      <StyledTableCell
                        key={String(cell.id) + row.id}
                        component='th'
                        id={String(row.id)}
                        scope='row'
                        align='center'
                      >
                        {getCellContentValue(row, cell)}
                      </StyledTableCell>
                    ))}

                    {!hideActions && (
                      <ActionsTableCell<T>
                        row={row}
                        hideView={hideView}
                        hideUpdate={hideUpdate}
                        hideDelete={hideDelete}
                        moduleName={moduleName}
                        viewHandler={viewHandler}
                        editHandler={editHandler}
                        deleteHandler={deleteHandler}
                        customActions={customActions}
                        restoreHandler={restoreHandler}
                        ignorePermissions={ignorePermissions}
                      />
                    )}
                  </StyledTableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </StyledTableBody>
          </Table>
        </StyledTableContainer>
        <StyledTablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage='Linhas por página'
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {customTableFooter && (
          <EnhancedTableToolbar
            hideCreate={true}
            numSelected={selected.length}
            customTableFooter={customTableFooter}
            moduleName={moduleName}
          />
        )}
      </StyledPaper>
    </DataTableContainer>
  );
}
