import styled from '@emotion/styled';
import { Calendar2RangeFill } from 'styled-icons/bootstrap';

export const Vigencia = styled('span')(({ colorValue }) => ({
  color: colorValue,
  marginLeft: '5px',
  fontSize: '16px',
  fontWeight: '500',
}));

export const VigenciaLabel = styled('span')({
  fontFamily: 'Raleway',
  fontSize: '16px',
  fontWeight: 600,
  color: '#434653',
});

export const CallendarRangeIcon = styled(Calendar2RangeFill)({
  marginRight: '10px',
});

export const ComponentContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'white',
});
