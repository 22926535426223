import { Crud } from '../../../components/crud/crud';
import { whiteSpaces } from '../../../helpers/custom-errors';
import { headCells } from './moeda.headcell';
import { Moeda as MoedaModel } from '../../../models/moeda';
import moedaUiSchema from './moeda.uischema';

function Moeda() {
  const customErrors = [whiteSpaces('nome'), whiteSpaces('simbolo'), whiteSpaces('sigla')];
  const defaultForm = { nome: '', sigla: '', simbolo: '' };

  return (
    <Crud<MoedaModel>
      headCells={headCells}
      titleConfig={{ value: 'Moedas', show: true }}
      apiUrl={'/moeda'}
      uischema={moedaUiSchema}
      customErrors={customErrors}
      defaultForm={defaultForm}
      hideView
      queryFilters={{ withDeleted: true }}
    ></Crud>
  );
}

export default Moeda;
