import { Money } from '@styled-icons/boxicons-regular/Money';
import { useNavigate } from 'react-router-dom';
import { Crud } from '../../../components/crud/crud';
import { useCrudContext } from '../../../components/crud/crud-context';
import { moduleNameFor } from '../../../helpers/module-name-for';
import { Data, headCells } from './tipo-recurso-linha-de-acao.headcell';
import edicaoRecursoLinhaDeAcaoUiSchema from './tipo-recurso-linha-de-acao.uischema';

function LinhaDeAcao() {
  const navigate = useNavigate();
  const { parameterFilters, setParameterFilters } = useCrudContext();

  const edicaoRecursoLinhaDeAcao = {
    hasPermission: 'EXIBIR_NO_MENU',
    moduleName: moduleNameFor('edicao-recurso-linha-de-acao'),
    icon: <Money size='24' color='#009900' />,
    title: 'Edição de Recurso da Linha de Ação',
    handler: (row: any) => {
      setParameterFilters({
        ...parameterFilters,
        linhaDeAcao: row,
      });
      navigate(`/edicaolinhaDeAcao/${row?.id}/edicao-recurso-linha-de-acao`);
    },
  };

  return (
    <Crud<Data>
      headCells={headCells}
      uischema={edicaoRecursoLinhaDeAcaoUiSchema}
      apiUrl={'/edicao-recurso-linha-de-acao'}
      queryFilters={{
        withDeleted: true,
        orderBy: 'dataInicio',
        orderDirection: 'ASC',
      }}
      customActions={[() => edicaoRecursoLinhaDeAcao]}
    ></Crud>
  );
}

export default LinhaDeAcao;
