import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import { useCrudContext } from '../crud/crud-context';
import { BreadcrumbsContext } from './breadcrumb-context';
import { BreadcrumbContent, HomeIcon, HomeLink } from './breadcrumb.styled';
import { useContext } from 'react';

export default function BreadcrumbsProvider() {
  const { breadcrumb, setBreadcrumb } = useContext(BreadcrumbsContext);
  const { setCrudStates, setCurrentTitle } = useCrudContext();

  const getClassNames = (index: number, array: any[]) => {
    const isLast = index === array.length - 1;
    return isLast;
  };

  const breadcrumbs = [
    <Link to={'/'} key={'/'}>
      <HomeLink
        onClick={() => {
          setCurrentTitle('');
          setBreadcrumb([]);
        }}
      >
        <HomeIcon size={26} />
      </HomeLink>
    </Link>,
    breadcrumb?.map((item, index, array) => {
      return (
        <Link
          to={item.pathname}
          key={item.pathname}
          onClick={() => (item.defaultState ? setCrudStates(item.defaultState) : {})}
        >
          <BreadcrumbContent isLast={getClassNames(index, array)}>{item.name}</BreadcrumbContent>
        </Link>
      );
    }),
  ];

  return (
    <Stack spacing={1} sx={{ margin: 1 }}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize='small' />}
        aria-label='breadcrumb'
        sx={{
          '& .MuiBreadcrumbs-separator': {
            width: '20px',
            margin: '0px',
          },
        }}
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}
