import { Crud } from '../../../components/crud/crud';
import { TipoInstrumentoJuridico } from '../../../models/tipo-instrumento-juridico';
import { headCells } from './tipo-instrumento-juridico.headcell';
import tipoInstrumentoJuridicoUiSchema from './tipo-instrumento-juridico.uischema';

function TipoInstrumentojuridico() {
  const defaultForm = { nome: '' };

  return (
    <Crud<TipoInstrumentoJuridico>
      headCells={headCells}
      titleConfig={{ value: 'Tipos de Instrumento Jurídico', show: true }}
      apiUrl={'/tipo-instrumento-juridico'}
      uischema={tipoInstrumentoJuridicoUiSchema}
      defaultForm={defaultForm}
      queryFilters={{ withDeleted: true }}
      hideView
    ></Crud>
  );
}

export default TipoInstrumentojuridico;
