import { Money } from '@styled-icons/boxicons-regular/Money';
import { useNavigate, useParams } from 'react-router-dom';
import { Crud } from '../../../components/crud/crud';
import { useCrudContext } from '../../../components/crud/crud-context';
import { positiveNumberOrEmpty } from '../../../helpers/custom-errors';
import { moduleNameFor } from '../../../helpers/module-name-for';
import { InstituicaoInstrumentoJuridico as InstituicaoInstrumentoJuridicoModel } from '../../../models/instituicao-instrumento-juridico';
import { headCells } from './instituicao-instrumento-juridico.headcell';
import instituicaoInstrumentoJuridicoUiSchema from './instituicao-instrumento-juridico.uischema';

function InstituicaoInstrumentoJuridico() {
  const navigate = useNavigate();
  const { parameterFilters, setParameterFilters } = useCrudContext();
  const customErrors = [positiveNumberOrEmpty('valorFirmado')];

  const { instrumentoJuridicoId } = useParams();
  const instrumentoJuridicoIdNumber = Number(instrumentoJuridicoId);

  const defaultForm = { instrumentoJuridicoId: instrumentoJuridicoIdNumber };

  const cronogramaDesembolsoAction = {
    hasPermission: ['EXIBIR_NO_MENU', 'BUSCAR_TODOS'],
    icon: <Money size='24' color='#ff9900' />,
    title: 'Cronograma de Desembolso',
    moduleName: moduleNameFor('cronograma-desembolso'),
    handler: (row: any) => {
      setParameterFilters({
        ...parameterFilters,
        instituicaoInstrumentoJuridico: row,
      });
      navigate(
        `/instrumento-juridico/${instrumentoJuridicoId}/instituicao-instrumento-juridico/${row.id}/cronograma-desembolso`,
      );
    },
  };

  return (
    <Crud<InstituicaoInstrumentoJuridicoModel>
      headCells={headCells}
      apiUrl={'/instituicao-instrumento-juridico'}
      titleConfig={{ value: 'Instituição do Instrumento Jurídico', show: true }}
      uischema={instituicaoInstrumentoJuridicoUiSchema}
      customErrors={customErrors}
      defaultForm={defaultForm}
      queryFilters={{
        orderBy: 'nome',
        orderDirection: 'ASC',
        instrumentoJuridicoId: instrumentoJuridicoIdNumber,
      }}
      hideDelete
      hideUpdate
      customActions={[() => cronogramaDesembolsoAction]}
    ></Crud>
  );
}

export default InstituicaoInstrumentoJuridico;

export const rootViews = ['instrumento-juridico'];
