import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import { CheckSquare } from '@styled-icons/boxicons-solid/CheckSquare';
import { Blocked } from 'styled-icons/icomoon';
import { Secure } from '../../../components/auth/secure';
import { AreaDeConhecimento } from '../../../models/area-de-conhecimento';
import { moduleName } from './area-de-conhecimento.headcell';

interface AreaDeConhecimentoActionsCellProps {
  row: AreaDeConhecimento;
  restoreHandler?: Function;
  editHandler?: Function;
  addHandler?: Function;
  openDeleteHandler?: Function;
  restoreAndUpdateHandler?: Function;
  sx?: TableCellProps['sx'];
}

export function AreaDeConhecimentoActionsCell(props: AreaDeConhecimentoActionsCellProps) {
  const { row, editHandler, openDeleteHandler, sx, addHandler, restoreAndUpdateHandler } = props;

  const addSubArea = row?.numeroNivel === 4;

  return (
    <TableCell className='actions' sx={{ display: 'flex', padding: 0, height: '48px', ...sx }}>
      {row.deletedAt && (
        <Tooltip title='Ativar' arrow>
          <Secure hasPermission={'RESTAURAR'} moduleName={moduleName}>
            <IconButton
              onClick={() => {
                restoreAndUpdateHandler?.(row);
              }}
              sx={{ margin: '0 auto' }}
            >
              <CheckSquare size='24' className='icon-restore'></CheckSquare>
            </IconButton>
          </Secure>
        </Tooltip>
      )}
      {!row.deletedAt && (
        <Tooltip title='Editar' arrow>
          <Secure hasPermission={'ATUALIZAR'} moduleName={moduleName}>
            <IconButton onClick={() => editHandler?.(row)}>
              <EditIcon className='icon-edit'></EditIcon>
            </IconButton>
          </Secure>
        </Tooltip>
      )}

      {!addSubArea && !row.deletedAt && (
        <Tooltip title='Adicionar sub-área' arrow>
          <Secure hasPermission={'CRIAR'} moduleName={moduleName}>
            <IconButton onClick={() => addHandler?.(row)}>
              <AddIcon color='success' />
            </IconButton>
          </Secure>
        </Tooltip>
      )}
      {!row.deletedAt && (
        <Tooltip title='Desativar' arrow>
          <Secure hasPermission={'DELETAR'} moduleName={moduleName}>
            <IconButton
              onClick={() => {
                openDeleteHandler?.(row);
              }}
            >
              <Blocked size='18' className='icon-delete'></Blocked>
            </IconButton>
          </Secure>
        </Tooltip>
      )}
    </TableCell>
  );
}
