import styled from '@emotion/styled';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Radio, { RadioProps } from '@mui/material/Radio';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';

interface HeadLineSpanContentProps {
  requiredColor?: string;
}

interface HeadLineSpanTitleProps {
  requiredColor?: string;
}

interface MultiplaEscolhaContentProps {
  requiredColor?: string;
}

interface MultiplaEscolhaRadioGroupProps extends RadioGroupProps {
  overflowType?: 'auto' | 'scroll' | 'hidden';
}

interface MultiplaEscolhaRadioProps extends RadioProps {
  requiredColor?: string;
}

export const HeadLineSpanContent = styled('span')<HeadLineSpanContentProps>(
  ({ requiredColor }) => ({
    fontFamily: 'Raleway',
    fontWeight: '500',
    fontSize: '10px',
    fontStyle: 'italic',
    color: requiredColor,
  }),
);

export const HeadLineSpanTitle = styled('span')<HeadLineSpanTitleProps>(({ requiredColor }) => ({
  fontFamily: 'Raleway',
  fontWeight: '700',
  fontSize: '12px',
  color: requiredColor,
}));

export const MultiplaEscolhaContent = styled('div')<MultiplaEscolhaContentProps>(
  ({ requiredColor }) => ({
    border: '1px solid',
    borderColor: requiredColor,
    borderRadius: '4px',
    maxHeight: '264px',
    padding: '8px 4px 8px 8px',
    backgroundColor: '#FFFFFF',
    marginBottom: '8px',
  }),
);

export const MultiplaEscolhaRadioGroup = styled(RadioGroup)<MultiplaEscolhaRadioGroupProps>(
  ({ overflowType }) => ({
    overflowY: overflowType,
    maxHeight: '200px',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '3px',
      margin: '2px',
      height: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
);

export const RadioGroupList = styled(List)({
  minWidth: 240,
  backgroundColor: 'white !important',
  padding: '0px',
});

export const RequiredSpan = styled('span')({
  color: '#BA1A1A',
  fontFamily: 'Raleway',
  fontSize: '10px',
  fontWeight: '400',
});

export const MultiplaEscolhaListItemButton = styled(ListItemButton)({
  borderBottom: '1px solid #CAC4D0',
  justifyContent: 'space-between',
  px: 1,
  marginRight: '5px',
});

export const MultiplaEscolhaListItemIcon = styled(ListItemIcon)({
  minWidth: 'auto',
});

export const MultiplaEscolhaRadio = styled(Radio)<MultiplaEscolhaRadioProps>(
  ({ requiredColor }) => ({
    fontFamily: 'Raleway',
    '& .MuiSvgIcon-root': {
      fontSize: 14,
    },
    color: requiredColor,
    '&.Mui-checked': {
      color: requiredColor,
    },
  }),
);
