import { Condition, Rule, RuleEffect } from '@jsonforms/core';
import { FormApi } from '../../../api/generic-api';
import { IUiSchema } from '../../../jsonforms/uischema';

const hideIfInstrumentoJuridico: Rule = {
  effect: RuleEffect.HIDE,
  condition: {
    scope: '#/properties/tipoRecursoLinhaDeAcaoId-codigo',
    schema: {
      not: {
        const: 'tesouro',
      },
    },
  } as Condition,
};

const hideIfTesouro: Rule = {
  effect: RuleEffect.HIDE,
  condition: {
    scope: '#/properties/tipoRecursoLinhaDeAcaoId-codigo',
    schema: {
      not: {
        const: 'instrumentoJuridico',
      },
    },
  } as Condition,
};

const edicaoRecursoLinhaDeAcaoUiSchema: IUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Select',
      scope: '#/properties/tipoRecursoLinhaDeAcaoId',
      label: 'Tipo de Recurso',
      options: {
        onlyCreate: true,
        handleChange: (item: any, handler: (path: string, value: any) => void, formData: any) => {
          const isTesouro = String(item.codigo).toLowerCase() === 'tesouro';
          handler('tesouroId', isTesouro ? formData?.tesouro?.id : null);
          handler('instrumentoJuridicoId', !isTesouro ? formData?.instrumentoJuridico?.id : null);
        },
      },
    },
    {
      type: 'Select',
      scope: '#/properties/instrumentoJuridicoId',
      rule: hideIfTesouro,
    },
    {
      type: 'Select',
      scope: '#/properties/tesouroId',
      rule: hideIfInstrumentoJuridico,
      options: {
        filter: {
          handler: (formData: any, listData: any[], api: FormApi) => {
            return api.getAll({
              withDeleted: 'false',
              orderBy: 'ano',
              orderDirection: 'DESC',
            });
          },
        },
      },
    },
    {
      type: 'MonetaryInput',
      scope: '#/properties/valor',
    },
  ],
};

export default edicaoRecursoLinhaDeAcaoUiSchema;
