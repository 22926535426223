import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makePropostaConviteMembroApi } from '../../api/proposta-convite-membro-api';
import PropostaConviteMembros from '../../components/proposta-convite-membros/proposta-convite-membros';
import { PropostaConviteMembro, TipoSituacao } from '../../models/proposta-convite-membro';
import {
  ConvitesBox,
  ConvitesHeader,
  ConvitesHeaderLabel,
  FooterBox,
  FooterButton,
  QuantidadeConvitesLabel,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledDiversity3,
  StyledExpandMoreIcon,
  StyledMarkEmailUnread,
  TodosConvitesAnchor,
} from './style/convites-home';

export const ConvitesHome = () => {
  const navigate = useNavigate();

  const [apiPropostaResult, setApiPropostaResult] = useState<{
    count: number;
    data: PropostaConviteMembro[];
  }>();
  const [load, setLoad] = useState<boolean>(true);
  const propostaConviteMembroApi = makePropostaConviteMembroApi();

  useEffect(() => {
    loadAvisos();
  }, []);

  async function loadAvisos() {
    propostaConviteMembroApi
      .getAllReceived({
        situacao: TipoSituacao.pendente,
        take: 2,
      })
      .then((resultData) => {
        setApiPropostaResult(resultData);
        setLoad(false);
      });
  }

  return isEmpty(apiPropostaResult) || apiPropostaResult?.count == 0 ? (
    <></>
  ) : (
    <ConvitesBox>
      <ConvitesHeader>
        <StyledMarkEmailUnread />
        <ConvitesHeaderLabel>Convites</ConvitesHeaderLabel>
      </ConvitesHeader>
      <StyledAccordion defaultExpanded>
        <StyledAccordionSummary
          expandIcon={<StyledExpandMoreIcon />}
          aria-controls='convitesAccordion'
          id='convitesAccordion-header'
        >
          <StyledDiversity3 />
          Participação de Projetos
          <QuantidadeConvitesLabel align='center'>
            {apiPropostaResult ? `•  ${apiPropostaResult.count} Pendentes` : ``}
          </QuantidadeConvitesLabel>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <PropostaConviteMembros
            apiResultData={apiPropostaResult.data}
            propostaConviteMembroApi={propostaConviteMembroApi}
            loadState={{ load, setLoad }}
            loadAvisos={loadAvisos}
          />
        </StyledAccordionDetails>
      </StyledAccordion>
      {apiPropostaResult.count > 2 ? (
        <FooterBox>
          <FooterButton>
            <TodosConvitesAnchor onClick={() => navigate('/todos-convites')}>
              Ver Mais ({apiPropostaResult.count})
            </TodosConvitesAnchor>
          </FooterButton>
        </FooterBox>
      ) : (
        ''
      )}
    </ConvitesBox>
  );
};
