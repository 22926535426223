import { Star } from '@styled-icons/evaicons-solid/Star';
import { useNavigate } from 'react-router-dom';
import { Crud } from '../../../components/crud/crud';
import { useCrudContext } from '../../../components/crud/crud-context';
import { moduleNameFor } from '../../../helpers/module-name-for';
import { Programa as ProgramaModel } from '../../../models/programa';
import { headCells } from './programa.headcell';
import programaUiSchema from './programa.uischema';

function Programa() {
  const navigate = useNavigate();
  const { parameterFilters, setParameterFilters } = useCrudContext();

  const edicaoPrograma = {
    hasPermission: ['EXIBIR_NO_MENU', 'BUSCAR_TODOS'],
    moduleName: moduleNameFor('edicao-programa'),
    icon: <Star size='24' color='#009900' />,
    title: 'Edição de Programa',
    handler: (row: any) => {
      setParameterFilters({
        ...parameterFilters,
        programa: row,
      });
      navigate(`/programa/${row?.id}/edicao-programa`);
    },
  };

  return (
    <Crud<ProgramaModel>
      headCells={headCells}
      titleConfig={{ value: 'Programas', show: true }}
      uischema={programaUiSchema}
      apiUrl={'/programa'}
      queryFilters={{
        withDeleted: true,
        orderBy: 'dataInicio',
        orderDirection: 'ASC',
      }}
      customActions={[() => edicaoPrograma]}
      width={650}
    ></Crud>
  );
}

export default Programa;
