import { ControlProps, rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { GenericApi, makeApi } from '../../api/generic-api';
import { useCrudContext } from '../../components/crud/crud-context';
import { IControlElement } from '../uischema';
import './renderer.css';
import {
  CustomSelectProps,
  FilterProps,
  SelectDefault,
  SelectForeignEnum,
  SelectOneOf,
  disableHandler,
  displayPropertiesComputed,
  filterHandler,
  loadEnumDefault,
  loadSavedItem,
} from './select';
import { useCallback, useEffect, useState } from 'react';

const selectTester = rankWith(6, uiTypeIs('Select'));

export const selectRender = {
  tester: selectTester,
  renderer: withJsonFormsControlProps((props: ControlProps) => {
    const { visible = true, schema, path, enabled, data } = props;

    const [api, setApi] = useState<GenericApi<any> | null>(null);
    const [apiListData, setApiListData] = useState<any>([]);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [options, setOptions] = useState<any>([]);
    const [lastFilterValues, setLastFilterValues] = useState<any>([]);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [oneOf, setOneOf] = useState<any>(null);
    const ctx = useCrudContext();
    const { formData, crudStates, disabledFields } = ctx;

    const uischema = props?.uischema as IControlElement;
    const {
      oneOf: customOneOf,
      apiInstance: customApiInstance,
      visibleHandler,
    } = uischema?.options ?? {};

    const apiUrl = (schema as any)?.foreignRoute ?? uischema?.options?.route;
    const foreignEnum = (schema as any)?.foreignEnum;
    const multiple = !!uischema?.options?.selectMultiple;

    const isDisabled = useCallback(
      () =>
        disabled ||
        !enabled ||
        crudStates.view ||
        uischema?.options?.disabled ||
        (crudStates.edit && uischema?.options?.onlyCreate) ||
        disabledFields.includes(path),
      [path, disabled, enabled, crudStates, uischema, disabledFields],
    );

    const isVisible = useCallback(() => {
      const visibility = visibleHandler
        ? visibleHandler?.(ctx, apiListData, lastFilterValues)
        : true;
      return visible && visibility;
    }, [visible, crudStates, visibleHandler, apiListData, lastFilterValues]);

    const customProps: () => CustomSelectProps = useCallback(
      () => ({
        ...props,
        oneOf,
        multiple,
        isVisible: isVisible(),
        isDisabled: isDisabled(),
        selectedItem,
        setSelectedItem,
        apiUrl,
        options,
      }),
      [path, data, selectedItem, options, isDisabled, isVisible],
    );

    const filterProps: () => FilterProps = useCallback(
      () => ({
        uischema,
        customProps: customProps(),
        path,
        ctx,
        apiListData,
        setApiListData,
        lastFilterValues,
        setLastFilterValues,
        api,
        apiUrl,
        setSelectedItem,
      }),
      [path, lastFilterValues, apiListData, selectedItem, ctx, api],
    );

    useEffect(() => {
      setOneOf(customOneOf?.(formData) ?? (schema as any)?.oneOf);
    }, [schema, uischema]);

    useEffect(() => {
      if (customApiInstance) {
        setApi(customApiInstance);
      } else if (!api && apiUrl) {
        setApi(makeApi(apiUrl));
      }
    }, [api, apiUrl]);

    useEffect(() => {
      const filter = uischema?.options?.filter;
      let hasFormField = filter?.formFields ? true : false;
      if (!filter) {
        api?.getAll?.().then((dataFromApi: any) => {
          setApiListData(dataFromApi);
          loadSavedItem(uischema, customProps(), dataFromApi, setSelectedItem, formData, apiUrl);
          setTimeout(() => loadEnumDefault(dataFromApi, customProps(), ctx, schema), 100);
        });
      } else if (api && !hasFormField) {
        const { handler } = filter;
        handler?.(formData, apiListData, api, undefined, path)?.then?.((dataFromApi: any) => {
          setApiListData(dataFromApi);
        });
        filterHandler(filterProps());
      } else if (api) filterHandler(filterProps());
    }, [api, path]);

    useEffect(() => {
      let options = [];
      if (Array.isArray(oneOf)) {
        options = oneOf.map((option) => ({
          label: `${option?.title}`,
          value: `${option?.const}`,
        }));
      } else {
        options = apiListData?.map?.((item: any) => ({
          label: displayPropertiesComputed(uischema, item, apiUrl),
          value: item?.id,
          codigo: item?.codigo ?? '',
          data: item,
        }));
      }
      disableHandler(uischema, ctx, apiListData, setDisabled);
      setOptions(options);
    }, [apiListData, oneOf]);

    // Carrega o item selecionado para o select default e foreignEnum
    useEffect(() => {
      if (!multiple && props.data && apiListData?.length) {
        const item = apiListData?.find?.((item: any) => item?.id === Number(props?.data));
        if (item) {
          const option = {
            label: displayPropertiesComputed(uischema, item, apiUrl),
            value: item?.id,
            codigo: item.codigo,
            data: item,
          };
          setSelectedItem(option);
        } else setSelectedItem(null);
      }
    }, [props.data, apiListData]);

    useEffect(() => {
      if (selectedItem && Object.keys(selectedItem).length) {
        let foreignEnum;
        if ('codigo' in selectedItem && (foreignEnum = (schema as any)?.foreignEnum)) {
          const [codeKey] =
            Object.entries(foreignEnum).find(([, value]) => value === selectedItem.codigo) ?? [];
          setTimeout(() => props.handleChange(`${path}-codigo`, codeKey), 200);
        }
      }
    }, [selectedItem]);

    useEffect(() => {
      if (!(crudStates.edit || crudStates.view || crudStates.add) || !api) return;
      filterHandler(filterProps());
    }, [api, crudStates, apiListData, selectedItem, lastFilterValues, formData]);

    if (oneOf) return <SelectOneOf {...customProps()} />;
    if (foreignEnum) return <SelectForeignEnum {...customProps()} />;
    return <SelectDefault {...customProps()} />;
  }),
};
