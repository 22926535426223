import { useParams } from 'react-router-dom';
import { useCrudContext } from '../../../../components/crud/crud-context';
import { Crud } from '../../../../components/crud/crud';
import { Data, headCells } from './historico-ajuste-edicao-recurso-linha-de-acao.headcell';
import historicoAjusteEdicaoRecursoLinhaDeAcaoUiSchema from './historico-ajuste-edicao-recurso-linha-de-acao.uischema';
import { useEffect, useState } from 'react';
import { whiteSpaces } from '../../../../helpers/custom-errors';
import { makeApi } from '../../../../api/generic-api';

export interface IEdicaoRecursoLinhaDeAcao {
  id: number;
  edicaoRecursoLinhaDeAcaoId: number;
  valor: number;
  deletedAt?: Date;
}

export enum TipoCodigo {
  corte = 'CORTE',
  aditivo = 'ADITIVO',
}

function HistoricoAjusteEdicaoRecursoLinhaDeAcao() {
  const [edicaoRecursoLinhaDeAcao, setEdicaoRecursoLinhaDeAcao] =
    useState<IEdicaoRecursoLinhaDeAcao>({} as IEdicaoRecursoLinhaDeAcao);
  const { apiListData } = useCrudContext();
  const { edicaoRecursoLinhaDeAcaoId } = useParams();
  const edicaoRecursoLinhaDeAcaoIdNumber = Number(edicaoRecursoLinhaDeAcaoId);
  const customErrors = [whiteSpaces('justificativa')];

  const defaultForm = {
    edicaoRecursoLinhaDeAcaoId: edicaoRecursoLinhaDeAcaoIdNumber,
    valorPrevisto: edicaoRecursoLinhaDeAcao?.valor,
    edicaoRecursoLinhaDeAcao,
  };

  const api = makeApi<any>('/historico-ajuste-edicao-recurso-linha-de-acao');
  const apiEdicaoRecursoLinhaDeAcao = makeApi(`/edicao-recurso-linha-de-acao`);
  const get = api.get.bind(api);
  api.get = async (id: number) => {
    const response = await get(id);
    response.novoValor = response.valorFinal;
    return response;
  };

  useEffect(() => {
    apiEdicaoRecursoLinhaDeAcao
      .get(edicaoRecursoLinhaDeAcaoIdNumber)
      .then((data) => setEdicaoRecursoLinhaDeAcao(data as IEdicaoRecursoLinhaDeAcao));
  }, [apiListData]);

  return (
    <Crud<Data>
      headCells={headCells}
      queryFilters={{
        withDeleted: true,
        edicaoRecursoLinhaDeAcaoId: edicaoRecursoLinhaDeAcaoIdNumber,
        orderBy: 'createdAt',
        orderDirection: 'DESC',
      }}
      uischema={historicoAjusteEdicaoRecursoLinhaDeAcaoUiSchema}
      titleConfig={{ value: 'Ajuste de Recursos da Linha de Ação', show: true }}
      apiUrl={'/historico-ajuste-edicao-recurso-linha-de-acao'}
      apiClient={api}
      defaultForm={defaultForm}
      customErrors={customErrors}
      hideCreate={!!edicaoRecursoLinhaDeAcao.deletedAt}
      hideDelete
      hideUpdate
    ></Crud>
  );
}

export default HistoricoAjusteEdicaoRecursoLinhaDeAcao;

export const rootViews = ['edicao-recurso-linha-de-acao'];
