import { Crud } from '../../../components/crud/crud';
import { whiteSpaces } from '../../../helpers/custom-errors';
import { FuncionalProgramatica as FuncionalProgramaticaModel } from '../../../models/funcional-programatica';
import funcionalProgramaticaUiSchema from './funcional-programatica.uischema';
import { headCells } from './funcional-programatica.headcell';

function FuncionalProgramatica() {
  const customErrors = [whiteSpaces('nome'), whiteSpaces('descricao')];

  return (
    <Crud<FuncionalProgramaticaModel>
      headCells={headCells}
      titleConfig={{ value: 'Funcionais Programáticas', show: true }}
      apiUrl={'/funcional-programatica'}
      uischema={funcionalProgramaticaUiSchema}
      customErrors={customErrors}
      hideView
      queryFilters={{ withDeleted: true }}
    ></Crud>
  );
}

export default FuncionalProgramatica;
