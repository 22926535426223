import styled from '@emotion/styled';
import { PersonPin } from '@mui/icons-material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { FluentDocumentFolder24Filled } from '../../validity-checker/fluent-document-folder-24-filled';

export const StyledDocumentFolder = styled(FluentDocumentFolder24Filled)({
  marginLeft: '16px',
  width: '16px',
  height: '16px',
  color: '#003D92',
});

export const StyledPersonPin = styled(PersonPin)({
  marginLeft: '16px',
  width: '16px',
  height: '16px',
  color: '#003D92',
});

export const TituloLabel = styled(Typography)({
  fontSize: '12px',
  fontWeight: 600,
  fontFamily: 'Raleway',
  lineHeight: '14.09px',
  letterSpacing: '0.15px',
  color: '#454545 !important',
  marginLeft: '16px',
});

export const TituloInformacoesLabel = styled(Typography)({
  fontSize: '12px',
  fontWeight: 600,
  marginLeft: '8px',
  fontFamily: 'Raleway',
  lineHeight: '14.09px',
  letterSpacing: '0.15px',
  color: '#454545 !important',
});

export const InformacoesLabel = styled(Typography)({
  fontSize: '10px',
  fontWeight: 500,
  marginLeft: '8px',
  fontFamily: 'Raleway',
  color: '#2F3036 !important',
});

export const ComponentBox = styled(Box)({
  width: '100%',
  fontFamily: 'Raleway',
});

export const BackgroundList = styled(List)({
  padding: '0px !important',
  width: '100%',
  minHeight: '107px',
});

export const BackgroundListItem = styled(ListItem)({
  display: 'flex',
  padding: '0px !important',
  marginTop: '8px',
  width: '100%',
});

export const BackgroundItem = styled(ListItem)({
  display: 'flex',
  padding: '0px !important',
  marginTop: '8px',
  width: '100%',
  borderBottom: 'solid 1px #C5C6D0',
});

export const CoordenadorListItem = styled(ListItem)({
  width: '100%',
  marginTop: '8px',
  marginBottom: '8px',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0px !important',
});

export const CoordenadorBox = styled(Box)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
});

export const BackgroundListItemButton = styled(ListItemButton)({});

export const ConviteList = styled(Box)({
  fontWeight: 600,
});

export const BackgroundBoxButtons = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginTop: '8px',
  marginRight: '16px',
});

export const BackgroundBoxActionButtons = styled(Box)({
  width: '100%',
  minHeight: '107px',
  maxHeight: '107px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  alignContent: 'center',
  justifyContent: 'center',
});

export const AcaoLabel = styled(Typography)({
  fontSize: '12px !important',
  fontWeight: 600,
  fontFamily: 'Raleway',
  color: '#454545 !important',
  display: 'inline',
});

export const AceitarLabel = styled(Typography)({
  fontSize: '12px',
  fontWeight: 600,
  fontFamily: 'Raleway',
  color: '#1351B4 !important',
  display: 'inline',
});

export const RecusarLabel = styled(Typography)({
  fontSize: '12px',
  fontWeight: 600,
  fontFamily: 'Raleway',
  color: '#BA1A1A !important',
  display: 'inline',
});

export const AcceptListItemButton = styled(ListItemButton)({
  backgroundColor: '#003D92 !important',
  '&:hover': {
    backgroundColor: '#003D92 !important',
  },
  width: '68px',
  border: '0.5px solid',
  borderRadius: '4px',
  borderColor: '#003D92',
  padding: '4px',
  gap: '10px',
  fontSize: '12px',
  fontFamily: 'Raleway',
  lineHeight: '14.09px',
  letterSpacing: '0.15px',
  color: '#FFFFFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
});

export const RefuseListItemButton = styled(ListItemButton)({
  backgroundColor: '#FFFFFF !important',
  '&:hover': {
    backgroundColor: '#FFFFFF !important',
  },
  width: '68px',
  border: '0.5px solid',
  borderRadius: '4px',
  borderColor: '#003D92',
  padding: '4px',
  gap: '10px',
  fontSize: '12px',
  fontFamily: 'Raleway',
  lineHeight: '14.09px',
  letterSpacing: '0.15px',
  color: '#003D92',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  marginLeft: '8px',
});
