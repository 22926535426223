export const ResizeHandleIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width='100'
    height='100'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M21 15L15 21M21 8L8 21' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
