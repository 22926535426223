import { useEffect, useState } from 'react';
import { makeApi } from '../../api/generic-api';
import {
  AvisosEmpty,
  AvisosHeaderLabel,
  AvisosIcon,
  ComponentBox,
  FooterBox,
  FooterButton,
  Header,
  StyledEmptyList,
  StyledList,
  StyledListItem,
  StyledListItemText,
} from './style/avisos-home';
import { TodosEditaisAnchor } from '../../components/layout/style/edital/edital-home';
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';

export const AvisosHome = () => {
  const navigate = useNavigate();
  const avisosApi = makeApi('/aviso');
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    avisosApi
      .getAll({ dataAtual: new Date().toISOString(), orderBy: 'dataInicio', take: 5 })
      .then((resultData) => {
        setData(resultData);
      });
  }, []);

  return (
    <>
      {data.length ? (
        <ComponentBox>
          <Header>
            <AvisosIcon />
            <AvisosHeaderLabel>Avisos</AvisosHeaderLabel>
          </Header>
          <Divider />
          <StyledList>
            {data.map((item, index) => (
              <StyledListItem key={index}>
                <StyledListItemText primary={item.titulo} />
              </StyledListItem>
            ))}
          </StyledList>
          <Divider />
          <FooterBox>
            <FooterButton>
              <TodosEditaisAnchor onClick={() => navigate('/todos-avisos')}>
                Ver Todos
              </TodosEditaisAnchor>
            </FooterButton>
          </FooterBox>
        </ComponentBox>
      ) : (
        ''
      )}
    </>
  );
};
