import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import { CircleFlag } from 'react-circle-flags';
import { Crud } from '../../components/crud/crud';
import { useCrudContext } from '../../components/crud/crud-context';
import CustomAvatarEditor from '../../components/layout/custom-avatar-editor';
import { Usuario } from '../../models/usuario';
import { validateCPF } from '../../utils/cpf-validator';
import { useAuthContext } from '../login/auth/auth-context';
import { makeEditarPerfilApi } from './editar-perfil-api';
import './editar-perfil.css';
import { headCells } from './editar-perfil.headcell';
import editarPerfilUiSchema from './editar-perfil.uischema';
import {
  EditarPerfilHeader,
  UsuarioInfo,
  UsuarioInfoNome,
  UsuarioInfoPais,
} from './editar-perfil.styled';

function EditarPerfil() {
  const apiClient = makeEditarPerfilApi('usuario');

  const { formData, crudStates, showSuccess, showError } = useCrudContext();
  const [userImageData, setUserImageData] = React.useState(formData);
  const [userHeaderData, setUserHeaderData] = React.useState(formData);
  const { updateUserProfileImage } = useAuthContext();
  const defaultForm = { ...cloneDeep(formData), possuiVinculoInstitucional: false };

  const customErrors = [validateCPF('documento')];

  React.useEffect(() => {
    if (Object.keys(formData).length && crudStates.view) {
      setUserImageData(formData.fotoPerfil);
      setUserHeaderData(formData);
    }
  }, [crudStates.view, formData]);

  const saveHandler = async (profileImage: any) => {
    try {
      await apiClient?.atualizarFotoPerfil?.({ fotoPerfil: profileImage }).then(() => {
        showSuccess('Foto atualizada');
        setUserImageData(profileImage);
        updateUserProfileImage(profileImage);
      });
    } catch (error: any) {
      showError(error?.cause?.response?.data?.message?.[0] || 'Ocorreu um erro.');
    }
  };

  return (
    <>
      <EditarPerfilHeader>
        <CustomAvatarEditor
          username={userHeaderData?.nome}
          image={userImageData}
          saveHandler={saveHandler}
        />
        <UsuarioInfo>
          <UsuarioInfoNome>{userHeaderData?.nome}</UsuarioInfoNome>
          <UsuarioInfoPais>
            <CircleFlag
              countryCode={userHeaderData ? userHeaderData.pais?.sigla?.toLowerCase() : ''}
            />
            {userHeaderData?.pais?.nomePt}
          </UsuarioInfoPais>
        </UsuarioInfo>
      </EditarPerfilHeader>

      <Crud<Usuario>
        headCells={headCells}
        titleConfig={{ value: 'Editar Perfil', show: false }}
        uischema={editarPerfilUiSchema}
        apiClient={apiClient}
        defaultForm={defaultForm}
        customErrors={customErrors}
        customSave={{ show: false }}
        initView
        ignorePermissions
      ></Crud>
    </>
  );
}
export default EditarPerfil;
