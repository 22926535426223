import { TipoCodigo } from './historico-ajuste-edicao-recurso-linha-de-acao.view';

const updateNovoValor = (
  tipo: string,
  valorAtual: number,
  valorAjuste: number,
  handler: (path: string, value: any) => void,
) => {
  let novoValor = 0;

  if (tipo === TipoCodigo.corte) {
    novoValor = valorAtual - (valorAjuste ?? 0);
  } else if (tipo === TipoCodigo.aditivo) {
    novoValor = valorAtual + (valorAjuste ?? 0);
  } else {
    novoValor = valorAtual;
  }

  handler('novoValor', novoValor);
};

const historicoAjusteEdicaoRecursoLinhaDeAcaoUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'MonetaryInput',
          scope: '#/properties/edicaoRecursoLinhaDeAcao/properties/valor',
          label: 'Valor Atual',
          options: { disabled: true },
        },
        {
          type: 'Select',
          scope: '#/properties/tipo',
          label: 'Tipo',
          options: {
            handleChange: (
              tipo: any,
              handler: (path: string, value: any) => void,
              formData: any,
            ) => {
              updateNovoValor(
                tipo,
                formData.edicaoRecursoLinhaDeAcao.valor,
                formData.valorAjuste,
                handler,
              );
            },
          },
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'MonetaryInput',
          scope: '#/properties/valorAjuste',
          label: 'Valor do Ajuste',
          options: {
            required: true,
            handleChange: (
              valueAjuste: number,
              handler: (path: string, value: any) => void,
              formData: any,
            ) => {
              if (!valueAjuste) return;
              updateNovoValor(
                formData.tipo,
                formData.edicaoRecursoLinhaDeAcao.valor,
                valueAjuste,
                handler,
              );
            },
          },
        },
        {
          type: 'MonetaryInput',
          scope: '#/properties/novoValor',
          label: 'Novo Valor',
          options: { disabled: true },
        },
      ],
    },

    {
      type: 'LongText',
      scope: '#/properties/justificativa',
      label: 'Justificativa',
    },
  ],
};

export default historicoAjusteEdicaoRecursoLinhaDeAcaoUiSchema;
