import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeApi } from '../../../../api/generic-api';
import { Crud } from '../../../../components/crud/crud';
import { useCrudContext } from '../../../../components/crud/crud-context';
import { whiteSpaces } from '../../../../helpers/custom-errors';
import { Data, headCells } from './historico-ajuste-tesouro.headcell';
import historicoAjusteTesouroUiSchema from './historico-ajuste-tesouro.uischema';

export interface ITesouro {
  id: number;
  ano: number;
  valorPrometido: number;
  valorPrevisto: number;
  valorAtual: number;
  deletedAt?: Date;
}

function HistoricoAjusteTesouro() {
  const [tesouro, setTesouro] = useState<ITesouro>({} as ITesouro);
  const { parameterFilters } = useCrudContext();
  const { apiListData } = useCrudContext();
  const { tesouroId } = useParams();
  const tesouroIdNumber = Number(tesouroId);
  const customErrors = [whiteSpaces('justificativa')];
  const defaultForm = {
    tesouroId: tesouroIdNumber,
    valorPrevisto: tesouro?.valorPrometido,
    tesouro,
  };
  const api = makeApi<Data>('/historico-ajuste-tesouro');
  const apiTesouro = makeApi(`/tesouro`);
  const get = api.get.bind(api);
  api.get = async (id: number) => {
    const response = await get(id);
    response.novoValor = response.valorFinal;
    return response;
  };

  useEffect(() => {
    apiTesouro.get(tesouroIdNumber).then((data) => setTesouro(data as ITesouro));
  }, [apiListData]);

  return (
    <Crud<Data>
      headCells={headCells}
      queryFilters={{
        withDeleted: true,
        tesouroId: tesouroIdNumber,
        orderBy: 'createdAt',
        orderDirection: 'DESC',
      }}
      titleConfig={{ value: 'Ajuste de Tesouro', show: true }}
      apiUrl={'/historico-ajuste-tesouro'}
      apiClient={api}
      uischema={historicoAjusteTesouroUiSchema}
      defaultForm={defaultForm}
      customErrors={customErrors}
      hideCreate={!!tesouro.deletedAt}
      hideUpdate
      hideDelete
    ></Crud>
  );
}

export default HistoricoAjusteTesouro;

export const rootViews = ['tesouro'];
