import client from '../config/axios-config';
import { PropostaConviteMembro } from '../models/proposta-convite-membro';
import { FormApi, GenericApi } from './generic-api';

export interface IPropostaConviteMembroApi extends FormApi {
  aceitar(id: number, data: any): Promise<any>;
  recusar(id: number): Promise<any>;
  remover(id: number): Promise<any>;
  reenviar(id: number): Promise<any>;
  getAllReceived: (options?: { [key: string]: any }) => Promise<any>;
}

export class PropostaConviteMembroApi
  extends GenericApi<PropostaConviteMembro>
  implements IPropostaConviteMembroApi
{
  async aceitar(id: number, data: any): Promise<any> {
    try {
      const response = await client.put<any>(`${this.url}/${id}/aceitar`, data);
      return response;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Put error: ', { cause: error });
    }
  }
  async recusar(id: number): Promise<any> {
    try {
      const response = await client.put<any>(`${this.url}/${id}/recusar`);
      return response;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Put error: ', { cause: error });
    }
  }
  async remover(id: number): Promise<any> {
    try {
      const response = await client.put<any>(`${this.url}/${id}/remover`);
      return response;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Put error: ', { cause: error });
    }
  }
  async reenviar(id: number): Promise<any> {
    try {
      const response = await client.put<any>(`${this.url}/${id}/reenviar`);
      return response;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Put error: ', { cause: error });
    }
  }
  async getAllReceived(
    options: { [key: string]: any } = { withDeleted: false },
  ): Promise<{ count: number; data: PropostaConviteMembro[] }> {
    try {
      const urlBuild = Object.entries(options).reduce((acc, [chave, valor], i) => {
        return `${acc}${i === 0 ? '?' : '&'}${chave}=${String(valor) ?? ''}`;
      }, `${this.url}/recebido`);
      const response = await client.get<{ count: number; data: PropostaConviteMembro[] }>(urlBuild);
      return response?.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('GetAll error: ', { cause: error });
    }
  }
}

export const makePropostaConviteMembroApi = () => {
  return new PropostaConviteMembroApi('/proposta-convite-membro');
};
