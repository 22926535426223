import {
  ArrayTranslations,
  ControlElement,
  JsonFormsCellRendererRegistryEntry,
  JsonFormsRendererRegistryEntry,
  JsonFormsUISchemaRegistryEntry,
  JsonSchema,
  composePaths,
  computeChildLabel,
  createId,
  findUISchema,
  moveDown,
  moveUp,
  removeId,
  update,
} from '@jsonforms/core';
import {
  JsonFormsDispatch,
  JsonFormsStateContext,
  useJsonForms,
  withJsonFormsContext,
} from '@jsonforms/react';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import { Trash3 as DeleteIcon } from '@styled-icons/bootstrap/Trash3';
import {
  ComponentType,
  Dispatch,
  memo,
  ReducerAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import './style/simple-item-layout.css';

const iconStyle: any = { float: 'right' };

interface OwnPropsOfSimpleItemLayout {
  enabled: boolean;
  index: number;
  path: string;
  uischema: ControlElement;
  schema: JsonSchema;
  renderers?: JsonFormsRendererRegistryEntry[];
  cells?: JsonFormsCellRendererRegistryEntry[];
  uischemas?: JsonFormsUISchemaRegistryEntry[];
  rootSchema: JsonSchema;
  config: any;
  childLabelProp: string;
  translations: ArrayTranslations;
}

interface StatePropsOfSimpleItemLayout extends OwnPropsOfSimpleItemLayout {
  childLabel: string;
  childPath: string;
}

export interface DispatchPropsOfSimpleItemLayout {
  removeItems(path: string, toDelete: number[]): (event: any) => void;
  moveUp(path: string, toMove: number): (event: any) => void;
  moveDown(path: string, toMove: number): (event: any) => void;
}

export interface SimpleItemLayoutProps
  extends StatePropsOfSimpleItemLayout,
    DispatchPropsOfSimpleItemLayout {
  arrayLength?: number;
}

const SimpleItemLayoutComponent = (props: SimpleItemLayoutProps) => {
  const [labelHtmlId] = useState<string>(createId('array-panel'));

  useEffect(() => {
    return () => {
      removeId(labelHtmlId);
    };
  }, [labelHtmlId]);

  const {
    enabled,
    childPath,
    index,
    removeItems,
    path,
    rootSchema,
    schema,
    uischema,
    uischemas,
    renderers,
    cells,
    translations,
    moveUp,
    moveDown,
  } = props;

  const { core } = useJsonForms();
  const arrayData = core?.data[path];

  const foundUISchema = useMemo(
    () =>
      findUISchema(uischemas as any, schema, uischema.scope, path, undefined, uischema, rootSchema),
    [uischemas, schema, uischema.scope, path, uischema, rootSchema],
  );

  return (
    <Grid container alignItems={'center'} justifyContent={'space-between'} className='array-panel'>
      <Grid item xs={10}>
        <Grid container alignItems={'center'}>
          <Grid item>
            <Avatar aria-label='Index' className='array-item-number'>
              {index + 1}
            </Avatar>
          </Grid>
          <Grid item className='array-item-container'>
            <JsonFormsDispatch
              enabled={enabled}
              schema={schema}
              uischema={foundUISchema}
              path={childPath}
              key={childPath}
              renderers={renderers}
              cells={cells}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2} display={'flex'} flexDirection={'row'}>
        {uischema?.options?.showSortButtons && enabled && (
          <>
            <Grid item>
              <Tooltip title={'Mover para cima'} placement='bottom'>
                <IconButton
                  onClick={moveUp(path, index)}
                  style={iconStyle}
                  aria-label={translations.upAriaLabel}
                  size='medium'
                  disabled={index === 0}
                >
                  <ArrowUpward />
                </IconButton>
              </Tooltip>
              <Tooltip title='Mover para baixo' placement='bottom'>
                <IconButton
                  onClick={moveDown(path, index)}
                  style={iconStyle}
                  aria-label='Mover para baixo'
                  size='medium'
                  disabled={index === (arrayData ? arrayData.length : 0) - 1}
                >
                  <ArrowDownward />
                </IconButton>
              </Tooltip>
            </Grid>
          </>
        )}
        {enabled && (
          <Grid item>
            <Tooltip id='tooltip-remove' title={'Remover'} placement='bottom'>
              <IconButton
                onClick={removeItems(path, [index])}
                style={iconStyle}
                aria-label={translations.removeAriaLabel}
                size='large'
              >
                <DeleteIcon size={20} />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export const SimpleItemLayout = memo(SimpleItemLayoutComponent);

export const ctxDispatchToSimpleItemLayoutProps: (
  dispatch: Dispatch<ReducerAction<any>>,
) => DispatchPropsOfSimpleItemLayout = (dispatch) => ({
  removeItems: useCallback(
    (path: string, toDelete: number[]) =>
      (event: any): void => {
        event.stopPropagation();
        dispatch(
          update(path, (array) => {
            toDelete
              .sort()
              .reverse()
              .forEach((s) => array.splice(s, 1));
            return array;
          }),
        );
      },
    [dispatch],
  ),
  moveUp: useCallback(
    (path: string, toMove: number) =>
      (event: any): void => {
        event.stopPropagation();
        dispatch(
          update(path, (array) => {
            moveUp(array, toMove);
            return array.map((item, index) => ({
              ...item,
              posicao: index + 1,
            }));
          }),
        );
      },
    [dispatch],
  ),
  moveDown: useCallback(
    (path: string, toMove: number) =>
      (event: any): void => {
        event.stopPropagation();
        dispatch(
          update(path, (array) => {
            moveDown(array, toMove);
            return array.map((item, index) => ({
              ...item,
              posicao: index + 1,
            }));
          }),
        );
      },
    [dispatch],
  ),
});

export const withContextToSimpleItemLayoutProps = (
  Component: ComponentType<SimpleItemLayoutProps>,
): ComponentType<{
  ctx: JsonFormsStateContext;
  props: OwnPropsOfSimpleItemLayout;
}> => {
  return function WithContextToSimpleItemLayoutProps({
    ctx,
    props,
  }: {
    ctx: JsonFormsStateContext;
    props: OwnPropsOfSimpleItemLayout;
  }) {
    const dispatchProps = ctxDispatchToSimpleItemLayoutProps(ctx.dispatch as any);
    const { childLabelProp, schema, uischema, rootSchema, path, index, uischemas } = props;
    const childPath = composePaths(path, `${index}`);

    const childLabel = useMemo(() => {
      return computeChildLabel(
        ctx?.core?.data,
        childPath,
        childLabelProp,
        schema,
        rootSchema,
        ctx!.i18n!.translate!,
        uischema,
      );
    }, [
      ctx?.core?.data,
      childPath,
      childLabelProp,
      schema,
      rootSchema,
      ctx?.i18n?.translate,
      uischema,
    ]);

    return (
      <Component
        {...props}
        {...dispatchProps}
        childLabel={childLabel}
        childPath={childPath}
        uischemas={uischemas}
      />
    );
  };
};

export const withJsonFormsSimpleItemLayoutProps = (
  Component: ComponentType<SimpleItemLayoutProps>,
): ComponentType<OwnPropsOfSimpleItemLayout> =>
  withJsonFormsContext(withContextToSimpleItemLayoutProps(Component));

export default withJsonFormsSimpleItemLayoutProps(SimpleItemLayout);
