import { Dispatch } from 'react';
import { GenericApi } from '../../api/generic-api';
import DeleteDialog from '../crud/delete-dialog';
import AlertCustom, { Severity } from '../crud/alert-custom';
import { Action } from '../crud/data-table/actions-table-cell';
import DataTable from '../crud/data-table/data-table';
import { HeadCell } from '../crud/protocols/head-cell';
import CustomMultipleSelectUserChip from './custom-multiple-select-user-chip';
import { Usuario } from '../../models/usuario';

type customMultipleUserSelectProps<T> = {
  headCells: readonly HeadCell<T>[];
  moduleName: string;
  moduleLabel: string;
  apiSearchInstance: GenericApi<unknown>;
  apiListData: any[];
  customActions: ((row: any) => Action | undefined)[];
  onCreateParticipants: () => void;
  onTextChange: (searchText: string) => void;
  handleAdd: (e: any) => void;
  usuariosState: {
    usuarios: Usuario[];
    setUsuarios: Dispatch<React.SetStateAction<Usuario[]>>;
  };
  selectedUsuariosState: {
    selectedUsuarios: Usuario[];
    setSelectedUsuarios: Dispatch<React.SetStateAction<Usuario[]>>;
  };
  dialogProps: {
    openDialog: boolean;
    handleCloseDelete: () => void;
    destroy: () => Promise<void>;
  };
  alertCustom: {
    openAlert: boolean;
    severityAlert: Severity;
    messageAlert: string;
  };
  hasSecure: boolean;
  enabled?: boolean;
};

export default function CustomMultipleUserSelect<T extends Record<string, any>>(
  props: customMultipleUserSelectProps<T>,
) {
  const { openDialog, handleCloseDelete, destroy } = props.dialogProps;
  const { openAlert, severityAlert, messageAlert } = props.alertCustom;
  return (
    <>
      <DataTable
        headCells={props.headCells}
        rows={props.apiListData}
        title={props.moduleLabel}
        moduleName={props.moduleName}
        customActions={props.customActions}
        customTableHeader={
          <CustomMultipleSelectUserChip
            apiSearchInstance={props.apiSearchInstance}
            apiListData={props.apiListData}
            onTextChange={props.onTextChange}
            onCreateParticipants={props.onCreateParticipants}
            usuariosState={props.usuariosState}
            selectedUsuariosState={props.selectedUsuariosState}
            handleAdd={props.handleAdd}
            hasSecure={props.hasSecure}
            enabled={props.enabled}
          ></CustomMultipleSelectUserChip>
        }
        styledPaperSx={{ margin: '0px !important' }}
        hideView
        hideUpdate
        hideDelete
      ></DataTable>
      <DeleteDialog
        openDialog={openDialog}
        body={<></>}
        handleCloseDelete={handleCloseDelete}
        destroy={destroy}
        customHeaderText='Deseja realmente deletar este item?'
      ></DeleteDialog>
      <AlertCustom
        open={openAlert}
        severity={severityAlert}
        message={messageAlert}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </>
  );
}
