import Footer from '../components/layout/footer';
import { AvisosHome } from './avisos/avisos-home';
import { ConvitesHome } from './convites/convites-home';
import { EditalHome } from './edital/lista-editais/edital-home';

function Home() {
  return (
    <>
      <AvisosHome />
      <ConvitesHome />
      <EditalHome />
      <Footer />
    </>
  );
}

export default Home;
