import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ExclamationTriangleFill } from 'styled-icons/bootstrap';

export const ComponentBox = styled(Box)({
  width: '100%',
  maxWidth: 826,
  fontFamily: 'Raleway',
  backgroundColor: 'white !important',
  marginBottom: '30px',
});

export const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  paddingTop: '25px',
  paddingBottom: '40px',
});

export const AvisosIcon = styled(ExclamationTriangleFill)({
  width: '28px',
  height: '28px',
  color: '#003D92',
  marginLeft: '20px',
});

export const AvisosHeaderLabel = styled(Typography)({
  fontSize: '22px',
  fontWeight: 600,
  marginLeft: '10px',
  fontFamily: 'Raleway',
  backgroundColor: 'white !important',
  '&:hover': {
    backgroundColor: 'white !important',
  },
});

export const StyledEmptyList = styled(List)({
  backgroundColor: 'white !important',
  color: '#434653 !important',
  fontWeight: 600,
  '&:hover': {
    backgroundColor: 'white !important',
  },
});

export const StyledList = styled(List)({
  width: '100%',
  height: '100%',
  maxWidth: '785px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  listStyleType: 'disc',
  padding: '8px',
  marginLeft: '36px',
  backgroundColor: 'white !important',
  color: '#434653 !important',
  '&:hover': {
    backgroundColor: 'white !important',
  },
});

export const AvisosEmpty = styled(Typography)({
  marginLeft: '20px',
  backgroundColor: 'white !important',
});

export const StyledListItem = styled(ListItem)({
  display: 'list-item',
  padding: 0,
  backgroundColor: 'white !important',
  '&:hover': {
    backgroundColor: 'white !important',
  },
});

export const StyledListItemText = styled(ListItemText)({
  backgroundColor: 'white !important',
  '&:hover': {
    backgroundColor: 'white !important',
  },
});

export const FooterBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row-reverse',
  paddingTop: '16px',
  paddingBottom: '24px',
  paddingRight: '32px',
});

export const FooterButton = styled(Typography)({
  fontSize: '16px',
  color: '#003D92 !important',
  fontWeight: 600,
  fontFamily: 'Raleway',
  backgroundColor: 'white !important',
  '&:hover': {
    backgroundColor: 'white !important',
  },
});

export const TodosAvisosAnchor = styled('a')({
  cursor: 'pointer',
  backgroundColor: 'white !important',
  '&:hover': {
    backgroundColor: 'white !important',
  },
});
