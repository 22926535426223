import { Eye } from '@styled-icons/entypo/Eye';
import { useNavigate } from 'react-router-dom';
import { Crud } from '../../../components/crud/crud';
import { moduleNameFor } from '../../../helpers/module-name-for';
import { Pergunta as PerguntaModel } from '../../../models/pergunta';
import { headCells } from './pergunta.headcell';
import perguntaUiSchema from './pergunta.uischema';
import { GenericApi, makeApi } from '../../../api/generic-api';
import { useEffect, useState } from 'react';

const defaultForm = {
  obrigatoria: false,
  descritiva: {
    minimo: 0,
    maximo: 0,
  },
};

function Pergunta() {
  const [api, setApi] = useState<GenericApi<PerguntaModel>>();
  const [uiSchema, setUiSchema] = useState<any>(null);

  const navigate = useNavigate();

  const perguntaDetalhes = {
    hasPermission: ['EXIBIR_NO_MENU', 'BUSCAR_TODOS'],
    moduleName: moduleNameFor('pergunta'),
    icon: <Eye size='24' />,
    title: 'Visualizar',
    handler: (row: any) => {
      navigate(`/pergunta-detalhes/${row?.id}`);
    },
  };

  useEffect(() => {
    setApi(
      makeApi<PerguntaModel>('/pergunta', {
        orderRelationBy: { orderMultiplaEscolhaBy: (a, b) => a.id - b.id },
      }),
    );

    setUiSchema(perguntaUiSchema());
  }, []);

  return (
    <Crud<PerguntaModel>
      headCells={headCells}
      titleConfig={{ value: 'Perguntas Propostas', show: true }}
      queryFilters={{ withDeleted: false, perguntaBase: true }}
      uischema={uiSchema}
      apiUrl={'/pergunta'}
      apiClient={api}
      defaultForm={defaultForm}
      hideView
      customActions={[() => perguntaDetalhes]}
      width={500}
    ></Crud>
  );
}

export default Pergunta;
