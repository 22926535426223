import { Crud } from '../../../components/crud/crud';
import { Proposta as PropostaModel } from '../../../models/proposta';
import { headCells } from './proposta.headcell';
import propostaUiSchema from './proposta.uischema';

export enum TrechoPassagemProposta {
  nacional = 'nacional',
  internacional = 'internacional',
}

function Proposta() {
  return (
    <Crud<PropostaModel>
      headCells={headCells}
      titleConfig={{ value: 'Submissão de Propostas', show: true }}
      uischema={propostaUiSchema}
      queryFilters={{ withDeleted: true, orderBy: 'tituloDoProjeto', orderDirection: 'ASC' }}
      apiUrl={'/proposta'}
      hideDelete
      hideUpdate
      hideCreate
    ></Crud>
  );
}

export default Proposta;
