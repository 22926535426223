import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppRoutes from './app-routes';
import BreadcrumbsProvider from './components/breadcrumb/breadcrumb-context';
import { CrudProvider } from './components/crud/crud-context';
import { ThemeProvider } from './components/layout/dark-mode-toggle/theme-context';
import Layout from './components/layout/layout';
import NotFound from './components/layout/not-found';
import AlterarSenha from './views/login/alterar-senha';
import { AuthProvider } from './views/login/auth/auth-context';
import ConfirmarEmail from './views/login/confirmar-email';
import Login from './views/login/login';
import RecuperarSenha from './views/login/recuperar-senha';
import Registrar from './views/login/registrar';
import { NotificacaoProvider } from './views/fundacao/notificacao/notificacao-context';

const App = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ThemeProvider>
          <CrudProvider>
            <NotificacaoProvider>
              <BreadcrumbsProvider>
                <Routes>
                  <Route path='/' element={<Login />}></Route>
                  <Route path='/login' element={<Login />}></Route>
                  <Route path='/registrar' element={<Registrar />}></Route>
                  <Route path='/confirmar-email' element={<ConfirmarEmail />}></Route>
                  <Route path='/recuperar-senha' element={<RecuperarSenha />}></Route>
                  <Route path='/alterar-senha' element={<AlterarSenha />}></Route>
                  <Route path='/not-found' element={<NotFound />} />
                  <Route
                    path='*'
                    element={
                      <Layout>
                        <AppRoutes />
                      </Layout>
                    }
                  />
                </Routes>
              </BreadcrumbsProvider>
            </NotificacaoProvider>
          </CrudProvider>
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
