import { createContext, useContext, useEffect, useState } from 'react';

interface ThemeContextData {
  isDarkMode: boolean;
  updateIsDarkMode: (isDark: boolean) => void;
}

const ThemeContext = createContext<ThemeContextData | undefined>(undefined);

export const ThemeProvider: React.FC = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(() => {
    // Verifica se há uma preferência de tema no localStorage
    const storedTheme = localStorage.getItem('isDarkMode');
    return storedTheme ? JSON.parse(storedTheme) : false;
  });

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [isDarkMode]);

  const updateIsDarkMode = (value: boolean) => {
    if (value !== isDarkMode) {
      localStorage.setItem('isDarkMode', JSON.stringify(value));
      setIsDarkMode(value);
    }
  };

  return (
    <ThemeContext.Provider
      value={{
        isDarkMode,
        updateIsDarkMode,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext deve ser usado dentro de um ThemeProvider');
  }
  return context;
};
