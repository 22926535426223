export const getUischemaElements = (root: any): any[] => {
  let result: any[] = [];

  if (!Array.isArray(root)) {
    if (root?.elements) {
      return getUischemaElements(root.elements);
    }
    return [];
  }

  for (const element of root) {
    if (element?.elements) {
      result = result.concat(getUischemaElements(element.elements));
    } else {
      result.push(element);
    }
  }

  return result;
};
