import { useEffect, useState } from 'react';
import { makePropostaConviteMembroApi } from '../../api/proposta-convite-membro-api';
import { useCrudContext } from '../../components/crud/crud-context';
import PropostaConviteMembros from '../../components/proposta-convite-membros/proposta-convite-membros';
import { PropostaConviteMembro, TipoSituacao } from '../../models/proposta-convite-membro';
import {
  ComponentBox,
  ConvitesBox,
  Header,
  QuantidadeConvitesLabel,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledDiversity3,
  StyledExpandMoreIcon,
  WidthFull,
} from './style/todos-convites';

function TodosConvites() {
  const { setCurrentTitle } = useCrudContext();
  setCurrentTitle('Convites');

  const [apiPropostaResult, setApiPropostaResult] = useState<{
    count: number;
    data: PropostaConviteMembro[];
  }>();
  const [load, setLoad] = useState<boolean>(true);

  const propostaConviteMembroApi = makePropostaConviteMembroApi();

  useEffect(() => {
    loadAvisos();
  }, []);

  async function loadAvisos() {
    propostaConviteMembroApi
      .getAllReceived({
        situacao: TipoSituacao.pendente,
      })
      .then((resultData) => {
        setApiPropostaResult(resultData);
        setLoad(false);
      });
  }

  return (
    <ComponentBox>
      <Header>
        <h3>Convites</h3>
      </Header>
      <WidthFull>
        <ConvitesBox>
          <StyledAccordion defaultExpanded>
            <StyledAccordionSummary
              expandIcon={<StyledExpandMoreIcon />}
              aria-controls='convitesAccordion'
              id='convitesAccordion-header'
            >
              <StyledDiversity3 />
              Participação de Projetos
              <QuantidadeConvitesLabel align='center'>
                {apiPropostaResult ? `•  ${apiPropostaResult.count} Pendentes` : ``}
              </QuantidadeConvitesLabel>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <PropostaConviteMembros
                apiResultData={apiPropostaResult?.data}
                propostaConviteMembroApi={propostaConviteMembroApi}
                loadState={{ load, setLoad }}
                loadAvisos={loadAvisos}
              />
            </StyledAccordionDetails>
          </StyledAccordion>
        </ConvitesBox>
      </WidthFull>
    </ComponentBox>
  );
}

export default TodosConvites;
