import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import { cloneElement, ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { authStringsFor } from '../../../../helpers/auth-strings-for';
import { moduleNames } from '../../../../views';
import { useAuthContext } from '../../../../views/login/auth/auth-context';
import { Item } from '../nav-drawer';
import { NavItem } from '../nav-item';

import { NavCategoryLine, NavCategoryButton, NavIcon, SubItem } from './nav-category.styled';

export interface NavCategoryProps {
  title: string;
  icon: ReactNode;
  items: Item[];
  subCategory?: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  toggleDrawer: () => void;
}

export function NavCategory(props: NavCategoryProps) {
  const { title, icon, items, open, setOpen, toggleDrawer } = props;
  const [visible, setVisible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { permissions } = useAuthContext();
  const location = useLocation();

  const isActive = items.some((item) => {
    return location.pathname.split('/')[1] === item.route.split('/')[1];
  });

  const handleClick = () => {
    !open && toggleDrawer();
    setIsExpanded((prevState) => !prevState);
  };

  const mapListToElement = (list: Item[]) => {
    const listMap =
      open &&
      list
        .map((item, index) => {
          const moduleName = moduleNames.find((i) => i.id === item.route.substring(1))?.value;
          const hasPermission =
            item.hasPermission?.(moduleName, permissions) ??
            (authStringsFor(`${moduleName ?? ''}.EXIBIR_NO_MENU` ?? '').some((x) =>
              permissions.includes(x),
            ) &&
              authStringsFor(`${moduleName ?? ''}.BUSCAR_TODOS` ?? '').some((x) =>
                permissions.includes(x),
              ));
          if (!visible && hasPermission) setVisible(true);
          if (!hasPermission) return null;
          return (
            <NavItem
              key={index}
              route={item.route}
              state={item.state}
              icon={item.icon}
              title={item.title}
            />
          );
        })
        .filter((x) => x);
    return listMap;
  };

  const childrens = mapListToElement(items);

  if (!visible) return null;

  const activeColor = isActive ? '#017096' : '';

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <NavCategoryLine sx={{ backgroundColor: activeColor }} />
        <NavCategoryButton open={open} onClick={handleClick}>
          <NavIcon open={open}>
            {cloneElement(icon as any, {
              style: {
                color: activeColor,
              },
            })}
          </NavIcon>
          {open && (
            <ListItemText
              primary={title}
              primaryTypographyProps={{
                variant: 'h6',
                style: {
                  fontFamily: 'Raleway',
                  fontSize: '14px',
                  fontWeight: 'medium',
                  color: activeColor,
                },
              }}
              style={{
                whiteSpace: 'normal',
              }}
            />
          )}
          {open &&
            (isExpanded ? (
              <ExpandLess sx={{ color: activeColor }} />
            ) : (
              <ExpandMore sx={{ color: activeColor }} />
            ))}
        </NavCategoryButton>
      </Box>
      <SubItem in={isExpanded} timeout='auto' unmountOnExit>
        <Box sx={{ display: 'block', flexDirection: 'column' }}>{childrens}</Box>
      </SubItem>
    </>
  );
}
