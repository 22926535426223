import { rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Hidden from '@mui/material/Hidden';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import { ChangeEvent, useEffect, useState } from 'react';
import { useCrudContext } from '../../components/crud/crud-context';
import { getFirstError } from '../../utils/get-first-error';
import { IControlElement } from '../uischema';
import './renderer.css';

const moneyTester = rankWith(5, uiTypeIs('MonetaryInput'));

export const moneyRender = {
  tester: moneyTester,
  renderer: withJsonFormsControlProps(({ visible = true, schema, enabled, ...props }) => {
    const uischema = props.uischema as IControlElement;
    const ctx = useCrudContext();
    const { validationMode, formData, crudStates, disabledFields, isRequiredField } = ctx;

    const [value, setValue] = useState('');
    const [maxLength, setMaxLength] = useState<number | null>(null);

    useEffect(() => {
      if (schema?.maxLength) {
        setMaxLength(schema?.maxLength);
      }

      if (props.data) {
        setValue(formatReal(props.data));
      } else setValue('');
    }, [props?.data]);

    const isDisabled: boolean =
      !enabled ||
      crudStates.view ||
      (crudStates.edit && uischema?.options?.onlyCreate) ||
      uischema.options?.disabled ||
      disabledFields.includes(props.path);

    const required = uischema.options?.required ?? false;

    const formatReal = (input: string) => {
      const numericValue = Number(input);
      const realValue = numericValue / 100;
      return realValue.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const originalCursorPosition = event.target.selectionStart ?? 0;
      const originalLength = event.target.value.length;

      const inputValue = event.target.value.replace(/[^0-9]/g, '');
      const formattedValue = formatReal(inputValue);
      setValue(formattedValue);
      props.handleChange(props?.path, Number(inputValue));

      const lengthDifference = formattedValue.length - originalLength;
      let newCursorPosition = originalCursorPosition + lengthDifference;
      newCursorPosition = Math.max(0, Math.min(newCursorPosition, formattedValue.length));

      if (uischema.options?.handleChange) {
        uischema.options?.handleChange?.(
          Number(inputValue),
          props.handleChange,
          formData,
          ctx,
          props.path,
        );
      }

      event.target.setSelectionRange(newCursorPosition, newCursorPosition);
    };

    return (
      <Hidden xsUp={!visible}>
        <div className='custom-input-container'>
          <TextField
            {...props}
            variant='filled'
            className={`input ${
              (isDisabled && 'disabled-field') ||
              (props.errors && validationMode === 'ValidateAndShow' && 'has-error')
            }`}
            label={props.label}
            required={isRequiredField(required, ctx)}
            disabled={isDisabled}
            defaultValue={props?.data ?? ''}
            value={value}
            onChange={handleChange}
            InputProps={
              {
                disableUnderline: true,
                maxLength: maxLength ?? undefined,
              } as Partial<OutlinedInputProps>
            }
          />
          {validationMode === 'ValidateAndShow' && props.errors && (
            <span className='error-message'>{getFirstError(props.errors)}</span>
          )}
        </div>
      </Hidden>
    );
  }),
};
