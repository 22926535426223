import { useNavigate, useParams } from 'react-router-dom';
import { Crud } from '../../../components/crud/crud';
import { Data, headCells } from './edicao-recurso-linha-de-acao.headcell';
import edicaoRecursoLinhaDeAcaoUiSchema from './edicao-recurso-linha-de-acao.uischema';
import { moduleNameFor } from '../../../helpers/module-name-for';
import { ClipboardPencil } from 'styled-icons/foundation';
import { useCrudContext } from '../../../components/crud/crud-context';

function EdicaoRecursoLinhaDeAcao() {
  const { edicaoLinhaDeAcaoId } = useParams();
  const edicaoLinhaDeAcaoIdNumber = Number(edicaoLinhaDeAcaoId);

  const defaultForm = {
    'tipoRecursoLinhaDeAcaoId-codigo': 'instrumentoJuridico',
    edicaoLinhaDeAcaoId: edicaoLinhaDeAcaoIdNumber,
    valor: 0,
  };

  const navigate = useNavigate();
  const { parameterFilters, setParameterFilters, clearForm } = useCrudContext();

  const ajusteEdicaoRecursoLinhaDeAcao = {
    hasPermission: ['EXIBIR_NO_MENU', 'BUSCAR_TODOS'],
    moduleName: moduleNameFor('historico-ajuste-edicao-recurso-linha-de-acao'),
    icon: <ClipboardPencil size='24' color='#009900' />,
    title: 'Ajuste de Recursos da Linha de Ação',
    handler: (row: any) => {
      setParameterFilters({
        ...parameterFilters,
        edicaoRecursoLinhaDeAcao: row,
      });
      clearForm();
      navigate(
        `/edicao-recurso-linha-de-acao/${row.id}/historico-ajuste-edicao-recurso-linha-de-acao`,
      );
    },
  };

  return (
    <Crud<Data>
      headCells={headCells}
      uischema={edicaoRecursoLinhaDeAcaoUiSchema}
      titleConfig={{ value: 'Recursos da Linha de Ação', show: true }}
      apiUrl={'/edicao-recurso-linha-de-acao'}
      defaultForm={defaultForm}
      queryFilters={{ edicaoLinhaDeAcaoId: edicaoLinhaDeAcaoIdNumber }}
      hideDelete
      hideUpdate
      customActions={[() => ajusteEdicaoRecursoLinhaDeAcao]}
    ></Crud>
  );
}

export default EdicaoRecursoLinhaDeAcao;

export const rootViews = ['edicao-linha-de-acao'];
