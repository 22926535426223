import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { Proposta } from '../../../models/proposta';

export const displayProperties = ['tituloDoProjeto'];

export const moduleLabel = 'Propostas';

export const moduleName = 'EDITAL.PROPOSTA.PROPOSTA';

export const headCells: readonly HeadCell<Proposta>[] = [
  {
    id: 'editalId',
    numeric: true,
    disablePadding: false,
    label: 'Edital',
    resource: {
      name: 'edital',
      target: 'nome',
    },
  },
  {
    id: 'tituloDoProjeto',
    numeric: false,
    disablePadding: false,
    label: 'Título do Projeto',
  },
  {
    id: 'criadoPorId',
    numeric: true,
    disablePadding: false,
    label: 'Coordenador',
    resource: {
      name: 'criadoPor',
      target: 'nome',
    },
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Criado em',
    dateFormat: 'DD/MM/YYYY',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Enviado em',
    dateFormat: 'DD/MM/YYYY',
  },
];
