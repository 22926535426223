import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeApi } from '../../../../api/generic-api';
import { CrudStatesOptions, useCrudContext } from '../../../../components/crud/crud-context';
import JsonForm from '../../../../components/crud/json-form';
import { IUiSchema } from '../../../../jsonforms/uischema';
import { TipoRespostaPerguntaAvaliacao } from '../../../../models/pergunta-avaliacao';

const PerguntaAvaliacaoDetalhes = () => {
  const { perguntaAvaliacaoDetalhesId } = useParams();
  const [pergunta, setPergunta] = useState<any>(null);
  const perguntaId = Number(perguntaAvaliacaoDetalhesId ?? null) ?? null;

  const { updateCrudStates } = useCrudContext();

  let type: string = '';
  let properties = {};

  useEffect(() => {
    updateCrudStates(CrudStatesOptions.VIEW);
    makeApi('/pergunta-avaliacao')
      .get(perguntaId)
      .then((x) => {
        setPergunta(x);
      });
  }, []);

  switch (pergunta?.tipoResposta) {
    case TipoRespostaPerguntaAvaliacao.descritiva:
      type = 'Descritiva';
      properties = {
        max: pergunta?.descritiva?.maximo,
        min: pergunta?.descritiva?.minimo,
      };
      break;
    case TipoRespostaPerguntaAvaliacao.multiplaEscolha:
      type = 'MultiplaEscolha';
      properties = {
        oneOf: pergunta?.multiplaEscolha?.map(
          ({ alternativa, id }: { alternativa: string; id: number }) => ({
            const: id,
            title: alternativa,
          }),
        ),
      };
      break;
    default:
      type = 'Control';
  }

  const uischema: IUiSchema = {
    type: 'VerticalLayout',
    elements: [
      {
        type,
        scope: '#/properties/field',
        label: pergunta?.pergunta,
        options: {
          required: true,
        },
      },
    ],
  };

  const schema = {
    type: 'object',
    properties: {
      field: {
        type: 'string',
        ...properties,
      },
    },
  };

  return <JsonForm uischema={uischema} schema={schema} data={{}} />;
};

export default PerguntaAvaliacaoDetalhes;
