import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Box from '@mui/material/Box';

export type CkEditorSize = {
  width?: number;
  height?: number;
};

interface EditorProps {
  isDisabled?: boolean;
  placeholder?: string;
  initialValue?: string;
  size?: CkEditorSize;
  toolbar?: string[];
  onBlur: (editorData: string) => void;
}

const Editor: React.FC<EditorProps> = ({
  isDisabled,
  placeholder,
  initialValue,
  size,
  onBlur,
  toolbar,
}) => {
  const customConfig: any = {};
  if (toolbar) customConfig['toolbar'] = toolbar;

  return (
    <Box
      sx={{
        marginTop: '0.5rem',
        width: size?.width ? `${size?.width}px` : '100%',
        '& .ck-editor__editable_inline': {
          minHeight: size?.height ? `${size?.height}px` : '200px',
          height: size?.height ? `${size?.height}px` : '200px',
          width: size?.width ? `${size?.width}px` : '100%',
        },
      }}
    >
      <CKEditor
        disabled={isDisabled}
        editor={ClassicEditor}
        config={{
          language: 'pt-br',
          placeholder: placeholder,
          ...customConfig,
        }}
        data={initialValue ?? ''}
        onBlur={(event, editor) => {
          onBlur(editor?.getData?.());
        }}
      />
    </Box>
  );
};

export default Editor;
