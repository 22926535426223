import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { StatusInstrumentoJuridico } from '../../../models/status-instrumento-juridico';

export const displayProperties = ['nome'];

export const moduleName = 'ORCAMENTO.INSTRUMENTO_JURIDICO.STATUS_INSTRUMENTO_JURIDICO';

export const moduleLabel = 'Status do Instrumento Jurídico';

export const headCells: readonly HeadCell<StatusInstrumentoJuridico>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'descricao',
    numeric: false,
    disablePadding: false,
    label: 'Descrição',
  },
  {
    id: 'codigo',
    numeric: false,
    disablePadding: false,
    label: 'Código',
  },
];
