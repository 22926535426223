import { rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Hidden from '@mui/material/Hidden';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import { ChangeEvent, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useCrudContext } from '../../components/crud/crud-context';
import { getFirstError } from '../../utils/get-first-error';
import { IControlElement } from '../uischema';
import './renderer.css';

const longTextRenderTester = rankWith(5, uiTypeIs('LongText'));

export const longTextRender = {
  tester: longTextRenderTester,
  renderer: withJsonFormsControlProps(({ visible = true, path, schema, enabled, ...props }) => {
    const uischema = props.uischema as IControlElement;
    const fieldRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const position = useRef({
      beforeStart: 0,
      beforeEnd: 0,
    });

    const [value, setValue] = useState<string>(props.data ?? '');
    const [maxLength, setMaxLength] = useState<number | null>(null);
    const [minLength, setMinLength] = useState<number | null>(null);
    const { validationMode, crudStates, disabledFields } = useCrudContext();

    // keep cursor position after setting value
    useLayoutEffect(() => {
      inputRef?.current?.setSelectionRange(
        position.current.beforeStart,
        position.current.beforeEnd,
      );
    }, [value]);

    useEffect(() => {
      if (schema?.maxLength) {
        setMaxLength(schema?.maxLength);
      }
      if (schema?.minLength) {
        setMinLength(schema?.minLength);
      }
    }, []);

    useEffect(() => {
      fieldRef?.current?.childNodes?.forEach?.((childNode) => {
        const node = childNode as HTMLElement;
        const htmlInput = node.children?.[1]?.children?.[0];
        if (
          childNode.nodeType === 1 &&
          (node.tagName === 'INPUT' || htmlInput?.tagName === 'INPUT')
        ) {
          const inputElement = childNode as HTMLInputElement;
          if (inputElement && props.data !== undefined) {
            inputElement.value = props.data;
          } else if (inputElement) {
            inputElement.value = '';
          }
        }
      });
    }, [props.data]);

    const isDisabled =
      !enabled ||
      crudStates.view ||
      (crudStates.edit && uischema?.options?.onlyCreate) ||
      uischema?.options?.disabled ||
      disabledFields.includes(path);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const beforeStart = event.target.selectionStart ?? 0;
      const beforeEnd = event.target.selectionEnd ?? 0;
      position.current = {
        beforeStart,
        beforeEnd,
      };

      const val = event?.target?.value;
      setValue(val);
      props.handleChange(path, val);
    };

    return (
      <Hidden xsUp={!visible}>
        <div className='custom-input-container' ref={fieldRef}>
          <TextField
            multiline
            variant='filled'
            className={`multiline-input
              ${isDisabled ? 'disabled-field' : ''}
              ${props.errors && validationMode === 'ValidateAndShow' ? 'has-error' : ''}
            `}
            label={props.label}
            required={props.required}
            disabled={isDisabled}
            value={value}
            onChange={handleChange}
            minRows={4}
            InputProps={
              {
                disableUnderline: true,
                maxLength: maxLength ?? undefined,
                minLength: minLength ?? undefined,
              } as Partial<OutlinedInputProps>
            }
          />
          {validationMode === 'ValidateAndShow' && props.errors && (
            <span className='error-message'>{getFirstError(props.errors)}</span>
          )}
        </div>
      </Hidden>
    );
  }),
};
