import { CrudContextData } from '../components/crud/crud-context';
import { makeErrorsManager } from '../components/crud/errors-manager';
import { getNestedValues } from '../helpers/get-nested-values';
import { scopeToPath } from '../helpers/scope-to-path';
import { IStepCallback } from '../jsonforms/uischema/uischema.type';

export const stepHandlerErrors = async (
  fieldsConfig: { paths: string[]; instancePaths: string[]; requiredScopes: string[] },
  ctx: CrudContextData,
  data: any,
  url: string,
  callback?: IStepCallback,
): Promise<boolean> => {
  const { errorsJsonForms, setValidationMode, showError } = ctx;

  const { paths, instancePaths, requiredScopes } = fieldsConfig;

  const requiredFields = requiredScopes?.map?.((scope) => scopeToPath(scope));
  for (const requiredField of requiredFields) {
    const value = getNestedValues(data, requiredField);
    if (!value) {
      const field = requiredField.split('.').pop();
      showError(`O campo ${field} é obrigatório!`);
      return false;
    }
  }
  const jsonFormErrors = errorsJsonForms
    ?.map((error: any) => {
      if (requiredFields?.includes?.(error?.params?.missingProperty)) {
        return error;
      }
      if (instancePaths?.includes?.(error?.instancePath)) {
        return error;
      }
    })
    .filter((error: any) => error !== undefined);

  const errorsManager = makeErrorsManager(ctx);
  const { addCustomErrors } = errorsManager;
  const additionalErrorsLocal = addCustomErrors();

  const hasCustomError = !!additionalErrorsLocal?.find?.(
    (error: any) => paths?.includes?.(error?.propertyName),
  );

  if (jsonFormErrors.length || hasCustomError) {
    setValidationMode('ValidateAndShow');
    return false;
  }

  if (callback) {
    return await callback?.(ctx);
  }

  return true;
};
