import { rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useEffect, useState } from 'react';
import { Trash3 } from 'styled-icons/bootstrap';
import { MailArrowUp } from 'styled-icons/fluentui-system-filled';
import { makeApi } from '../../api/generic-api';
import { makePropostaConviteMembroApi } from '../../api/proposta-convite-membro-api';
import { Severity } from '../../components/crud/alert-custom';
import { useCrudContext } from '../../components/crud/crud-context';
import CustomMultipleUserSelect from '../../components/custom-multiple-user-select/custom-multiple-user-select';
import { moduleNameFor } from '../../helpers/module-name-for';
import {
  headCells,
  moduleLabel,
  moduleName,
} from '../../views/edital/proposta/proposta-convite-membros/proposta-convite-membros.headcell';
import { Usuario } from '../../models/usuario';
import { PropostaConviteMembro, TipoSituacao } from '../../models/proposta-convite-membro';
import Hidden from '@mui/material/Hidden';

const CustomMultipleUserSelectTester = rankWith(5, uiTypeIs('CustomMultipleUserSelect'));

export const CustomMultipleUserSelectRenderer = {
  tester: CustomMultipleUserSelectTester,
  renderer: withJsonFormsControlProps(
    ({ schema, uischema, data, path, visible, enabled, ...props }) => {
      const { formData } = useCrudContext();

      const propostaId = formData.id;

      const apiSearchInstance = makeApi('/usuario');
      const apiCreateInstance = makePropostaConviteMembroApi();

      const [apiListData, setApiListData] = useState([]);
      const [usuarios, setUsuarios] = useState<Usuario[]>([]);
      const [selectedUsuarios, setSelectedUsuarios] = useState<Usuario[]>([]);
      const [filters, setFilters] = useState<any>({ propostaId: propostaId });
      const [openAlert, setOpenAlert] = useState(false);
      const [severityAlert, setSeverityAlert] = useState<Severity>(Severity.SUCCESS);
      const [messageAlert, setMessageAlert] = useState<string>('Permissões salvas com sucesso!');

      const onTextChange = (searchText: string) => {
        setFilters({ propostaId: propostaId, nomeUsuario: searchText });
      };

      const onCreateParticipants = () => {
        setFilters({ propostaId: propostaId });
      };

      const onExecuteAction = async () => {
        apiCreateInstance
          ?.getAll?.(filters)
          .then((data: PropostaConviteMembro[]) => {
            setApiListData(data);
          })
          .catch((e) => {
            showError(e?.cause?.response?.data?.message?.[0] || 'Ocorreu um erro.');
          });
      };

      const showSuccess = (message: string) => {
        setMessageAlert(message);
        setSeverityAlert(Severity.SUCCESS);
        setOpenAlert(true);
        setTimeout(() => setOpenAlert(false), 3000);
      };

      const showError = (message: string) => {
        setMessageAlert(message);
        setSeverityAlert(Severity.ERROR);
        setOpenAlert(true);
        setTimeout(() => setOpenAlert(false), 3000);
      };

      const handleAdd = () => {
        const fetchData = async () => {
          const createData = selectedUsuarios.map((usuario: Usuario) => {
            return { propostaId: propostaId, usuarioId: usuario.id };
          });
          apiCreateInstance
            .post({ propostaConviteMembros: createData })
            .then(() => {
              showSuccess('Participante(s) adicionado(s) com sucesso!');
              onCreateParticipants();
              setSelectedUsuarios([]);
              setUsuarios([]);
            })
            .catch((e) => {
              showError(e?.cause?.response?.data?.message?.[0] || 'Ocorreu um erro.');
            });
        };
        fetchData();
      };

      useEffect(() => {
        apiCreateInstance
          ?.getAll?.(filters)
          .then((data: PropostaConviteMembro[]) => {
            setApiListData(data);
          })
          .catch((e) => {
            showError(e?.cause?.response?.data?.message?.[0] || 'Ocorreu um erro.');
          });
      }, [filters]);

      const removerMembro = {
        hasPermission: [],
        moduleName: moduleNameFor('convite-participantes'),
        icon: <Trash3 size={20} style={{ color: 'red' }} />,
        title: 'Remover Membro',
        handler: (row: PropostaConviteMembro) => {
          removerMembroFunc(row.id);
        },
      };

      const removerMembroFunc = async (id: number) => {
        apiCreateInstance
          .remover(id)
          .then(() => {
            showSuccess('Removido com Sucesso');
            onExecuteAction();
          })
          .catch((e) => {
            showError(e?.cause?.response?.data?.message?.[0] || 'Ocorreu um erro.');
          });
      };

      const showRemoverMembro = (row: PropostaConviteMembro) => {
        if (
          (row.situacao == TipoSituacao.pendente || row.situacao == TipoSituacao.aceito) &&
          enabled
        )
          return removerMembro;
      };

      const reenviarConvite = {
        hasPermission: [],
        moduleName: moduleNameFor('convite-participantes'),
        icon: <MailArrowUp size={20} style={{ color: 'green' }} />,
        title: 'Reenviar Convite',
        handler: (row: PropostaConviteMembro) => {
          reenviarConviteFunc(row.id);
        },
      };

      const reenviarConviteFunc = async (id: number) => {
        apiCreateInstance
          .reenviar(id)
          .then(() => {
            showSuccess('Reenviado com Sucesso');
            onExecuteAction();
          })
          .catch((e) => {
            showError(e?.cause?.response?.data?.message?.[0] || 'Ocorreu um erro.');
          });
      };

      const showReenviarConvite = (row: PropostaConviteMembro) => {
        if (
          (row.situacao == TipoSituacao.recusado || row.situacao == TipoSituacao.removido) &&
          enabled
        )
          return reenviarConvite;
      };

      return (
        <Hidden xsUp={!visible}>
          <CustomMultipleUserSelect
            headCells={headCells}
            moduleName={moduleName}
            moduleLabel={moduleLabel}
            apiSearchInstance={apiSearchInstance}
            apiListData={apiListData}
            customActions={[showRemoverMembro, showReenviarConvite]}
            onTextChange={onTextChange}
            onCreateParticipants={onCreateParticipants}
            handleAdd={handleAdd}
            usuariosState={{ usuarios, setUsuarios }}
            selectedUsuariosState={{ selectedUsuarios, setSelectedUsuarios }}
            dialogProps={{
              openDialog: false,
              handleCloseDelete: () => {},
              destroy: async () => {},
            }}
            alertCustom={{ openAlert, severityAlert, messageAlert }}
            hasSecure={false}
            enabled={enabled}
          ></CustomMultipleUserSelect>
        </Hidden>
      );
    },
  ),
};
