import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { Moeda } from '../../../models/moeda';

export const displayProperties = ['nome'];

export const moduleName = 'CONFIGURACOES_INICIAIS.MOEDA';

export const moduleLabel = 'Moedas';

export const headCells: readonly HeadCell<Moeda>[] = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'simbolo',
    numeric: false,
    disablePadding: false,
    label: 'Símbolo',
  },
  {
    id: 'sigla',
    numeric: false,
    disablePadding: false,
    label: 'Sigla',
  },
];
