import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { FluentDocumentFolder24Filled } from '../../../validity-checker/fluent-document-folder-24-filled';

export const ComponentBox = styled(Box)({
  width: '100%',
  maxWidth: 826,
  fontFamily: 'Raleway',
  backgroundColor: 'white !important',
});

export const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  paddingTop: '25px',
  paddingBottom: '40px',
});

export const EditalIcon = styled(FluentDocumentFolder24Filled)({
  marginLeft: '20px',
  width: '32px',
  height: '32px',
  color: '#003D92',
});

export const EditalHeaderLabel = styled(Typography)({
  fontSize: '22px',
  fontWeight: 600,
  marginLeft: '10px',
  fontFamily: 'Raleway',
  backgroundColor: 'white !important',
  '&:hover': {
    backgroundColor: 'white !important',
  },
});

export const EditalList = styled(Box)({
  backgroundColor: 'white !important',
  color: '#434653 !important',
  fontWeight: 600,
  '&:hover': {
    backgroundColor: 'white !important',
  },
});

export const WhiteBackgroundList = styled(List)({
  backgroundColor: 'white !important',
  '&:hover': {
    backgroundColor: 'white !important',
  },
});

export const WhiteBackgroundListItem = styled(ListItem)({
  backgroundColor: 'white !important',
  '&:hover': {
    backgroundColor: 'white !important',
  },
});

export const WhiteBackgroundListItemButton = styled(ListItemButton)({
  backgroundColor: 'white !important',
  '&:hover': {
    backgroundColor: 'white !important',
  },
});

export const FooterBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row-reverse',
  paddingTop: '16px',
  paddingBottom: '24px',
  paddingRight: '32px',
});

export const FooterButton = styled(Typography)({
  fontSize: '16px',
  color: '#003D92 !important',
  fontWeight: 600,
  fontFamily: 'Raleway',
  backgroundColor: 'white !important',
});

export const EditalEmpty = styled(Typography)({
  marginLeft: '20px',
  backgroundColor: 'white !important',
});

export const TodosEditaisAnchor = styled('a')({
  cursor: 'pointer',
});
