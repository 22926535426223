import { Eye } from '@styled-icons/entypo/Eye';
import { useNavigate } from 'react-router-dom';
import { Crud } from '../../../components/crud/crud';
import { moduleNameFor } from '../../../helpers/module-name-for';
import { headCells } from './pergunta-avaliacao.headcell';
import perguntaAvaliacaoUiSchema from './pergunta-avaliacao.uischema';
import { GenericApi, makeApi } from '../../../api/generic-api';
import { useEffect, useState } from 'react';
import { PerguntaAvaliacao } from '../../../models/pergunta-avaliacao';

const defaultForm = {
  obrigatoria: false,
  descritiva: {
    minimo: 0,
    maximo: 0,
  },
  nota: {
    notaMinima: 0,
    notaMaxima: 0,
  },
};

function Pergunta() {
  const [api, setApi] = useState<GenericApi<PerguntaAvaliacao>>();

  const navigate = useNavigate();

  const perguntaAvaliacaoDetalhes = {
    hasPermission: ['BUSCAR_POR_ID'],
    moduleName: moduleNameFor('pergunta-avaliacao'),
    icon: <Eye size='24' />,
    title: 'Visualizar',
    handler: (row: any) => {
      navigate(`/pergunta-avaliacao-detalhes/${row?.id}`);
    },
  };

  useEffect(() => {
    setApi(
      makeApi('/pergunta-avaliacao', {
        orderRelationBy: { multiplaEscolha: (a, b) => a.id - b.id },
      }),
    );
  }, []);

  return (
    <Crud<PerguntaAvaliacao>
      headCells={headCells}
      titleConfig={{ value: 'Perguntas de Avaliação', show: true }}
      queryFilters={{ withDeleted: true }}
      uischema={perguntaAvaliacaoUiSchema}
      apiUrl={'/pergunta-avaliacao'}
      apiClient={api}
      defaultForm={defaultForm}
      hideView
      customActions={[() => perguntaAvaliacaoDetalhes]}
      width={500}
    ></Crud>
  );
}

export default Pergunta;
