import styled from '@emotion/styled';
import ListItemButton from '@mui/material/ListItemButton';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

export const MultipleChoiceContainer = styled(Box)({
  fontFamily: 'Raleway',
  border: '1px solid',
  borderRadius: '4px',
  marginBottom: '0.5rem',
  paddingRight: '0.25rem',
  backgroundColor: '#FFF',
  '& input, & button, & p': {
    fontFamily: 'inherit',
    fontSize: '0.75rem',
    backgroundColor: 'transparent !important',
  },
});

export const QuestionContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  padding: '0.75rem 0.5rem 0.5rem 0.5rem',
});

export const QuestionText = styled(Typography)({
  fontWeight: '600',
  marginRight: '0.125rem',
});

export const WarningText = styled(Typography)({
  fontFamily: 'Raleway',
  fontStyle: 'italic',
  fontSize: '10px !important',
  fontWeight: 500,
  lineHeight: '1.2rem',
  letterSpacing: '0.5px',
  marginRight: '2px',
});

export const RequiredSpan = styled(Typography)({
  fontSize: '1rem !important',
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif !important',
});

export const ChoiceContainer = styled(List)({
  backgroundColor: 'transparent !important',
  padding: '0 0.5rem',
  maxHeight: '13.5rem',
  overflow: 'hidden',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    marginBottom: '0.1rem',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
    margin: '2px',
    height: '10px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
});

export const ChoiceItem = styled(ListItemButton)({
  padding: '0.75rem 0.25rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const ChoiceCheckBox = styled(Checkbox)({ padding: 0 });

export const ChoiceText = styled(Typography)({
  wordBreak: 'break-word',
  fontWeight: '500',
});

export const ErrorText = styled(Typography)({
  fontFamily: 'Raleway',
  fontSize: '0.75rem',
  fontWeight: '500',
  color: '#BA1A1A !important',
});
