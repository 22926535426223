import { JsonSchema } from '@jsonforms/core';
import client from '../config/axios-config';
import { AxiosResponse } from 'axios';
import { camelCase } from 'change-case';

export interface FormApi<T = any> {
  get url(): string;
  getSchema: () => Promise<JsonSchema | undefined>;
  get: (id: number) => Promise<any>;
  getAll: (options?: { [key: string]: any }) => Promise<any>;
  getAllForPagination: (options?: { [key: string]: any }) => Promise<any>;
  put: (id: number, data: any) => Promise<any>;
  post: (data: any) => Promise<any>;
  delete: (id: number) => Promise<any>;
  restore: (id: number) => Promise<any>;
}

type OrderFunction<T> = (a: T, b: T) => number;
type ElementType<T> = T extends (infer U)[] ? U : T;
type OrderBy<T> = {
  [K in keyof T as `order${Capitalize<string & K>}By`]?: OrderFunction<ElementType<T[K]>>;
};
type OrderRelationBy<T> = {
  [key in keyof T]?: OrderTypeBy<ElementType<T[key]>>;
};
type OrderTypeBy<T> = OrderBy<T> & OrderRelationBy<T>;

export const isSortFunction = <T>(obj: any): obj is OrderFunction<T> => {
  return typeof obj === 'function';
};

export const isOrderRelation = <T>(obj: any): obj is OrderRelationBy<T> => {
  return typeof obj === 'object';
};

export const isOrderBy = (key: string): string | undefined => {
  if (key.startsWith('order') && key.endsWith('By') && key.length > 'orderBy'.length) {
    return camelCase(key.substring('order'.length, key.length - 'By'.length));
  }
};

const sortBy = <T>(obj: T, order: OrderTypeBy<T>) => {
  Object.entries(order).forEach(([key, value]) => {
    const isOrderByField = isOrderBy(key);
    const relation = isOrderByField ? obj[isOrderByField as keyof T] : obj[key as keyof T];
    if (Array.isArray(relation) && isSortFunction(value))
      obj[key as keyof T] = relation.sort(value);
    if (relation && isOrderRelation(value)) sortBy(relation, value);
  });
  return obj;
};

export interface IGenericApiOptions<T> {
  customPaths?: {
    [key: string]: string;
  };
  orderRelationBy?: OrderTypeBy<T>;
}

export class GenericApi<T> implements FormApi<T> {
  constructor(
    public readonly url: string,
    public readonly apiOptions?: IGenericApiOptions<T>,
  ) {}

  async getSchema(): Promise<JsonSchema | undefined> {
    try {
      const response = await client.get<JsonSchema>(`${this.url}/schema`);
      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('GetSchema error: ', { cause: error });
    }
  }

  async get(id: number): Promise<T> {
    try {
      const response = await client.get<T>(`${this.url}/${id}`);
      return sortBy(response.data, this.apiOptions?.orderRelationBy ?? {});
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Get error: ', { cause: error });
    }
  }

  async getAll(options: { [key: string]: any } = { withDeleted: false }): Promise<any> {
    return (await this.getAllForPagination(options)).data;
  }

  async getAllForPagination(
    options: { [key: string]: any } = { withDeleted: false },
  ): Promise<any> {
    try {
      const urlBuild = Object.entries(options).reduce(
        (acc, [chave, valor], i) => {
          return `${acc}${i === 0 ? '?' : '&'}${chave}=${String(valor) ?? ''}`;
        },
        `${this.url}${this.apiOptions?.customPaths?.['getAll'] ?? ''}`,
      );
      const response = await client.get<{ data: T[] }>(urlBuild);

      return response?.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('GetAll error: ', { cause: error });
    }
  }

  async restore(id: number): Promise<AxiosResponse<T, any>> {
    try {
      const response = await client.put<T>(`${this.url}/restore/${id}`);
      return response;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Restore error: ', { cause: error });
    }
  }

  async put(id: number, data: any): Promise<AxiosResponse<T, any>> {
    try {
      const response = await client.put<T>(
        `${this.url}/${id}${this.apiOptions?.customPaths?.['put'] ?? ''}`,
        data,
      );
      return response;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Put error: ', { cause: error });
    }
  }

  async post(data: any): Promise<AxiosResponse<T, any>> {
    try {
      const response = await client.post<T>(`${this.url}`, data);
      return response;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Post error: ', { cause: error });
    }
  }

  async delete(id: number): Promise<AxiosResponse<T, any>> {
    try {
      const response = await client.delete<T>(`${this.url}/${id}`);
      return response;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('Delete error: ', { cause: error });
    }
  }
}

export const makeApi = <T>(url: string, apiOptions?: IGenericApiOptions<T>) => {
  return new GenericApi<T>(url, apiOptions);
};
