import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { EyeOffOutline } from '@styled-icons/evaicons-outline/EyeOffOutline';
import { EyeOutline } from '@styled-icons/evaicons-outline/EyeOutline';
import { useState } from 'react';

type InputCustomProps = {
  value?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  name?: string;
  inputLabelProps?: any;
  inputProps?: any;
  type?: string;
  required?: boolean;
};

const Input = styled(TextField)(() => ({
  '& .MuiFilledInput-root': {
    overflow: 'hidden',
    fontFamily: 'Raleway',
    fontWeight: 600,
    borderRadius: 4,
    border: '1px solid',
    borderColor: '#2D3843',
    backgroundColor: 'transparent',
    '& .MuiInputBase-input': {
      color: '#052F54 !important',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&::before, &::after': {
      borderBottom: 'none',
    },
    '&:hover:not(.Mui-disabled, .Mui-error):before': {
      borderBottom: 'none',
    },
    '&.Mui-focused:after': {
      borderBottom: 'none',
    },
  },
}));

const IconPassword = (props: any) => {
  return (
    <InputAdornment position='end'>
      <IconButton onClick={props.togglePasswordVisibility}>
        {props.showPassword ? (
          <EyeOffOutline size={28} color={'#9F9F9F'} />
        ) : (
          <EyeOutline size={28} color={'#9F9F9F'} />
        )}
      </IconButton>
    </InputAdornment>
  );
};

const LabelText = (props: any) => {
  return <span style={{ color: '#939495', fontFamily: 'Raleway' }}>{props.text}</span>;
};

const InputCustom = (props: InputCustomProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const setType = () => {
    if (props.type === 'password') {
      if (showPassword) return 'text';
      return 'password';
    }
    if (props.type === 'date') return 'date';
    return 'text';
  };

  const getInputLabelProps = () => {
    return props.type === 'date'
      ? {
          shrink: true,
          style: { color: 'red' },
        }
      : { style: { color: 'red' } };
  };

  const getInputProps = () => {
    if (props.type === 'password') {
      return {
        endAdornment: (
          <IconPassword
            showPassword={showPassword}
            togglePasswordVisibility={() => setShowPassword(!showPassword)}
          />
        ),
      };
    }
    return {};
  };

  return (
    <>
      <Input
        fullWidth
        type={setType()}
        variant='filled'
        id={props.label}
        name={props.name}
        value={props.value}
        required={props.required}
        onChange={props.onChange}
        InputProps={getInputProps()}
        InputLabelProps={getInputLabelProps()}
        label={<LabelText text={props.label} />}
      />
    </>
  );
};
export default InputCustom;
