import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Step, { StepProps } from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Home } from '@styled-icons/fluentui-system-filled/Home';
import { Person } from '@styled-icons/fluentui-system-filled/Person';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GenericApi, makeApi } from '../../api/generic-api';
import AlertCustom from '../../components/crud/alert-custom';
import { useCrudContext } from '../../components/crud/crud-context';
import ButtonCustom from '../../components/custom-input/custom-button';
import InputCustom from '../../components/custom-input/custom-input';
import LogoFundacao from '../../components/layout/logo-fundacao';
import { makeTermoPoliticaPrivacidadeApi } from '../fundacao/termo-politica-privacidade/termo-politica-privacidade-api';
import {
  Background,
  DivGroup,
  LoginContainer,
  LoginForm,
  LogoRegister,
  Paper,
  CustomDatePicker,
} from './login.styled';

interface LoginFormState {
  nome: string;
  nomeSocial?: string;
  dataNascimento?: Date | null;
  email: string;
  senha: string;
  senhaConfirmar: string;
}

const Registrar = () => {
  const navigate = useNavigate();
  const [api, setApi] = useState<GenericApi<any>>();
  const { openAlert, messageAlert, severityAlert, showError, showSuccess } = useCrudContext();
  const [formData, setFormData] = useState<LoginFormState>({
    nome: '',
    nomeSocial: '',
    dataNascimento: null,
    email: '',
    senha: '',
    senhaConfirmar: '',
  });
  const [termoPoliticaPrivacidade, setTermoPoliticaPrivacidadeApi] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Informações Pessoais', 'Aceite de Termos'];
  const [isEndOfScroll, setIsEndOfScroll] = useState(false);
  const [checked, setChecked] = useState(false);

  const [senhaValida, setSenhaValida] = useState<boolean>(true);
  const [senhaErro, setSenhaErro] = useState<string>('');
  const [senhaConfirmarValida, setSenhaConfirmarValida] = useState<boolean>(true);
  const [senhaConfirmarErro, setSenhaConfirmarErro] = useState<string>('');

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState<boolean>(false);

  useEffect(() => {
    const usuarioApi = makeApi('usuario');
    setApi(usuarioApi);
    const termoPoliticaPrivacidadeApi = makeTermoPoliticaPrivacidadeApi();
    termoPoliticaPrivacidadeApi
      ?.get?.()
      ?.then?.((res) => {
        if (!!res) {
          const text: string = res?.politicaDePrivacidade || '';
          setTermoPoliticaPrivacidadeApi(text);
          if (text.length < 1200) {
            setIsEndOfScroll(true);
          }
        }
      })
      ?.catch((e) => console.log(e));
  }, []);

  const handleScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setIsEndOfScroll(true);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleTogglePasswordConfirmVisibility = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeDataNascimento = (date: Date | null) => {
    setFormData({
      ...formData,
      dataNascimento: date,
    });
  };

  const validarSenha = (senha: string) => {
    const validations =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_=+{};:'",<.>\/?\\[\]`~])[\w!@#$%^&*()-_=+{};:'",<.>\/?\\[\]`~]{8,64}$/;
    if (!senha.match(validations)) {
      setSenhaValida(false);
      setSenhaErro(
        'A senha deve ter entre 8 e 64 caracteres, contendo pelo menos 1 letra minúscula, 1 maiúscula, 1 número e 1 caracter especial.',
      );
      return false;
    }
    setSenhaValida(true);
    setSenhaErro('');
    return true;
  };

  const validarSenhaConfirmar = (senhaConfirmar: string, senha: string) => {
    if (senhaConfirmar !== senha) {
      setSenhaConfirmarValida(false);
      setSenhaConfirmarErro('A confirmação de senha não coincide com a senha.');
      return false;
    }
    setSenhaConfirmarValida(true);
    setSenhaConfirmarErro('');
    return true;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const senhaValida = validarSenha(formData.senha);
    const senhaConfirmarValida = validarSenhaConfirmar(formData.senhaConfirmar, formData.senha);

    if (!senhaValida || !senhaConfirmarValida) {
      showError('Por favor, corrija os erros nos campos de senha.');
      return;
    }

    if (formData.senha !== formData.senhaConfirmar) {
      showError('A senha e a confirmação da senha não conferem.');
      return;
    }

    if (checked && activeStep > 0) {
      api
        ?.post?.(formData)
        ?.then?.((res) => {
          if (res?.status === 201) {
            showSuccess('Conta criada com sucesso.');
            navigate('/login');
          }
        })
        .catch((err: any) => {
          showError(
            err?.cause?.response?.data?.message?.[0] ||
              err?.response?.data?.message?.[0] ||
              'Erro ao criar usuário.',
          );
          console.error('Erro ao criar usuário:', err?.cause);
        });
    }

    if (steps.length > activeStep + 1) setActiveStep(activeStep + 1);
  };

  function IconStepByStep(props: StepProps) {
    const icons: { [index: string]: React.ReactElement } = {
      1: <Person size={28} color='#ebf0f4' />,
      2: <Home size={28} color='#ebf0f4' />,
    };
    let sx;
    if (String(activeStep + 1) == String(props.icon)) {
      sx = { backgroundColor: '#1351b4' };
    } else {
      sx = { backgroundColor: '#d9d9d9' };
    }

    return (
      <Box sx={{ ...sx, padding: '10px', borderRadius: '100%' }}>{icons[String(props.icon)]}</Box>
    );
  }

  return (
    <DivGroup>
      <Background />

      <LoginContainer>
        <CssBaseline />
        <Paper>
          <LogoRegister as={LogoFundacao} />
          <Button onClick={() => navigate('/login')}>
            <ArrowBackIcon />
            Voltar
          </Button>
          <LoginForm onSubmit={handleSubmit}>
            <Stepper nonLinear activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepButton color='inherit' onClick={() => setActiveStep(index)}>
                    <StepLabel StepIconComponent={IconStepByStep}>
                      <Typography
                        sx={{
                          fontFamily: 'Raleway',
                          fontSize: '16px',
                          fontWeight: 600,
                          width: 100,
                          textAlign: 'left',
                          lineHeight: 1.2,
                          color: '#454545',
                        }}
                      >
                        {label}
                      </Typography>
                    </StepLabel>
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            {activeStep === 0 && (
              <Grid>
                <Typography
                  component='h1'
                  variant='h5'
                  style={{
                    color: '#191B22',
                    fontWeight: '600',
                    fontFamily: 'Raleway',
                    fontSize: '22px',
                    marginBottom: '1rem',
                    marginTop: '1.5rem',
                  }}
                >
                  Informações Pessoais
                </Typography>
                <InputCustom
                  required
                  label='Nome completo'
                  name='nome'
                  value={formData.nome}
                  onChange={handleChange}
                />
                <InputCustom
                  label='Nome Social'
                  name='nomeSocial'
                  value={formData.nomeSocial}
                  onChange={handleChange}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                  <CustomDatePicker>
                    <DatePicker
                      label='Data de Nascimento'
                      value={formData.dataNascimento}
                      onChange={handleChangeDataNascimento}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          variant: 'filled',
                          required: true,
                        },
                      }}
                      format='DD/MM/YYYY'
                    />
                  </CustomDatePicker>
                </LocalizationProvider>
                <InputCustom
                  required
                  label='Email'
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                />
                <TextField
                  required
                  label='Senha'
                  name='senha'
                  type={showPassword ? 'text' : 'password'}
                  variant='filled'
                  value={formData.senha}
                  onChange={handleChange}
                  error={!senhaValida}
                  helperText={senhaErro}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          sx={{
                            color: !senhaValida ? '#BA1A1A' : '#2D3843',
                          }}
                        >
                          {showPassword ? (
                            <VisibilityOffOutlinedIcon />
                          ) : (
                            <VisibilityOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  style={{ width: '100%' }}
                  sx={{
                    '& .MuiFilledInput-root': {
                      backgroundColor: 'transparent',
                      border: '1px solid',
                      borderColor: !senhaValida ? '#BA1A1A' : '#2D3843',
                      borderRadius: '4px',
                    },
                    '& .Mui-focused': {
                      backgroundColor: 'transparent',
                      borderColor: !senhaValida ? '#BA1A1A' : '#2D3843',
                    },
                    '& .Mui-error': {
                      borderColor: '#BA1A1A',
                    },
                  }}
                  FormHelperTextProps={{
                    sx: {
                      color: '#BA1A1A !important',
                      fontFamily: 'Raleway',
                      fontSize: '12px',
                    },
                  }}
                />
                <TextField
                  required
                  label='Confirmar Senha'
                  name='senhaConfirmar'
                  type={showPasswordConfirm ? 'text' : 'password'}
                  variant='filled'
                  value={formData.senhaConfirmar}
                  onChange={handleChange}
                  error={!senhaConfirmarValida}
                  helperText={senhaConfirmarErro}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={handleTogglePasswordConfirmVisibility}
                          sx={{
                            color: !senhaValida ? '#BA1A1A' : '#2D3843',
                          }}
                        >
                          {showPasswordConfirm ? (
                            <VisibilityOffOutlinedIcon />
                          ) : (
                            <VisibilityOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  style={{ width: '100%' }}
                  sx={{
                    '& .MuiFilledInput-root': {
                      backgroundColor: 'transparent',
                      border: '1px solid',
                      borderColor: !senhaValida ? '#BA1A1A' : '#2D3843',
                      borderRadius: '4px',
                    },
                    '& .Mui-focused': {
                      backgroundColor: 'transparent',
                      borderColor: !senhaValida ? '#BA1A1A' : '#2D3843',
                    },
                    '& .Mui-error': {
                      borderColor: '#BA1A1A',
                    },
                  }}
                  FormHelperTextProps={{
                    sx: {
                      color: '#BA1A1A !important',
                      fontFamily: 'Raleway',
                      fontSize: '12px',
                    },
                  }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                  <ButtonCustom
                    onClick={handleSubmit}
                    sx={{
                      backgroundColor: '#003D92',
                      width: '131px',
                      height: '40px',
                      marginTop: '0.75rem',
                    }}
                  >
                    Próximo
                  </ButtonCustom>
                </Box>
              </Grid>
            )}
            {activeStep === 1 && (
              <Grid>
                <Box
                  onScroll={handleScroll}
                  sx={{
                    minWidth: 550,
                    padding: '0px 15px',
                    height: 400,
                    overflowY: 'auto',
                    marginTop: 3,
                  }}
                >
                  <Typography
                    variant='h5'
                    sx={{
                      fontWeight: 600,
                      fontSize: '22px',
                      color: '#191B22',
                      fontFamily: 'Raleway',
                    }}
                  >
                    Termo de Política e Privacidade
                  </Typography>
                  <Typography variant='body1' sx={{ marginTop: 1 }}>
                    <div
                      style={{ wordWrap: 'break-word' }}
                      dangerouslySetInnerHTML={{ __html: String(termoPoliticaPrivacidade) }}
                    />
                  </Typography>
                </Box>
                <Box sx={{ margin: '10px 0px' }}>
                  <FormControlLabel
                    disabled={isEndOfScroll ? false : true}
                    control={<Checkbox checked={checked} onClick={() => setChecked(!checked)} />}
                    label='Li e aceitei a Política de Privacidade e os Termos de Uso'
                    sx={{
                      fontFamily: 'Raleway',
                      fontWeight: '400',
                      fontSize: '14px',
                      color: '#434653',
                      marginTop: '2rem',
                      marginBottom: '1.5rem',
                    }}
                  />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 4 }}>
                  <ButtonCustom
                    variant='outline'
                    onClick={() => navigate('/login')}
                    style={{
                      borderColor: '#737784',
                      width: '131px',
                      height: '40px',
                      fontFamily: 'Raleway',
                      fontSize: '16px',
                      color: '#737784',
                      padding: '0px',
                    }}
                  >
                    Não aceito
                  </ButtonCustom>
                  <ButtonCustom
                    type='submit'
                    disabled={!checked}
                    sx={{
                      width: '131px',
                      fontFamily: 'Raleway',
                      backgroundColor: !checked ? 'default' : '#003D92',
                      color: !checked ? 'default' : 'white',
                      '&:hover': {
                        backgroundColor: !checked ? 'default' : '#003D92',
                      },
                    }}
                  >
                    Finalizar
                  </ButtonCustom>
                </Box>
              </Grid>
            )}
          </LoginForm>
          <AlertCustom open={openAlert} message={messageAlert} severity={severityAlert} />
        </Paper>
      </LoginContainer>
    </DivGroup>
  );
};
export default Registrar;

export const disableAutoRoute = true;
