import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Divider, Grid } from '@mui/material';
import { DocumentText } from '@styled-icons/fluentui-system-filled/DocumentText';
import { AttachText } from '@styled-icons/fluentui-system-regular/AttachText';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makeEditalApi } from '../../../api/edital-api';
import { FileApi } from '../../../api/file-api';
import { makeApi } from '../../../api/generic-api';
import { Secure } from '../../../components/auth/secure';
import { CrudStatesOptions, useCrudContext } from '../../../components/crud/crud-context';
import CustomModal from '../../../components/layout/custom-modal';
import {
  BoxAnexoContent,
  BoxAnexoContentItem,
  BoxAnexoName,
  BoxAnexoNameItem,
  BoxContent,
  ButtomLabel,
  ButtonContent,
  ComponentBox,
  CustomButton,
  EmptyAnexoMsg,
  Header,
  PdfContent,
  ShowAnexoLabel,
  TextoEdital,
} from '../../../components/layout/style/edital/edital-detalhes';
import {
  BackButton,
  BackButtonContainer,
} from '../../../components/layout/style/edital/todos-editais';
import { truncateByCharacters } from '../../../components/layout/truncate-by-characters';
import ValidityChecker from '../../../components/validity-checker/validity-checker';
import { moduleNameFor } from '../../../helpers/module-name-for';
import { tagsEntitiesHtmlRemoval } from '../../../helpers/tags-entities-html-removal';
import { urlFor } from '../../../helpers/url-for';
import { useAuthContext } from '../../login/auth/auth-context';
import { EditalRubrica, TipoEditalRubrica } from '../../../models/edital-rubrica';

const StyledCustomModal = styled(CustomModal)({
  border: 'none',
  borderRadius: '4px',
  '.modal_modal_description': {
    backgroundColor: 'red',
  },
});

function EditalDetalhes() {
  const navigate = useNavigate();
  const routeParams = useParams();
  const [edital, setEdital] = useState<any>(null);
  const [editalTexto, setEditalTexto] = useState('');
  const [anexos, setAnexos] = useState([]);
  const [anexoNome, setAnexoNome] = useState('');
  const [selectedAnexo, setSelectedAnexo] = useState(null);
  const [url, setUrl] = useState('');
  const { setCurrentTitle, setFormData, updateCrudStates } = useCrudContext();
  const editalId = Number(routeParams['editalDetalhesId'] ?? null) ?? null;
  const fileApi = new FileApi();
  const title = 'Detalhes do edital';
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open, setOpen] = React.useState(false);
  const { loggedInUser } = useAuthContext();

  const defaultForm = {
    editalId,
    tituloDoEdital: edital?.nome,
    duracao: edital?.duracaoProjetoEmMeses,
    criadoPorId: loggedInUser?.id,
    edital: {
      termoDeAceite: edital?.termoDeAceite,
      perguntas: edital?.perguntaEdital,
      faixaFinanciamento: edital?.faixaFinanciamento,
      editalRubrica: edital?.editalRubrica,
    },
  };

  const editalApi = makeApi('/edital');

  useEffect(() => {
    setCurrentTitle(title);
    editalApi.get(editalId).then((x) => {
      setEdital(x);
      if (x.texto) {
        const truncatedText = truncateByCharacters(tagsEntitiesHtmlRemoval(x.texto), 200, {
          linkText: ' Ver Mais...',
          callback: () => {
            handleOpen();
          },
        });
        setEditalTexto(truncatedText);
      }
    });
  }, []);

  useEffect(() => {
    if (edital) {
      fileApi.getFile('edital', 'edital-anexo', edital.id).then(({ data }) => {
        setAnexos(data);
      });
    }
  }, [edital]);

  const AnexoItem = ({ label, isSelected, onClick }) => (
    <ShowAnexoLabel colorValue={isSelected ? 'lightgray' : 'white'} onClick={onClick}>
      {label}
    </ShowAnexoLabel>
  );

  const goToPropostas = (editalId: number) => {
    updateCrudStates(CrudStatesOptions.ADD);
    setFormData(defaultForm);
    navigate(`/proposta`);
  };

  const AnexoList = ({ items }) => {
    const handleAnexoClick = (index, anexo) => {
      setAnexoNome(anexo.nome);
      setSelectedAnexo(index);
      makeEditalApi()
        .getEditalFileToken()
        .then(({ fileToken }) => {
          setUrl(
            urlFor(
              `edital/${edital?.id}/edital-anexo/${anexo.id}?preview=true&api-key=${fileToken}&#zoom=100`,
            ),
          );
        });
    };

    return (
      <BoxAnexoName>
        {items.map((item, index) => (
          <AnexoItem
            key={index}
            label={item.nome}
            isSelected={selectedAnexo === index}
            onClick={() => handleAnexoClick(index, item)}
          />
        ))}
      </BoxAnexoName>
    );
  };

  return (
    <>
      <StyledCustomModal
        content={
          <>
            <div dangerouslySetInnerHTML={{ __html: edital?.texto ?? '' }} />
          </>
        }
        openModalState={open}
        hasWindowCloseBtn={true}
        handleCloseModal={handleClose}
      />
      <ComponentBox>
        <BackButtonContainer>
          <BackButton onClick={() => navigate('/login')}>
            <ArrowBackIcon></ArrowBackIcon>
            Voltar
          </BackButton>
        </BackButtonContainer>
        <Header>
          {edital ? edital.nome : ''}
          <ValidityChecker item={edital ?? {}} />
          <TextoEdital>{editalTexto}</TextoEdital>
        </Header>
        <Divider />
        <BoxContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <BoxAnexoNameItem>
                <AttachText size='24' color='#003D92' /> Arquivos
                <AnexoList items={anexos} />
              </BoxAnexoNameItem>
            </Grid>
            <Grid item xs={8}>
              <BoxAnexoContentItem>
                <DocumentText size='24' color='#003D92' /> Pré-Visualização: {anexoNome}
                <BoxAnexoContent>
                  {url ? (
                    <PdfContent src={url}></PdfContent>
                  ) : (
                    <EmptyAnexoMsg>Nenhum documento selecionado!</EmptyAnexoMsg>
                  )}
                </BoxAnexoContent>
              </BoxAnexoContentItem>
            </Grid>
          </Grid>
        </BoxContent>
        <ButtonContent>
          <Secure moduleName={moduleNameFor('proposta')} hasPermission={'CRIAR'}>
            <CustomButton onClick={() => goToPropostas(editalId)}>
              <AddIcon></AddIcon>
              <ButtomLabel>Criar Proposta</ButtomLabel>
            </CustomButton>
          </Secure>
        </ButtonContent>
      </ComponentBox>
    </>
  );
}

export default EditalDetalhes;
