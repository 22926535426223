// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Foi deixado aqui esse arquivo pois não encontrei nenhuma maneira simples de utilizar essa fonte com styled components. */", "",{"version":3,"sources":["webpack://./src/views/editar-perfil/editar-perfil.css"],"names":[],"mappings":"AAEA,2HAA2H","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');\n\n/* Foi deixado aqui esse arquivo pois não encontrei nenhuma maneira simples de utilizar essa fonte com styled components. */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
